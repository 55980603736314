import {
    DisplayAddress,
    DisplayContactItem,
    DisplayDateRange,
    DisplayDate,
    DisplayEmail,
    DisplayEntity,
    DisplayNum,
    numFormatter,
    DisplayPhone,
    DisplaySpan,
} from './Display';
import { DisplayFormattedText, DisplayText, DisplayPre, DisplayTextShortened } from './DisplayText';
import * as React from 'react';

export * from './Display';
export * from './DisplayText';

export class Display extends React.Component {
    public static Num = DisplayNum;
    public static numFormatter = numFormatter;
    public static Email = DisplayEmail;
    public static Date = DisplayDate;
    public static Phone = DisplayPhone;
    public static DateRange = DisplayDateRange;
    public static Address = DisplayAddress;
    public static ContactItem = DisplayContactItem;
    public static Entity = DisplayEntity;
    public static Span = DisplaySpan;
    public static Pre = DisplayPre;
    public static Text = DisplayText;
    public static TextShortened = DisplayTextShortened;
    public static Description = DisplayFormattedText;
    public static FormattedText = DisplayFormattedText;

    render() {
        throw new Error('Use Display.XXX for display');
        return null;
    }
}

export interface Display extends React.Component {
    Num: typeof DisplayNum;
    numFormatter: typeof numFormatter;
    Email: typeof DisplayEmail;
    Date: typeof DisplayDate;
    Phone: typeof DisplayPhone;
    DateRange: typeof DisplayDateRange;
    Address: typeof DisplayAddress;
    ContactItem: typeof DisplayContactItem;
    Entity: typeof DisplayEntity;
    Span: typeof DisplaySpan;
    Pre: typeof DisplayPre;
    Text: typeof DisplayText;
    Description: typeof DisplayFormattedText;
    FormattedText: typeof DisplayFormattedText;
}
