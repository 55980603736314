import * as React from 'react';
import { css, styled } from 'twin.macro';
import { Segment } from 'semantic-ui-react';
import { HeaderButtons, HeaderButtonType } from './HeaderButtons';
import { HeaderIconProp, iconPropToComp } from './helpers';
import { useAppBreakpoints } from '../use-app-breakpoints';

const hsl = (hue, sat, lum, alpha = 1) => {
    return `hsl(${hue}, ${sat}%, ${lum}%, ${alpha})`;
};

const primHsl =
    (sat, lum, alpha = 1) =>
    (props) => {
        return hsl(props.theme?.palette?.primaryHue, sat, lum, alpha);
    };

const secHsl =
    (sat, lum, alpha = 1) =>
    (props) => {
        return hsl(props.theme?.palette?.secondaryHue, sat, lum, alpha);
    };

const primColor = (props) => {
    return props.theme?.palette?.primaryColor;
};

const basicCss = css`
    background: ${(props) => props.theme?.panel?.body?.background};
    background-color: ${(props) => props.theme?.panel?.body?.backgroundColor || '#fefefe'};
    color: ${(props) => props.theme?.panel?.header?.color || '#222222'};
    border-bottom: #f5f5f5;
    border-bottom-width: 1px;
    border-bottom-style: solid;
`;
const nonBasicCss = css`
    background: ${(props) => props.theme?.panel?.header?.background};
    background-color: ${(props) => props.theme?.panel?.header?.backgroundColor || '#f0f0f0'};
    color: ${(props) => props.theme?.panel?.header?.color || '#222222'};
`;

const StyledHeader = styled(Segment)<{ $isBasic?: boolean }>`
    &&& {
        min-height: 2.5em;
        font-size: 1.1em;
        font-weight: bold;

        border-right: none !important;
        border-left: none !important;
        border-bottom: none !important;
        padding: 0.125em 0.5em 0.125em 0.75em;
        width: 100%;
        margin: 0;

        ${(props) => (props.$isBasic ? basicCss : nonBasicCss)}

        @media screen and (max-width: 479px) {
            font-size: 1.1em;
        }

        display: flex !important;
        flex-direction: row;
        align-items: center;
    }
`;

type PanelHeaderSizeType = 'small' | 'normal';

const LeftSpan = styled.span<{ size?: PanelHeaderSizeType }>`
    flex-grow: 0;
    font-size: ${({ size }) => (size === 'small' ? '0.95em' : undefined)};
`;

const RightSpan = styled.span`
    flex-grow: 2;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    &&& > * {
        margin: 0;
    }
`;

export interface PanelHeaderProps {
    content?: string | React.ReactNode;
    headerComp?: any;
    icon?: HeaderIconProp;
    color?: String;
    button?: HeaderButtonType | HeaderButtonType[];
    size?: PanelHeaderSizeType;
    style?: any;
    basic?: boolean;
}

const PanelHeader: React.FC<PanelHeaderProps> = (props) => {
    let { headerComp, content, icon, color, button, children, size, style, basic } = props;

    const { isMobile } = useAppBreakpoints();

    let iconComp = iconPropToComp(icon);
    return (
        <StyledHeader compact={true} color={color} attached={'top'} style={style} $isBasic={basic}>
            <LeftSpan size={size || 'normal'}>
                {iconComp}
                {content}
                {children}
                {headerComp}
            </LeftSpan>
            <RightSpan className={'no-print'}>
                <HeaderButtons buttons={button} autoHideButtonsContent={isMobile} />
            </RightSpan>
        </StyledHeader>
    );
};

export default PanelHeader;
