import styled from 'styled-components';
import * as React from 'react';
import { useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { ColorPalette, ColorPicker } from './ColorPicker';

const hoverProps = () => {
    return `&:hover {
        border: 1px solid #b7b7b7;
        box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }`;
};

export const ColorPill = styled.div<{ value: string; hoverable?: boolean; width?: string }>`
    background-color: ${(props) => props.value};
    position: relative;
    display: inline-block;
    vertical-align: text-bottom;
    height: 1.2em;
    width: ${(props) => props.width || '4em'};
    line-height: 1;
    border-radius: 0.25em;
    margin: 0;
    padding: 0;
    border: 1px solid #cccccc;
    color: #ccc;
    text-align: center;
    ${(props) => props.hoverable && hoverProps()}
`;

export const ColorPillWithColorPicker: React.FC<{
    value: string;
    palette?: ColorPalette;
    onChange?: (newValue: string) => any;
    readonly?: boolean;
}> = ({ value, onChange, palette, readonly = false }) => {
    const [isOpen, setOpen] = useState(false);
    const [isUpdating, setUpdating] = useState(false);

    const onColorSelect = async (newColor: string) => {
        setOpen(false);
        if (onChange) {
            setUpdating(true);
            await onChange(newColor);
            setUpdating(false);
        }
    };

    const pillContent = isUpdating ? <Icon name="spinner" loading={true} /> : !value && 'n/a';

    const trigger = (
        <span>
            <ColorPill hoverable={!readonly} value={value}>
                {pillContent}
            </ColorPill>
        </span>
    );

    return (
        <Popup
            hoverable
            on={['click']}
            open={isOpen}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            trigger={trigger}
            disabled={readonly}
            content={
                <div style={{ width: '280px' }}>
                    <ColorPicker palette={palette} onClick={onColorSelect} />
                </div>
            }
        />
    );
};
