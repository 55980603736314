import * as React from 'react';
import { StickyMenuContainer } from './StickyMenuContainer';
import { MenuBar } from './MenuBar';

export const StickyMenuBar: React.FC<{ topOffset?: number }> = ({ children, topOffset }) => {
    return (
        <StickyMenuContainer height={42} topOffset={topOffset}>
            <MenuBar>{children}</MenuBar>
        </StickyMenuContainer>
    );
};
