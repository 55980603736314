import * as React from 'react';
import { Menu, MenuItemProps, Popup, StrictPopupProps } from 'semantic-ui-react';

export interface MenuItemWithPopupProps extends MenuItemProps {
    popup?: StrictPopupProps;
    solid?: boolean;
}

export class MenuItemWithPopup extends React.Component<MenuItemWithPopupProps> {
    render() {
        const { popup, solid, ...rest } = this.props;

        // if (popup && rest.float) {
        //     console.warn(
        //         "WARNING: Popup with floated Buttons don't like each other. Don't use Popup and floated button together. See https://github.com/Semantic-Org/Semantic-UI-React/issues/2804"
        //     );
        // }

        const baseStyle = {
            display: 'flex',
            flexDirection: 'row',
        };

        const styles = solid
            ? {
                  ...baseStyle,
                  backgroundColor: '#e8e8e9',
                  borderRadius: '.28571429rem',
                  // color: 'white',
                  padding: '.72857143em 0.94285714em',
                  margin: '0.2em',
              }
            : baseStyle;

        const renderMenuItem = () => {
            //IMPORTANT: wrap in div so that Popup could appear when button is disabled.
            return (
                <div>
                    <Menu.Item style={styles} {...rest} />
                </div>
            );
        };

        if (popup?.content) {
            return <Popup trigger={renderMenuItem()} position={'top center'} {...popup} />;
        }

        return <Menu.Item style={styles} {...rest} />;
    }
}
