import styled from 'styled-components';
import * as React from 'react';
import { IconWithPopup, IconWithPopupProps } from '../IconWithPopup';

const IconButtonContainer = styled.button`
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    //outline: none;
    color: #c2c1c1;
    background-color: transparent;
    padding: 2px 0 0 4px;

    :disabled {
        color: #8c8c8c;
        opacity: 0.4;
    }

    &:hover {
        border: 1px solid #cccccc;
        background-color: #fefefe;
    }
`;

export interface IconButtonWithPopupProps extends IconWithPopupProps {
    onClick?: (e: any) => any;
}

export const IconButton: React.FC<IconButtonWithPopupProps> = ({ onClick, disabled, children, style, ...props }) => {
    const handleOnClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.(e);
    };
    return (
        <IconButtonContainer type={'button'} disabled={disabled} onClick={handleOnClick} style={style}>
            <IconWithPopup disabled={disabled} {...props} />
            {children}
        </IconButtonContainer>
    );
};
