import * as _ from 'lodash';
import { LayoutState } from './AppLayoutManager';
import { EZStorage } from '@ez/tools';

const defaultState: LayoutState = {
    mini: false,
    isDesktop: false,
    animationEnabled: true,
    mobileCollapsed: true,
    desktopCollapsed: false,
};

const STORAGE_KEY = 'app.layout.state';

export const restoreAppLayout = () => {
    try {
        if (!EZStorage.getItem(STORAGE_KEY)) {
            return defaultState;
        }
        const data = JSON.parse(EZStorage.getItem(STORAGE_KEY));
        const mini = !!_.get(data, 'mini', false);
        return { ...defaultState, mini: mini };
    } catch (e) {
        return defaultState;
    }
};

export const saveAppLayout = (newState: LayoutState) => {
    try {
        EZStorage.setItem(STORAGE_KEY, JSON.stringify({ mini: newState.mini }));
    } catch (e) {
        console.error(e);
    }
};
