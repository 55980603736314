import * as React from 'react';
import { MenuBarItem, MenuBarItemProps } from './MenuBarItem';
import { ConfirmModal, ConfirmProps } from '../ConfirmModal';

export interface MenuBarItemWithConfirmProps extends MenuBarItemProps {
    confirm?: ConfirmProps;
}

export const MenuBarItemWithConfirm: React.FC<MenuBarItemWithConfirmProps> = (props) => {
    const { confirm, onClick, onCancel, ...rest } = props;

    const handleClick = () => {
        return onClick && onClick();
    };

    if (!confirm) {
        return <MenuBarItem {...rest} onClick={handleClick} />;
    } else {
        const trigger = <MenuBarItem {...rest} onClick={undefined} />;
        return (
            <ConfirmModal
                onCancel={onCancel}
                onClick={handleClick}
                confirm={{ showLoaderOnConfirm: true, ...confirm }}
                trigger={trigger}
            />
        );
    }
};
