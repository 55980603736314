import { Machine } from 'xstate';
import { DateRange } from './DateRangePicker';
import moment from 'moment';

export interface RangePickerStateSchema {
    states: {
        init: {};
        selectStart: {};
        selectEnd: {};
    };
}

export interface RangePickerContext {
    initMaxDate?: Date;
    initMinDate?: Date;
    minDate?: Date;
    maxDate?: Date;
    maxRangeInWks: number;
    startDate?: Date;
    endDate?: Date;
}

export type RangePickerEvent =
    | { type: 'INIT_DEFAULTS'; maxDate?: Date; minDate?: Date; maxRangeInWks: number; startDate?: Date; endDate?: Date }
    | { type: 'CLICK_START_INPUT' }
    | { type: 'CLICK_END_INPUT' }
    | { type: 'SELECT_DATE_CELL'; date: Date }
    | { type: 'SET_START_DATE'; date: Date }
    | { type: 'SET_END_DATE'; date: Date }
    | { type: 'SET_DATE_RANGE'; dateRange: DateRange };

export const rangePickerMachine = Machine<RangePickerContext, RangePickerStateSchema, RangePickerEvent>(
    {
        id: 'date-range-picker',
        initial: 'init',
        context: {
            initMaxDate: null,
            initMinDate: null,
            maxDate: null,
            minDate: null,
            maxRangeInWks: 8,
            startDate: null,
            endDate: null,
        },
        states: {
            init: {
                on: {
                    INIT_DEFAULTS: {
                        target: 'selectStart',
                        actions: 'initDefaults',
                    },
                },
            },
            selectStart: {
                on: {
                    SET_DATE_RANGE: {
                        actions: ['setDateRange'],
                    },
                    SET_START_DATE: {
                        actions: ['setStartDate'],
                    },
                    CLICK_END_INPUT: {
                        target: 'selectEnd',
                        actions: ['setStartDateAsMinDate'],
                    },
                    SELECT_DATE_CELL: {
                        target: 'selectEnd',
                        actions: ['setStartDate', 'setStartDateAsMinDate'],
                    },
                },
            },
            selectEnd: {
                on: {
                    SET_DATE_RANGE: {
                        target: 'selectStart',
                        actions: ['setDateRange'],
                    },
                    CLICK_START_INPUT: {
                        target: 'selectStart',
                        actions: ['clearMinDate'],
                    },
                    SET_END_DATE: {
                        actions: ['setEndDate'],
                    },
                    SELECT_DATE_CELL: {
                        target: 'selectStart',
                        actions: ['setEndDate', 'clearMinDate'],
                    },
                },
            },
        },
    },
    {
        actions: {
            initDefaults: (context, event: any) => {
                context.startDate = event.startDate;
                context.endDate = event.endDate;
                context.initMaxDate = event.maxDate;
                context.maxDate = event.maxDate;
                context.initMinDate = event.minDate;
                context.minDate = event.minDate;
                context.maxRangeInWks = event.maxRangeInWks;
            },
            setDateRange: (context, event: any) => {
                context.startDate = event.startDate;
                context.endDate = event.endDate;
                context.maxDate = context.initMaxDate;
                context.minDate = context.initMinDate;
            },
            setStartDateAsMinDate: (context) => {
                const newStartDate = moment(context.startDate).startOf('d');
                context.minDate = newStartDate.toDate();
                if (!context.endDate || moment(context.endDate).isBefore(newStartDate)) {
                    context.endDate = newStartDate.endOf('d').toDate();
                }
            },
            clearMinDate: (context) => {
                context.minDate = null;
            },
            setStartDate: (context, event: any) => {
                const newStartDate = moment(event.date).startOf('day');
                context.startDate = newStartDate.toDate();

                if (context.maxRangeInWks > 0) {
                    const plusNWeeks = moment(newStartDate).add(context.maxRangeInWks, 'week');
                    const newMaxDate = moment(plusNWeeks).isAfter(context.initMaxDate)
                        ? context.initMaxDate
                        : plusNWeeks.toDate();

                    if (moment(newMaxDate).isBefore(context.endDate)) {
                        context.endDate = newMaxDate;
                    }

                    context.maxDate = newMaxDate;
                } else {
                    context.maxDate = context.initMaxDate;
                }
            },
            setEndDate: (context, event: any) => {
                context.endDate = moment(event.date).endOf('day').toDate();
            },
        },
    }
);
