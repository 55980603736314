import styled from 'styled-components';

export const FormLabel = styled.label`
    display: block;
    margin: 0.28571429rem 0 0.28571429rem 0;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.92857143em;
    font-weight: 700;
    text-transform: none;
`;
