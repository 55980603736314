import * as React from 'react';

import { Segment, SegmentProps } from 'semantic-ui-react';

interface PanelFooterProps extends SegmentProps {
    textAlign?: 'left' | 'right';
}

class PanelFooter extends React.Component<PanelFooterProps> {
    public static defaultProps: PanelFooterProps = {
        textAlign: 'left',
    };

    render() {
        const { textAlign } = this.props;
        return (
            <Segment attached="bottom" size={'mini'} compact textAlign={textAlign} clearing {...this.props}>
                {this.props.children}
            </Segment>
        );
    }
}

export default PanelFooter;
