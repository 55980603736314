import * as React from 'react';
import * as _ from 'lodash';

import { PageSkeletonLoader } from '../spinners';
import { branch, compose, renderComponent, renderNothing } from '@ez/tools';
import { ConnectionErrorMessage, NotFoundMessage } from '../Errors';

const Error = ({ data }) => {
    if (!data || !data.error) return null;
    const error = data?.error?.networkError?.result || data.error;
    return <ConnectionErrorMessage error={error} />;
};

export type ApolloLoadingInput = {
    show404ForPath?: boolean | string;
    showLoader?: boolean;
    showError?: boolean;
    loaderPaths?: string | string[];
};

export const withApolloLoading = (input?: ApolloLoadingInput) => {
    const { show404ForPath = false, showLoader = true, showError = true, loaderPaths = [] } = input || {};

    let loaderPathsComputed: string[] = (_.isArray(loaderPaths) ? loaderPaths : [loaderPaths]) as string[];

    const LoaderView = showLoader ? renderComponent(PageSkeletonLoader) : renderNothing;
    return compose(
        branch((props) => {
            if (loaderPathsComputed.length > 0) {
                // check if data is cached
                const cached = loaderPathsComputed.reduce((acc, path) => {
                    return _.get(props, path) && acc;
                }, true);
                if (cached) {
                    return false;
                }
            }
            return _.get(props, 'data.loading', false) || _.get(props, 'isMutating', false);
        }, LoaderView),
        branch((props) => {
            if (!showError) return false;
            const error = _.get(props, 'data.error');
            if (error) {
                console.error(error);
            }
            return !!error;
        }, renderComponent(Error)),
        branch(
            (props) => show404ForPath && _.isString(show404ForPath) && !_.get(props, show404ForPath),
            renderComponent(NotFoundMessage)
        )
    );
};

export default withApolloLoading;
