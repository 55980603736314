import * as React from 'react';
import { Menu, MenuMenuProps, MenuProps } from 'semantic-ui-react';
import { MenuBarHeaderItem } from './MenuBarHeaderItem';
import { MenuBarGroup } from './MenuBarGroup';
import { MenuBarItem } from './MenuBarItem';
import { MenuBarDropdown, MenuBarDropdownItem, MenuBarDropdownItemWithConfirm } from './MenuBarDropdown';
import { MenuBarItemWithConfirm } from './MenuBarItemWithConfirm';
import { MenuItemWithPopup } from './MenuItemWithPopup';

export const MenuBarSection: React.FC<MenuMenuProps> = (props) => <Menu.Menu {...props} />;

export interface MenuBarProps extends MenuProps {}
export class MenuBar extends React.Component<MenuBarProps> {
    public static Section = MenuBarSection;
    public static HeaderItem = MenuBarHeaderItem;
    public static Item = MenuBarItem;
    public static ItemWithConfirm = MenuBarItemWithConfirm;
    public static ItemWithPopup = MenuItemWithPopup;
    public static Group = MenuBarGroup;
    public static Dropdown = MenuBarDropdown;
    public static DropdownItem = MenuBarDropdownItem;
    public static DropdownItemWithConfirm = MenuBarDropdownItemWithConfirm;
    render() {
        return <Menu compact fluid className={'no-print pw-menu'} {...this.props} />;
    }
}
