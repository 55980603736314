import * as React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import { FormikProps, FormikValues, useFormik, useFormikContext } from 'formik';
import styled from 'styled-components';

export interface FormikFormButtonsProps {
    handleCancel?: () => any;
    submitOnEnter?: boolean;
    activateOnDirty?: boolean;
    submitButton?: ButtonProps;
    cancelButton?: ButtonProps;
}

export const FormikFormButtons: React.FC<FormikFormButtonsProps> = (props) => {
    const { submitOnEnter = true, activateOnDirty = false, handleCancel, cancelButton, submitButton } = props;

    const formikContext = useFormikContext();
    const { handleSubmit, isSubmitting, dirty } = formikContext;

    if (!handleSubmit && !handleCancel) return null;

    return (
        <ButtonContainer>
            {handleCancel ? (
                <Button
                    primary
                    type="button"
                    basic
                    disabled={isSubmitting}
                    onClick={handleCancel}
                    content={'Cancel'}
                    {...cancelButton}
                />
            ) : (
                <div />
            )}

            <Button
                primary
                type={submitOnEnter ? 'submit' : 'button'}
                onClick={(e) => handleSubmit(e as any)}
                loading={isSubmitting}
                disabled={isSubmitting || (activateOnDirty && !dirty)}
                content={'Submit'}
                {...submitButton}
            />
        </ButtonContainer>
    );
};

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    //padding: 0.25rem 0;
    margin: -0.5rem;
    &&& > button {
        margin: 0.5rem;
    }
    @media screen and (max-width: 480px) {
        flex-direction: column-reverse;
    }
`;
