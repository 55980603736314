import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { ThemeInterface } from './theme.interface';
import { useMemo } from 'react';

const generateLinks = (theme: ThemeInterface) => {
    const prefix = `${theme.publicPrefix}/favicon`;
    return [
        { rel: 'manifest', href: `${prefix}/site.webmanifest` },
        { rel: 'shortcut icon', href: `${prefix}/favicon.ico` },
        { rel: 'icon', type: 'image/png', href: `${prefix}/favicon-32x32.png`, sizes: '32x32' },
        { rel: 'icon', type: 'image/png', href: `${prefix}/favicon-16x16.png`, sizes: '16x16' },
        { rel: 'apple-touch-icon', href: `${prefix}/apple-touch-icon.png`, sizes: '180x180' },
        { rel: 'mask-icon', type: 'image/png', href: `${prefix}/safari-pinned-tab.svg`, color: '#5bbad5' },
    ];
};

const generateMetas = (theme: ThemeInterface) => {
    const prefix = `${theme.publicPrefix}/favicon`;
    return [
        { name: 'msapplication-TileColor', content: '#2b5797' },
        { name: 'msapplication-config', content: `${prefix}/browserconfig.xml` },
        { name: 'theme-color', content: `#ffffff` },
    ];
};

interface AppHelmetProps {
    theme: ThemeInterface;
    appTitle?: string;
}

export const AppThemeHelmet: React.FC<AppHelmetProps> = (props) => {
    const { theme, appTitle } = props;
    const { link, meta } = useMemo(() => {
        return { link: generateLinks(theme), meta: generateMetas(theme) };
    }, [props.theme]);
    return <Helmet title={appTitle} link={link} meta={meta} />;
};
