export const PrintConstants = {
    radius: 0,
    borderColor: '#3a3a3a',
    borderColorLight: '#777777',
    paddingUnit: 0.1,
};

export const pad = (val: number | number[]) => {
    if (val instanceof Array) {
        return val.map((u) => pad(u)).join(' ');
    } else {
        return `${PrintConstants.paddingUnit * val}cm`;
    }
};
