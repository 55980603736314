import * as React from 'react';
import { Message } from 'semantic-ui-react';

import withTransition, { SemanticTransitionProps } from './with-transition';
import { SemanticToastType } from './toast';

export const toastIcon: { [key: string]: string } = {
    info: 'announcement',
    success: 'checkmark',
    error: 'remove',
    warning: 'warning circle',
};

export interface SemanticToastProps {
    type: SemanticToastType;
    title: string;
    description: string;
    icon?: boolean | string;
    onClick: () => any;
    onClose: (toastId: number) => any;
}

interface SemanticToastInjectedProps extends SemanticToastProps, SemanticTransitionProps {}

export const SemanticToast: React.FC<SemanticToastInjectedProps> = (props) => {
    const { type, title, description, onClose, onClick, toastId } = props;
    const icon = props.icon || toastIcon[type];

    const onDismiss = (e) => {
        e.stopPropagation();
        onClose(toastId);
    };

    return (
        <Message
            {...{ [type]: true }}
            onClick={onClick}
            onDismiss={onDismiss}
            header={title}
            content={description}
            icon={icon}
            floating
        />
    );
};

export default withTransition<SemanticToastInjectedProps>(SemanticToast);
