import * as React from 'react';
import { VStack } from '../Stack';
import tw, { css, styled } from 'twin.macro';
import { FullScreen } from '../FullScreen';
import { PageHeader, PageHeaderSub } from '../PageHeader';
import { ThreeColumnManaged } from './ThreeColumnManaged';
import { SideBarLayout } from './SideBarLayout';
import { useContext } from 'react';

export type PageLayoutCtxType = {
    width?: PageLayoutWidthType;
};
const PageLayoutCtx = React.createContext<PageLayoutCtxType>({ width: 'screen-wide' });

export const usePageLayoutContext = () => useContext(PageLayoutCtx);

export const PageLayoutTwoColumns = styled.div<{ mobileColReverse?: boolean }>(({ mobileColReverse }) => [
    tw`flex flex-col gap-4 pb-4 lg:grid lg:grid-cols-2`,
    mobileColReverse && tw`flex-col-reverse`,
]);

export type PageLayoutWidthType =
    | 'full'
    | 'screen-sm'
    | 'screen-md'
    | 'screen-lg'
    | 'screen-xl'
    | 'screen-2xl'
    | 'screen-wide';

const PageBodyContStyled = styled.div<{ w: PageLayoutWidthType }>(({ w }) => [
    tw`w-full mx-auto`,
    w === 'screen-sm' && tw`max-w-screen-sm`,
    w === 'screen-md' && tw`max-w-screen-md`,
    w === 'screen-lg' && tw`max-w-screen-lg`,
    w === 'screen-xl' && tw`max-w-screen-xl`,
    w === 'screen-2xl' && tw`max-w-screen-2xl`,
    w === 'screen-wide' &&
        css`
            max-width: 140rem;
        `,
]);

export interface BodySectionProps {
    width?: PageLayoutWidthType;
}

export const BodySection: React.FC<BodySectionProps> = ({ width, children, ...rest }) => {
    const ctx = usePageLayoutContext();
    const w = width || ctx?.width;
    return (
        <PageBodyContStyled {...rest} w={w}>
            {children}
        </PageBodyContStyled>
    );
};

export interface PageLayoutProps {
    width?: PageLayoutWidthType;
    sectionWidth?: PageLayoutWidthType;
}

export class PageLayout extends React.Component<PageLayoutProps> {
    public static FullScreen = FullScreen;
    public static SideBarLayout = SideBarLayout;
    public static ThreeColumnManaged = ThreeColumnManaged;
    public static TwoColumns = PageLayoutTwoColumns;
    public static PageHeader = PageHeader;
    public static PageHeaderSub = PageHeaderSub;
    public static BodySection = BodySection;

    private static defaultProps = {
        width: 'screen-wide',
        sectionWidth: 'full',
    };

    constructor(props: PageLayoutProps) {
        super(props);
    }

    render() {
        const ctxValue = { width: this.props.sectionWidth || this.props.width };

        return (
            <PageLayoutCtx.Provider value={ctxValue}>
                <PageBodyContStyled w={this.props.width}>
                    <VStack>{this.props.children}</VStack>
                </PageBodyContStyled>
            </PageLayoutCtx.Provider>
        );
    }
}
