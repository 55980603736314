import * as React from 'react';

// import '../../styles/react-semantic-alert.css';

import SemanticToast from './semantic-toast';
import { store } from './toast';
import { SemanticTRANSITIONS } from 'semantic-ui-react';
import { SemanticToastContainerStyled } from './semantic-toast-container.styled';

/* eslint-disable no-useless-computed-key */
const closeAnimations: { [key: string]: SemanticTRANSITIONS } = {
    ['top-right']: 'fly left',
    ['top-center']: 'fly down',
    ['top-left']: 'fly right',
    ['bottom-right']: 'fly left',
    ['bottom-center']: 'fly up',
    ['bottom-left']: 'fly right',
};

interface SemanticToastContainerProps {
    position: 'top-right' | 'top-center' | 'bottom-right' | 'bottom-center' | 'bottom-left';
    className?: string;
    animation?: string;
}

interface SemanticToastContainerState {
    toasts: any[];
}

class SemanticToastContainer extends React.Component<SemanticToastContainerProps, SemanticToastContainerState> {
    public static defaultProps: SemanticToastContainerProps = {
        position: 'top-right',
    };

    state = {
        toasts: [],
    };

    componentDidMount() {
        store.subscribe(this.updateToasts);
    }

    componentWillUnmount() {
        store.unsubscribe(this.updateToasts);
    }

    onClose = (toastId) => {
        const toast = this.state.toasts.find((value) => value.id === toastId);
        if (!toast) return;

        store.remove(toast);

        if (toast.onClose) {
            toast.onClose();
        }
    };

    updateToasts = () => {
        this.setState({
            toasts: store.data,
        });
    };

    render() {
        const { animation: containerAnimation, position, className } = this.props;
        const hasToasts = this.state.toasts.length > 0;
        const style = !hasToasts
            ? {
                  display: 'none',
              }
            : undefined;

        return (
            <SemanticToastContainerStyled>
                <div className={`ui-alerts ${position} ${className}`} style={style}>
                    {this.state.toasts.map((toast) => {
                        const {
                            id,
                            type = 'info',
                            title = '',
                            description = '',
                            icon = 'announcement',
                            time,
                            animation,
                            onClick,
                        } = toast;
                        return (
                            <SemanticToast
                                key={id}
                                toastId={id}
                                type={type}
                                title={title}
                                description={description}
                                icon={icon}
                                openAnimation={animation || containerAnimation || 'pulse'}
                                closeAnimation={closeAnimations[position]}
                                time={time}
                                onClick={onClick}
                                onClose={this.onClose}
                            />
                        );
                    })}
                </div>
            </SemanticToastContainerStyled>
        );
    }
}

export default SemanticToastContainer;
