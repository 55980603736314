import * as React from 'react';

import { Segment, SegmentProps, SemanticCOLORS } from 'semantic-ui-react';
import styled from 'styled-components';

interface PanelBodyProps extends Omit<SegmentProps, 'color'> {
    attached?: 'top' | 'bottom' | boolean;
    style?: any;
    compact?: boolean;
    color?: SemanticCOLORS | string;
}

const StyledBody = styled(Segment)`
    &&& {
        font-size: 1em;
        padding: 0.75em;
        border-top-width: 0;
        background: ${(props) => props.theme?.panel?.body?.background};
        background-color: ${(props) => props.theme?.panel?.body?.backgroundColor};
        color: ${(props) => props.theme?.panel?.body?.color};
        //border-color: var(--ez-panel-border-color, #f5f5f5);
    }
`;

class PanelBody extends React.Component<PanelBodyProps> {
    public static defaultProps: PanelBodyProps = {
        compact: false,
    };

    render() {
        const { color, style = {}, ...rest } = this.props;
        const computedStyle = {
            // backgroundColor: color ? color : undefined,
            ...style,
        };

        return <StyledBody color={color} style={computedStyle} clearing {...rest} />;
    }
}

export default PanelBody;
