import { Popup, PopupProps } from 'semantic-ui-react';
import * as React from 'react';

export interface TableCellPopupProps extends PopupProps {
    trigger?: React.ReactNode;
    children?: React.ReactNode;
}

export const TableCellPopup: React.FC<TableCellPopupProps> = ({ trigger, children, ...rest }) => {
    return (
        <Popup mouseEnterDelay={300} wide={true} hoverable={true} trigger={trigger} {...rest}>
            <div
                onClick={(e) => {
                    // Catch click event here and stop propagation.
                    // Otherwise it will trigger a click on the `TableRowClickable` row.
                    e.preventDefault(), e.stopPropagation();
                }}
            >
                {children}
            </div>
        </Popup>
    );
};
