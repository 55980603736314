import * as React from 'react';
import { Icon, Image, SemanticICONS } from 'semantic-ui-react';
import { Sidebar } from './AppSidebarComponents';
import { LayoutControlProps } from './AppLayoutManager';
import { isTouch } from '@ez/tools';
import { Hover } from './MenuHover';
import { LinkButton } from '../buttons/LinkButton';
import { useMemo } from 'react';

export type MaybeItem<P> = P | null | undefined | false;

export interface AppSidebarMenuItem {
    to: string;
    exact?: boolean;
    icon: SemanticICONS;
    secondaryIcon?: SemanticICONS;
    label: string | React.ReactNode;
    as: typeof SidebarMenuItemExternalLink | typeof SidebarMenuItemButton | React.ComponentType;
    sub?: boolean;
    [key: string]: any;
}

export interface AppSidebarMenuGroup {
    header: string;
    items: MaybeItem<AppSidebarMenuItem>[];
}

export interface AppSidebarConfig {
    logo?: {
        image: string | React.ReactNode;
        icon: string | React.ReactNode;
    };
    menu: MaybeItem<AppSidebarMenuGroup>[];
}

// An example of how to create a reloadable NavLink component
// const ReloadableLinkComp = React.forwardRef<any, any>(({ to, router, onClick, exact = false, ...rest }, ref) => {
//     const appUpdater = useAppUpdater();
//     return (
//         <div ref={ref}>
//             <NavLink
//                 to={to}
//                 exact={exact}
//                 {...rest}
//                 onClick={(e) => {
//                     try {
//                         if (appUpdater.pendingReload) {
//                             e.preventDefault();
//                             return appUpdater.reloadPage(to);
//                         }
//                     } catch (e) {
//                         console.error(e);
//                     }
//                     onClick && onClick();
//                 }}
//             />
//         </div>
//     );
// });
//
// export const SidebarMenuItemNavLink = Sidebar.createMenuItem(ReloadableLinkComp);

const ExternalLinkComp: React.FC<{ to; className; newTab?: boolean }> = ({ to, children, className, newTab }) => (
    <a href={to} className={className} children={children} target={newTab ? '_blank' : undefined} />
);

export const SidebarMenuItemExternalLink = Sidebar.createMenuItem(ExternalLinkComp);
export const SidebarMenuItemButton = Sidebar.createMenuItem(LinkButton);

const FullWidthItemContent: React.FC<AppSidebarMenuItem> = ({
    to,
    exact,
    as,
    onClick,
    icon,
    label,
    sub,
    secondaryIcon,
}) => {
    const SidebarMenuItem = as; // Sidebar.createMenuItem(as);
    return (
        <SidebarMenuItem
            key={to}
            activeClassName="active"
            to={to}
            exact={exact}
            onClick={onClick}
            className={sub ? 'sub' : undefined}
        >
            {icon && <Icon name={icon} className={'main-icon'} />}
            {label}
            {secondaryIcon && <Icon className={'secondary-icon'} name={secondaryIcon} />}
        </SidebarMenuItem>
    );
};

const MiniWidthItemContent: React.FC<AppSidebarMenuItem> = ({ to, as, onClick, icon, label }) => {
    const SidebarMenuItem = as;

    const Comp = (
        <SidebarMenuItem key={to} activeClassName="active minimised" to={to} onClick={onClick}>
            <Icon name={icon} className={'main-icon'} />
        </SidebarMenuItem>
    );

    if (isTouch) {
        // Don't show popup on touch devices. It behaves odd.
        return Comp;
    }

    return <Hover trigger={Comp} content={label} />;
};

interface AppSidebarProps extends LayoutControlProps {
    config: AppSidebarConfig;
    onDimmerClick: () => any;
    onClick?: (item: AppSidebarMenuItem) => any;
}
export const AppSidebar: React.FC<AppSidebarProps> = ({ layoutState, config, onClick, onDimmerClick }) => {
    const mini = layoutState.isDesktop && layoutState.mini;

    const LogoImage = useMemo(() => {
        const comp = !!mini ? config.logo?.icon : config.logo?.image;
        const idName = !!mini ? 'nav-logo-icon' : 'nav-logo-image';

        if (typeof comp === 'string') {
            return () => <Image id={idName} alt={''} src={comp} />;
        } else {
            return () => <>{comp}</>;
        }
    }, [mini, config.logo]);

    const menus = config?.menu?.filter(Boolean) || [];
    return (
        <>
            <Sidebar.Aside id={'sidebar'}>
                {config.logo && (
                    <Sidebar.LogoContainer>
                        <LogoImage />
                    </Sidebar.LogoContainer>
                )}
                <Sidebar.MenuContainer>
                    <Sidebar.Menu>
                        {menus.map((group, index) => {
                            if (!group) return null;
                            const items = group.items?.filter(Boolean) || [];
                            if (items.length === 0) return null;
                            return (
                                <Sidebar.MenuGroup key={index}>
                                    <Sidebar.MenuHeader>{!mini && group?.header}</Sidebar.MenuHeader>
                                    {items.map((item, index) => {
                                        if (!item) {
                                            return null;
                                        }
                                        return (
                                            <React.Fragment key={index}>
                                                {mini ? (
                                                    <MiniWidthItemContent onClick={() => onClick(item)} {...item} />
                                                ) : (
                                                    <FullWidthItemContent onClick={() => onClick(item)} {...item} />
                                                )}
                                            </React.Fragment>
                                        );
                                    })}
                                </Sidebar.MenuGroup>
                            );
                        })}
                    </Sidebar.Menu>
                    <Sidebar.MenuSpacer />
                </Sidebar.MenuContainer>
            </Sidebar.Aside>
            <div className="layout-mask" onClick={onDimmerClick} />
        </>
    );
};
