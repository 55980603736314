import DatePicker, { ReactDatePickerProps } from '@ez/react-datepicker';
import * as React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';

class DateCustomInput extends React.Component<ButtonProps> {
    render() {
        const { onClick, content, value, icon, ...props } = this.props;
        return <Button tw={'min-w-10'} basic onClick={onClick} content={content || value} icon={icon} {...props} />;
    }
}

export type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export interface DatePickerButtonProps extends ReactDatePickerProps, Omit<ButtonProps, 'tabIndex'> {
    arrowPosition?: 'center' | 'left';
    fluid?: boolean;
    readOnly?: boolean;
}

export const DatePickerButton: React.FC<DatePickerButtonProps> = ({
    arrowPosition,
    icon,
    content,
    size,
    fluid,
    labelPosition,
    color,
    readOnly,
    disabled,
    ...props
}) => {
    let popperClassName = ['pw-datepicker-popper'];
    if (arrowPosition === 'center') {
        popperClassName.push('pw-react-datepicker__arrow--align-center');
    }
    if (arrowPosition === 'left') {
        popperClassName.push('pw-react-datepicker__arrow--align-left');
    }

    const isDisabled = readOnly || disabled;
    return (
        <div>
            <DatePicker
                popperClassName={popperClassName.join(' ')}
                calendarClassName={'pw-datepicker'}
                allowSameDay={true}
                customInput={
                    <DateCustomInput
                        fluid={fluid}
                        icon={icon}
                        content={content}
                        size={size}
                        color={color}
                        labelPosition={labelPosition}
                    />
                }
                disabled={isDisabled}
                {...props}
            />
        </div>
    );
};
