import * as React from 'react';
import styled from 'styled-components';

export type ColorPaletteItem = { color?: string; label?: string };
export type ColorPalette = {
    group?: any;
    items: ColorPaletteItem[];
};

/**
const s = '100%';
const l = '83%';

const s2 = '60%';
const l2 = '70%';

const defaultColorPalette1: ColorPalette = {
    group: null,
    items: [
        { color: `hsl(0, 0%, ${l})` },
        { color: `hsl(4, ${s}, ${l})` },
        { color: `hsl(60, ${s}, ${l})` },
        { color: `hsl(120, ${s}, ${l})` },
        { color: `hsl(180, ${s}, ${l})` },
        { color: `hsl(215, ${s}, ${l})` },
        { color: `hsl(260, ${s}, ${l})` },
        { color: `hsl(320, ${s}, ${l})` },

        { color: `hsl(30, ${s2}, ${l2})` },
        { color: `hsl(150, ${s2}, ${l2})` },
        { color: `hsl(285, ${s2}, ${l2})` },
        { color: `hsl(344, ${s2}, ${l2})` },
    ],
};
*/

const defaultColorPalette: ColorPalette = {
    group: null,
    items: [
        { color: '#d4d4d4' },
        { color: '#ffaea8' },
        { color: '#ffffa8' },
        { color: '#a8ffa8' },
        { color: '#a8ffff' },
        { color: '#a8ccff' },
        { color: '#c5a8ff' },
        { color: '#ffa8e2' },

        { color: '#e0b285' },
        { color: '#8bb7a1' },
        { color: '#a684b0' },
        { color: '#e0859d' },
    ],
};

const ColorDiv = styled.div<{ color: string }>`
    background-color: ${(props) => props.color};
    min-height: 40px;
    min-width: 60px;
    margin: 2px;
    border-radius: 4px;
    padding: 4px;
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        border: 1px solid #747474;
        box-shadow: -1px 1px 3px -1px rgba(0, 0, 0, 0.5);
        cursor: pointer;
    }
`;

const StyledButton = styled.button`
    border: none;
    outline: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
`;

const GridContainer = styled.div<{ columns?: number }>`
    display: inline-grid;
    grid-template-columns: repeat(${(props) => props.columns || 1}, 1fr);
`;

export const ColorPicker: React.FC<{ onClick?: (color: string) => any; palette?: ColorPalette }> = ({
    onClick,
    palette,
}) => {
    const onclick = (pi: ColorPaletteItem) => (e) => {
        e.preventDefault();
        onClick && onClick(pi.color);
    };

    if (!palette) {
        palette = defaultColorPalette;
    }

    if (!palette.items || palette.items.length === 0) {
        // If palette is empty, use default palette
        palette = defaultColorPalette;
    }

    const count = palette.items.length;
    let columns = 4;

    if (count < 5 || count === 7 || count === 8) {
        // it looks better in 4 column layour for these numbers
        columns = 4;
    } else {
        // try to fit in square.
        columns = Math.ceil(Math.sqrt(count));
    }

    return (
        <GridContainer columns={columns}>
            {palette.items.map((p, index) => (
                <StyledButton key={index} onClick={onclick(p)}>
                    <ColorDiv color={p.color}>{p.label ? p.label : ''}</ColorDiv>
                </StyledButton>
            ))}
        </GridContainer>
    );
};
