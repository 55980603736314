import * as React from 'react';
import styled from 'styled-components';

const ButtonStyled = styled.button`
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #4183c4;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    display: inline;
    margin: 0;
    padding: 0;
    outline: none;
    text-align: left;

    &:hover {
        text-decoration: underline;
    }
    &:focus {
        text-decoration: none;
    }
`;

export const LinkButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement> & { content?: any }> = ({
    content,
    children,
    onClick,
    ...rest
}) => {
    /// content prop wins.
    const c = content || children;

    const handleOnClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.(e);
    };

    return (
        <ButtonStyled onClick={handleOnClick} {...rest}>
            {c}
        </ButtonStyled>
    );
};
