import * as React from 'react';
import { Field, getIn } from 'formik';
import { FormikFormFieldLabel } from './FormikFormFieldLabel';

export interface SuggestableInputProps<T = any> {
    label?: string | React.ReactNode;
    required?: boolean;
    value: T;
    onChange: (item: T | null) => any;
    placeholder?: string;
    isClearable?: boolean;
    error?: boolean;
    readOnly?: boolean;
}

export interface FormikSuggestInputProps<T> extends SuggestableInputProps<T> {}

export interface FormikSuggestInputFieldProps<T> extends Omit<SuggestableInputProps<T>, 'value' | 'onChange'> {
    name: string;
}

export function withFormikSuggestField<E = any, T = any>(
    SuggestInputComponent: React.ComponentType<SuggestableInputProps<T>>
) {
    const FormikSuggestInputField: React.FC<FormikSuggestInputFieldProps<T> & E> = ({
        label,
        name,
        required,
        readOnly,
        ...props
    }) => {
        return (
            <Field name={name}>
                {({ form, field }) => {
                    const { value } = field;

                    const touched = getIn(form.touched, name);
                    const error = getIn(form.errors, name);
                    let hasError = touched && !!error;
                    const labelComp = (
                        <FormikFormFieldLabel htmlFor={name} label={label} name={name} required={required} />
                    );

                    const onUpdate = (value) => {
                        form.setFieldValue(field.name, value);
                    };
                    return (
                        <SuggestInputComponent
                            {...props}
                            label={labelComp}
                            isClearable={true}
                            isDisabled={readOnly}
                            value={value}
                            error={hasError}
                            onChange={(value) => onUpdate(value)}
                        />
                    );
                }}
            </Field>
        );
    };

    return FormikSuggestInputField;
}
