import * as React from 'react';
import { Checkbox, CheckboxProps, Icon } from 'semantic-ui-react';
import { PrintConstants, pad } from '../constants';
import { useContext } from 'react';
import { usePrintToggles } from '../useTogglesHook';
import * as _ from 'lodash';
import { styled } from 'twin.macro';

const ContentContainer = styled.div<{ noPrint?: boolean }>`
    border-radius: ${PrintConstants.radius};
    //border: 1px solid ${PrintConstants.borderColor};
    break-inside: avoid-page;
    padding: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: ${pad(1)};
    margin-bottom: ${pad(2)};
    @media print {
        display: ${(props) => (props.noPrint ? 'none' : 'flex')};
    }

    @media screen {
        color: ${(props) => props.noPrint && '#777'};
        opacity: ${(props) => props.noPrint && '0.4'};
    }
`;

const ToggleContainerDiv = styled.span`
    position: absolute;
    top: ${pad(1)};
    right: -80px;
    display: inline-block;
    & > i {
        font-size: 1rem;
    }
    & > * {
        vertical-align: middle !important;
        padding-right: 2px;
    }
    @media print {
        display: none;
    }
`;

const PrintSectionContainer = styled.div`
    position: relative;
    break-inside: avoid-page;
`;

export interface PrintSectionProps {
    showPrintToggle?: boolean;
    noPrint?: boolean;
    onPrintChange?: (event: React.MouseEvent<HTMLInputElement>, data: CheckboxProps) => any;
}

export const PrintSection: React.FC<PrintSectionProps> = (props) => {
    const { showPrintToggle, noPrint, onPrintChange, ...rest } = props;
    return (
        <PrintSectionContainer>
            {!!showPrintToggle && (
                <ToggleContainerDiv>
                    <Checkbox toggle checked={!noPrint} onChange={onPrintChange} />
                    <Icon name={'print'} color={noPrint ? 'grey' : 'blue'} style={{ paddingLeft: '10px' }} />
                </ToggleContainerDiv>
            )}
            <ContentContainer {...rest} noPrint={noPrint} />
        </PrintSectionContainer>
    );
};

export interface PrintSectionContextType {
    moduleName: string;
    toggles: any;
}

export const PrintSectionContext = React.createContext<PrintSectionContextType>(null);

export const PrintSectionProvider: React.FC<{ moduleName?: string; defaultState }> = ({
    children,
    moduleName,
    defaultState,
}) => {
    const toggles = usePrintToggles(moduleName, defaultState);
    return <PrintSectionContext.Provider value={{ moduleName, toggles }}>{children}</PrintSectionContext.Provider>;
};

export const PrintSectionConsumer: React.FC<{ name?: string }> = ({ name, ...rest }) => {
    const ctx = useContext(PrintSectionContext);
    const toggle = _.get(ctx.toggles, name);
    return <PrintSection {...toggle} {...rest} />;
};
