import * as React from 'react';
import { Icon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { MenuItemWithPopup } from './MenuItemWithPopup';
import styled from 'styled-components';

export interface MenuBarHeaderItemProps {
    color?: SemanticCOLORS;
    icon?: SemanticICONS;
    position?;
    title?;
    popup?;
    loading?: boolean;
    disabled?: boolean;
}
export const MenuBarHeaderItem: React.FC<MenuBarHeaderItemProps> = ({
    color,
    position,
    disabled,
    title,
    icon,
    children,
}) => {
    return (
        <MenuItemWithPopup position={position} disabled={disabled}>
            <StyledHeader>
                {icon && <Icon name={icon} color={color} />}
                {title}
                {children}
            </StyledHeader>
        </MenuItemWithPopup>
    );
};

const StyledHeader = styled.div`
    font-weight: bold;
    font-size: 1.2em;
    height: 1rem;
    line-height: 1;
    display: inline-flex;
    @media (max-width: 768px) {
        font-size: 1em;
    }
`;
