import * as React from 'react';
import { LayoutWrapper, LayoutWrapperGlobalStyle, LayoutConf } from './LayoutWrapper';
import { Navbar } from './NavbarComponents';
import { AppSidebarMenuItem } from './AppSidebar';
import { restoreAppLayout, saveAppLayout } from './utils';
import { PageWrapperDiv } from './DefaultPageLayout';

const AppErrorBoundary: React.FC = ({ children }) => {
    return <>{children}</>;
};

const defaultLayoutConf: LayoutConf = {
    layoutMask: {
        backgroundColor: 'rgb(0,0,0)',
    },
    sidebar: {
        width: '170px',
        widthMini: '50px',
        widthMobile: '280px',
    },
    navbar: {
        height: 'var(--topBar, 46px)',
    },
    layoutState: {
        mobileCollapsed: true,
        desktopCollapsed: false,
        mini: false,
        animationEnabled: true,
        isDesktop: true,
    },
    desktopBreakpoint: 768,
};

export interface LayoutState {
    isDesktop: boolean;
    animationEnabled: boolean;
    mobileCollapsed: boolean;
    desktopCollapsed: boolean;
    mini: boolean;
}

export interface LayoutControlProps {
    layoutState: LayoutState;
    showSidebarToggle?: boolean;
    onToggleMenu?: (close?: boolean) => any;
    onSidebarMenuClick?: (item: AppSidebarMenuItem) => any;
}

export interface PageLayoutManagerComponentProps {
    defaultLayoutState?: LayoutState;
    onLayoutChange?: (newState: LayoutState) => any;
}

export interface PageLayoutManagerExternalProps extends PageLayoutManagerComponentProps {
    AppNavbar: React.ComponentType<LayoutControlProps>;
    AppSidebar: React.ComponentType<LayoutControlProps>;
}

export interface PageLayoutManagerProps extends PageLayoutManagerExternalProps {}

export interface State {
    isDesktop: boolean;
    sidebarMinimized: boolean;
    desktopSidebarCollapsed: boolean;
    mobileSidebarCollapsed: boolean;
    animationEnabled: boolean;
    innerWidth: number;
}

export class AppShellLayoutManager extends React.Component<PageLayoutManagerProps, State> {
    private rqf: any;

    constructor(props) {
        super(props);
        this.state = {
            isDesktop: false, // Set to false by default, to avoid visual glitch on mobile devices.
            desktopSidebarCollapsed: false,
            mobileSidebarCollapsed: true,
            animationEnabled: true,
            sidebarMinimized: props.defaultLayoutState !== undefined ? props.defaultLayoutState.mini : false,
            innerWidth: window.innerWidth,
        };
    }

    public componentDidMount() {
        this.updateDimensionsImmediate();
        window.addEventListener('resize', this.onResize);
    }

    public componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
        this.rqf = null;
    }

    public onResize = () => {
        if (this.rqf) {
            return;
        }
        this.rqf = window.requestAnimationFrame(() => {
            this.rqf = null;
            this.updateDimensions();
        });
    };

    private isDesktop = () => {
        return window.innerWidth > defaultLayoutConf.desktopBreakpoint;
    };

    public updateDimensionsImmediate = () => {
        const { isDesktop: prevIsDesktop } = this.state;
        const newIsDesktop = this.isDesktop();

        if (prevIsDesktop == newIsDesktop) {
            return;
        }

        this.setState(
            {
                desktopSidebarCollapsed: false,
                mobileSidebarCollapsed: true,
                isDesktop: newIsDesktop,
                animationEnabled: false,
            },
            () =>
                setTimeout(() => {
                    if (!this.rqf) return;
                    this.setState({ animationEnabled: true });
                }, 500)
        );

        this.notifyLayoutStateObserver();
    };

    // public updateDimensions = _.debounce(this.updateDimensionsImmediate, 20);
    public updateDimensions = this.updateDimensionsImmediate;

    public onSidebarMenuClick = (_: AppSidebarMenuItem) => {
        if (this.state.isDesktop) {
        } else {
            this.setState(
                {
                    mobileSidebarCollapsed: true,
                },
                this.notifyLayoutStateObserver
            );
        }
    };

    public onToggleMenu = (shouldClose?: boolean) => {
        // console.log('onToggleMenu', shouldClose);
        if (this.state.isDesktop) {
            const nextState = shouldClose !== undefined ? shouldClose : !this.state.sidebarMinimized;
            this.setState(
                {
                    sidebarMinimized: nextState,
                    desktopSidebarCollapsed: false,
                    mobileSidebarCollapsed: true,
                },
                this.notifyLayoutStateObserver
            );
        } else {
            const nextState = shouldClose !== undefined ? shouldClose : !this.state.mobileSidebarCollapsed;
            this.setState(
                {
                    mobileSidebarCollapsed: nextState,
                },
                this.notifyLayoutStateObserver
            );
        }
    };

    getLayoutState = (): LayoutState => {
        return {
            mobileCollapsed: this.state.mobileSidebarCollapsed,
            desktopCollapsed: this.state.desktopSidebarCollapsed,
            mini: this.state.sidebarMinimized,
            isDesktop: this.state.isDesktop,
            animationEnabled: this.state.animationEnabled,
        };
    };

    notifyLayoutStateObserver = () => {
        const { onLayoutChange } = this.props;
        if (onLayoutChange) {
            onLayoutChange(this.getLayoutState());
        }
    };

    render() {
        const { AppNavbar, AppSidebar } = this.props;

        const layoutState = this.getLayoutState();

        return (
            <LayoutWrapper data-testid={'LayoutWrapper'} {...defaultLayoutConf} layoutState={layoutState}>
                <LayoutWrapperGlobalStyle {...defaultLayoutConf} layoutState={layoutState} />
                {AppNavbar && (
                    <Navbar.Header id={'header'}>
                        <Navbar.Menu
                            showSidebarToggle={true}
                            onToggleMenu={this.onToggleMenu}
                            layoutState={layoutState}
                        >
                            <AppNavbar
                                showSidebarToggle={true}
                                onToggleMenu={this.onToggleMenu}
                                layoutState={layoutState}
                            />
                        </Navbar.Menu>
                    </Navbar.Header>
                )}
                {AppSidebar && (
                    <AppSidebar
                        showSidebarToggle={false}
                        onSidebarMenuClick={this.onSidebarMenuClick}
                        onToggleMenu={this.onToggleMenu}
                        layoutState={layoutState}
                    />
                )}
                <main id="content">
                    <AppErrorBoundary>
                        <PageWrapperDiv>{this.props.children}</PageWrapperDiv>
                    </AppErrorBoundary>
                </main>
            </LayoutWrapper>
        );
    }
}

// Helper method
const defaultLayoutState = restoreAppLayout();

export const createPageLayout =
    (components: {
        AppNavbar: React.ComponentType<LayoutControlProps>;
        AppSidebar: React.ComponentType<LayoutControlProps>;
    }): React.FC<PageLayoutManagerComponentProps> =>
    (props) => {
        return (
            <AppShellLayoutManager
                onLayoutChange={saveAppLayout}
                defaultLayoutState={defaultLayoutState}
                AppNavbar={components.AppNavbar}
                AppSidebar={components.AppSidebar}
                {...props}
            />
        );
    };
