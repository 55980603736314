import * as React from 'react';
import {
    Button as ButtonSUI,
    ButtonProps,
    Icon,
    Popup,
    PopupProps,
    SemanticICONS,
    StrictPopupProps,
} from 'semantic-ui-react';
import { styled } from 'twin.macro';
import { useSafeState } from '@ez/tools';

export interface ButtonWithPopupProps extends ButtonProps {
    popup?: StrictPopupProps;
    showLoaderOnClick?: boolean;
    // Default lock reason is "No Permission"
    // Provide string value to set custom lock reason.
    locked?: boolean | string;
}

const ButtonContainer = styled.div<{ fluid?: boolean }>`
    display: ${({ fluid = false }) => (fluid ? 'block' : 'inline-block')};
`;

export const ButtonWithPopup: React.FC<ButtonWithPopupProps> = (props) => {
    const { style, popup, showLoaderOnClick = true, onClick, locked = false, icon, disabled, ...rest } = props;
    const [loading, setLoading] = useSafeState(false);

    if (popup && rest.floated) {
        console.warn(
            "WARNING: Popup with floated Buttons don't like each other. Don't use Popup and floated button together. See https://github.com/Semantic-Org/Semantic-UI-React/issues/2804"
        );
    }

    const handleOnClick = async (event, data) => {
        if (showLoaderOnClick) {
            try {
                setLoading(true);
                await onClick?.(event, data);
                setLoading(false);
            } catch (e) {
                setLoading(false);
                throw e;
            }
        } else {
            return onClick?.(event, data);
        }
    };

    let contentIcon;
    let _popup: PopupProps = popup;
    if (loading) {
        contentIcon = <Icon loading={true} name={'spinner'} />;
    } else if (locked) {
        if (!_popup && !!locked) {
            if (typeof locked === 'string') {
                _popup = { content: locked };
            } else {
                _popup = { content: 'No Permission' };
            }
        }
        contentIcon = <Icon name={'lock'} />;
    } else if (typeof icon === 'string') {
        contentIcon = <Icon loading={loading} name={icon as SemanticICONS} />;
    } else {
        contentIcon = icon;
    }

    const renderButton = (fluid?: boolean) => {
        //IMPORTANT: wrap in span so that Popup could appear when button is disabled.
        return (
            <ButtonSUI
                basic={true}
                type="button"
                onClick={handleOnClick}
                icon={contentIcon}
                disabled={loading || disabled || !!locked}
                style={style}
                fluid={fluid}
                {...rest}
            />
        );
    };

    if (_popup?.content) {
        return (
            <Popup
                trigger={<ButtonContainer fluid={rest.fluid}>{renderButton(true)}</ButtonContainer>}
                position={'top center'}
                {..._popup}
            />
        );
    }

    return renderButton();
};

// alias
export const Button = ButtonWithPopup;
