import * as React from 'react';
import { DevOnly } from './DevOnly';
import { NoPrint } from '../print-components';
import { ViewJSON, ViewJSONProps } from './ViewJSON';

export interface DebugJSONProps extends ViewJSONProps {}

export const DebugJSON: React.FC<DebugJSONProps> = ({ hidden, ...props }) => {
    if (hidden) {
        return null;
    }
    return (
        <DevOnly>
            <NoPrint>
                <ViewJSON {...props} />
            </NoPrint>
        </DevOnly>
    );
};
