import * as React from 'react';

import { Loader, Segment, Dimmer } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledDimmer = styled(Dimmer)`
    background-color: unset !important;
`;

export const Loading = (props) => {
    let { content = 'Loading', height = 160 } = props;
    return (
        <Segment basic>
            <StyledDimmer active inverted>
                <Loader size={'small'} inverted content={content} />
            </StyledDimmer>
            <div style={{ height: height }}>{'  '}</div>
        </Segment>
    );
};
