import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from '@ez/tools';
import { FormLabel } from './form-components';
import { Icon, SemanticICONS } from 'semantic-ui-react';

interface DebouncedInputProps<T = string> {
    initialValue?: T;
    onChange: (value: T) => any;
    label?: string;
    rightIcon?: SemanticICONS;
    placeholder?: string;
    id?: string;
    ariaLabel?: string;
    type?: 'number' | 'text';
    step?: number;
}

export const DebouncedInput: React.FC<DebouncedInputProps> = ({
    initialValue,
    onChange,
    placeholder,
    label,
    id,
    step,
    type,
    ariaLabel,
    rightIcon,
    ...props
}) => {
    const [term, setTerm] = useState(initialValue);

    useEffect(() => {
        setTerm(initialValue);
    }, [initialValue]);

    const [debouncedCallback] = useDebouncedCallback((value) => {
        onChange(value);
    }, 800);

    const handleChange = (e) => {
        const value = e.target.value;
        setTerm(value);
        debouncedCallback(value);
    };

    const handleOnClear = (value) => {
        // console.log('onClear', value);
        setTerm(value);
        onChange && onChange(value);
    };

    const ariaText = ariaLabel || ariaLabel || label || placeholder;

    return (
        <div style={{ minWidth: '160px' }} className={'field'}>
            {label && <FormLabel htmlFor={id}>{label}</FormLabel>}
            <div className="ui fluid icon input small">
                <input
                    id={id}
                    type={type || 'text'}
                    step={step}
                    aria-label={ariaText}
                    placeholder={placeholder}
                    value={term || ''}
                    onChange={handleChange}
                    {...props}
                />
                {term ? (
                    <i aria-hidden="true" className="remove link icon" onClick={() => handleOnClear('')} />
                ) : (
                    rightIcon && <Icon name={rightIcon} />
                )}
            </div>
        </div>
    );
};
