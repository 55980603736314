import * as React from 'react';

import { Button, Modal, StrictButtonProps } from 'semantic-ui-react';
import { ButtonWithPopup, ButtonWithPopupProps } from './ButtonWithPopup';

export interface ConfirmButtonProps extends ButtonWithPopupProps {
    confirmButton?: StrictButtonProps;
    cancelButton?: StrictButtonProps;
    confirmMessage?: {
        header?: string | React.ComponentType<any> | React.ReactNode;
        content?: string | React.ReactNode;
    };
    modalSize?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
    name?: string;
    showLoaderOnConfirm?: boolean;
}

// @deprecated
export class ConfirmButton extends React.Component<ConfirmButtonProps> {
    state = {
        showConfirm: false,
        loading: false,
    };

    public static defaultProps: ConfirmButtonProps = {
        basic: true,
        size: 'mini',
        showLoaderOnConfirm: true,
    };

    showConfirm = () => {
        this.setState({ showConfirm: true });
    };

    handleCancel = () => {
        this.setState({ showConfirm: false });
    };

    handleConfirm = async (e, data) => {
        if (this.props.showLoaderOnConfirm) {
            this.setState({ loading: true });
            if (this.props.onClick) {
                await this.props.onClick(e, data);
            }
            this.setState({ loading: false, showConfirm: false });
        } else {
            this.setState({ showConfirm: false });
            if (this.props.onClick) {
                await this.props.onClick(e, data);
            }
        }
    };

    render() {
        const {
            confirmMessage = {},
            confirmButton,
            cancelButton,
            modalSize,
            negative = false,
            onClick,
            showLoaderOnConfirm,
            ...rest
        } = this.props;

        const confirmProps = {
            header: confirmMessage.header || 'Are you sure?',
            content: confirmMessage.content,
            confirmButton: confirmButton || 'Yes',
        };

        const confirmButtonProps: StrictButtonProps = {
            content: 'Yes',
            positive: !negative,
            negative: negative,
            basic: true,
            disabled: this.state.loading,
            loading: this.state.loading,
            onClick: this.handleConfirm,
            ...confirmButton,
        };

        const cancelButtonProps: StrictButtonProps = {
            content: 'No',
            basic: true,
            onClick: this.handleCancel,
            disabled: this.state.loading,
            ...cancelButton,
        };

        return (
            <Modal
                size={modalSize || 'tiny'}
                open={this.state.showConfirm}
                onClose={() => {}}
                centered={false}
                // dimmer={false}
                trigger={<ButtonWithPopup type={'button'} negative={negative} {...rest} onClick={this.showConfirm} />}
            >
                <Modal.Header>{confirmProps.header}</Modal.Header>
                {confirmProps.content && <Modal.Content>{confirmProps.content}</Modal.Content>}
                <Modal.Actions>
                    <Button type={'button'} {...cancelButtonProps} />
                    <Button type={'button'} {...confirmButtonProps} />
                </Modal.Actions>
            </Modal>
        );
    }
}
