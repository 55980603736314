import * as React from 'react';
import { Icon, IconProps, Popup, PopupProps } from 'semantic-ui-react';

export interface IconWithPopupProps extends IconProps {
    popup?: PopupProps;
}
export const IconWithPopup: React.FC<IconWithPopupProps> = ({ popup, ...iconProps }) => {
    const icon = <Icon {...iconProps} />;
    if (!popup) {
        return icon;
    }
    return <Popup position={'top center'} size={'small'} trigger={icon} {...popup} />;
};
