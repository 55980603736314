import * as React from 'react';
import { useState } from 'react';
import {
    Button,
    Dropdown,
    Icon,
    IconProps,
    SemanticCOLORS,
    SemanticICONS,
    SemanticSIZES,
    SemanticWIDTHS,
} from 'semantic-ui-react';
import { FormLabel } from '../form-components';
import { cn, useSafeState } from '@ez/tools';
import { defaultOptionValueComparatorFn, Optionable, OptionableComponent } from '../optionable';
import tw, { css, styled } from 'twin.macro';

export interface SegmentedButtonProps<V = any> extends OptionableComponent<V> {
    color?: SemanticCOLORS;
    label?: string;
    fluid?: boolean;
    widths?: SemanticWIDTHS | 'equal';
    vertical?: boolean;
    size?: SemanticSIZES;
    disabled?: boolean;
    separated?: boolean;
}
const NO_MUTATION = '____NO_MUTATIONS____';

export const DropdownSelectButton: React.FC<SegmentedButtonProps> = ({
    label,
    onChange,
    options,
    value,
    fluid = true,
    color,
    valueComparator = defaultOptionValueComparatorFn,
}) => {
    const [mutatingValue, setMutatingValue] = useState(NO_MUTATION);
    const handleClick = (o) => async (e) => {
        if (!onChange) {
            return;
        }
        setMutatingValue(o.value);
        try {
            await onChange(o);
        } catch (e) {}
        setMutatingValue(NO_MUTATION);
    };

    const currentOption = options.find((o) => valueComparator(value, o));
    const icon = currentOption?.icon;

    const text = currentOption?.text || '-';

    return (
        <div style={{ width: fluid && '100%' }}>
            {label && <FormLabel>{label}</FormLabel>}
            <Dropdown
                text={text}
                icon={icon}
                floating
                labeled
                button
                fluid={fluid}
                className={cn('icon', { [color]: !!color })}
                basic={true}
                loading={mutatingValue !== NO_MUTATION}
                clearable={true}
            >
                <Dropdown.Menu direction={'left'} color={'blue'}>
                    {options.map((option) => (
                        <Dropdown.Item
                            key={option.value}
                            icon={option.icon}
                            value={option.value}
                            text={option.text}
                            onClick={handleClick(option)}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

const ButtonChangeStatusStyled = styled.button<{ active?: boolean; relaxed?: boolean }>(({ active, relaxed }) => [
    tw`hover:cursor-pointer pl-2 pr-2 text-sm rounded`,
    tw`text-blue-500 bg-white hover:bg-blue-100 border border-blue-300`,
    active && tw`text-white bg-blue-600 border-blue-600 hover:bg-blue-700`,
    css`
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        &[disabled] {
            opacity: 0.45 !important;
            pointer-events: none !important;
            cursor: default;
        }
    `,
    relaxed && tw`p-3 text-base`,
]);

interface SeparateButtonProps<V = any> extends Optionable<V> {
    active: boolean;
    relaxed?: boolean;
    onClick: (value: any) => any;
    disabled?: boolean;
    loading?: boolean;
    color?: SemanticCOLORS;
}

const SeparateButton: React.FC<SeparateButtonProps> = ({
    disabled,
    active,
    text,
    icon,
    onClick,
    relaxed,
    value,
    loading,
}) => {
    let _icon;
    // if (loading) {
    //     _icon = <Icon name={'spinner'} inverted={true} />;
    // } else
    if (typeof icon === 'string') {
        _icon = <Icon name={icon as SemanticICONS} inverted={active} />;
    } else {
        _icon = icon;
    }

    return (
        <Button
            icon={_icon}
            loading={loading}
            color={'blue'}
            basic={!active}
            size={'mini'}
            onClick={() => onClick(value)}
            disabled={disabled}
            active={active}
            content={text}
        />
    );

    return (
        <ButtonChangeStatusStyled
            className={'ui button'}
            disabled={disabled}
            active={active}
            onClick={() => onClick(value)}
            relaxed={relaxed}
        >
            {_icon} {text}
        </ButtonChangeStatusStyled>
    );
};

export const SegmentedSelectButtons: React.FC<SegmentedButtonProps> = (props) => {
    const {
        label,
        onChange,
        options,
        value,
        widths,
        fluid = true,
        color,
        vertical = false,
        size = 'tiny',
        disabled,
        separated,
        valueComparator = defaultOptionValueComparatorFn,
    } = props;
    const [mutatingValue, setMutatingValue] = useSafeState(NO_MUTATION);
    const handleClick = (o) => async (e) => {
        if (!onChange) {
            return;
        }
        setMutatingValue(o.value);
        try {
            await onChange(o);
        } catch (e) {
            console.error(e);
        }
        setMutatingValue(NO_MUTATION);
    };

    if (separated) {
        return (
            <div tw={'flex flex-row flex-wrap gap-2 justify-between'}>
                {options.map((o, index) => {
                    const isActive = valueComparator(value, o);
                    const isMutating = valueComparator(mutatingValue, o);
                    const isMutationInProgress = mutatingValue !== NO_MUTATION || disabled;
                    return (
                        <SeparateButton
                            value={o.value}
                            key={index}
                            disabled={isMutationInProgress}
                            loading={isMutating}
                            color={isActive ? color || 'teal' : 'grey'}
                            active={isActive}
                            onClick={handleClick(o)}
                            text={o.text || null}
                            icon={o.icon}
                        />
                    );
                })}
                <div tw={'flex-grow'} />
            </div>
        );
    }

    const optionsLength: any = widths === 'equal' ? options.length : widths;
    return (
        <div style={{ width: fluid && '100%' }}>
            {label && <FormLabel>{label}</FormLabel>}
            <Button.Group size={size} fluid={fluid} widths={optionsLength} vertical={vertical}>
                {options.map((o, index) => {
                    const isActive = valueComparator(value, o);
                    const isMutating = valueComparator(mutatingValue, o);
                    const isMutationInProgress = mutatingValue !== NO_MUTATION || disabled;
                    return (
                        <Button
                            style={{ padding: '0.78571429em' }}
                            key={index}
                            disabled={isMutationInProgress}
                            loading={isMutating}
                            basic={!isActive}
                            color={isActive ? color || 'teal' : 'grey'}
                            active={isActive}
                            onClick={handleClick(o)}
                            content={o.text || null}
                            icon={o.icon}
                            size={size}
                        />
                    );
                })}
            </Button.Group>
        </div>
    );
};
