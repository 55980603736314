import styled from 'styled-components';
import * as React from 'react';

export const TwoColumnGrid = styled.div`
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
    justify-items: stretch;
    align-items: stretch;
    justify-content: stretch;
    align-content: stretch;
    grid-template-rows: auto;
`;

const ThreeColumnGrid = styled.div<{ colsTemplate?: string; vStackBrkPt?: number; gridGap?: number }>`
    width: 100%;
    height: 100%;
    display: grid;
    grid-gap: ${({ gridGap }) => (gridGap ? gridGap : 1)}rem;
    grid-template-columns: ${(props) => props.colsTemplate || '300px minmax(0, 1fr) 300px'};
    justify-items: stretch;
    align-items: stretch;
    justify-content: stretch;
    align-content: stretch;
    grid-template-rows: auto;

    @media screen and (max-width: ${({ vStackBrkPt }) => (vStackBrkPt ? vStackBrkPt : 1200)}px) {
        display: block;
        &&& > :first-child {
            margin-bottom: 1rem !important;
        }
    }
`;

type ColType = '1fr' | '2fr' | string;

export interface MultiColumnGridProps {
    col1?: ColType;
    col2?: ColType;
    col3?: ColType;
    gridGap?: number;
    vStackBrkPt?: number;
}
export const MultiColumnGrid: React.FC<MultiColumnGridProps> = (props) => {
    const { col1, col2, col3, vStackBrkPt, gridGap, children } = props;
    let template = '1fr';
    if (col1) {
        template = col1;
    }
    if (col2) {
        template = template + ' ' + col2;
    }
    if (col3) {
        template = template + ' ' + col3;
    }

    return (
        <ThreeColumnGrid gridGap={gridGap} vStackBrkPt={vStackBrkPt} colsTemplate={template}>
            {children}
        </ThreeColumnGrid>
    );
};
