import * as React from 'react';
import { Icon, PopupProps, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { MenuItemWithPopup, MenuItemWithPopupProps } from './MenuItemWithPopup';
import { useSafeState } from '@ez/tools';

type Omit<T, K> = Pick<T, Exclude<keyof T, K>>;

export interface MenuBarItemProps extends Omit<MenuItemWithPopupProps, 'content'> {
    color?: SemanticCOLORS;
    icon?: SemanticICONS | React.ReactNode;
    position?;
    onClick?;
    title?;
    popup?: PopupProps;
    loading?: boolean;
    disabled?: boolean;
    responsive?: boolean;
    solid?: boolean;
    // Default lock reason is "No Permission"
    // Provide string value to set custom lock reason.
    locked?: boolean | string;
}

export const MenuBarItem: React.FC<MenuBarItemProps> = (props) => {
    const {
        color,
        icon,
        position,
        onClick,
        title,
        children,
        popup = undefined,
        loading = false,
        disabled = false,
        responsive = true,
        solid = false,
        locked = false,
    } = props;

    const [isMutating, setMutating] = useSafeState(false);

    const handleClick = async () => {
        setMutating(true);
        await onClick?.();
        setMutating(false);
    };

    let contentIcon;
    let _popup: PopupProps = popup;
    if (isMutating) {
        contentIcon = <Icon loading={true} name={'spinner'} />;
    } else if (locked) {
        if (!_popup && !!locked) {
            if (typeof locked === 'string') {
                _popup = { content: locked };
            } else {
                _popup = { content: 'No Permission' };
            }
        }
        contentIcon = <Icon name={'lock'} />;
    } else if (typeof icon === 'string') {
        contentIcon = <Icon loading={loading} color={color} name={icon as SemanticICONS} />;
    } else {
        contentIcon = icon;
    }

    const contentBody = (
        <>
            {title}
            {children}
        </>
    );

    return (
        <MenuItemWithPopup
            popup={_popup}
            tabIndex="0"
            position={position}
            onClick={handleClick}
            solid={solid}
            color={color}
            disabled={isMutating || disabled || !!locked}
        >
            {contentIcon}
            {responsive && icon ? <span tw={'hidden md:block'}>{contentBody}</span> : contentBody}
        </MenuItemWithPopup>
    );
};
