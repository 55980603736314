import * as React from 'react';
import { Button, ButtonGroupProps, Dropdown, DropdownProps, Header } from 'semantic-ui-react';
import styled from 'styled-components';
import { HeaderButtons, HeaderButtonType } from './HeaderButtons';
import { HeaderIconProp, iconPropToComp } from './helpers';
import tw, { css } from 'twin.macro';
import { useAppBreakpoints } from '../use-app-breakpoints';
import { ButtonWithPopup, ButtonWithPopupProps } from '../buttons';

const StyledContainer = styled(Header)<{ dividing?: boolean }>(({ dividing = true }) => [
    css`
        font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
        font-weight: normal;
        line-height: 1.5em;
        text-transform: none;
        //font-size: 1.1em;

        margin: 1em 0 0.5em;

        &:first-child {
            margin-top: 0;
        }
    `,
    tw`text-gray-600 pb-1 text-base md:text-lg`,
    dividing && tw`border-0 border-b border-gray-300 border-solid`,
]);

const LeftSpan = styled.span``;

const RightSpan = styled.span`
    float: right;
    margin-top: -3px;
`;

export interface SectionHeaderProps {
    content?: string | React.ReactNode;
    headerComp?: any;
    dividing?: boolean;
    icon?: HeaderIconProp;
    button?: HeaderButtonType | HeaderButtonType[];
    autoHideButtonsContent?: boolean;
    size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge';
}

const SectionHeaderComp: React.FC<SectionHeaderProps> = (props) => {
    const { isMobile } = useAppBreakpoints();
    let { size, autoHideButtonsContent, headerComp, content, icon, button, children, dividing } = props;

    let iconComp = iconPropToComp(icon);
    return (
        <StyledContainer size={size} dividing={dividing}>
            <LeftSpan>
                {iconComp}
                {content}
                {children}
                {headerComp}
            </LeftSpan>
            <RightSpan className={'no-print'}>
                <HeaderButtons buttons={button} autoHideButtonsContent={autoHideButtonsContent && isMobile} />
            </RightSpan>
        </StyledContainer>
    );
};

export const SectionHeaderButtonGroup: React.FC<ButtonGroupProps> = (props) => {
    return <Button.Group basic={true} size={'tiny'} {...props} />;
};

export const SectionHeaderButton: React.FC<ButtonWithPopupProps> = (props) => {
    return <ButtonWithPopup basic={true} size={'tiny'} style={{ padding: '0.4em 1em' }} {...props} />;
};

export const SectionHeaderDropdownButton: React.FC<{ content?: React.ReactNode } & DropdownProps> = ({
    content,
    ...props
}) => {
    return (
        <Dropdown
            style={{ padding: '0.4em 0.5em' }}
            className="button tiny icon"
            floating
            size={'tiny'}
            basic={true}
            value={false}
            icon={'ellipsis vertical'}
            trigger={<>{content}</>}
            {...props}
        />
    );
};

export class SectionHeader extends React.Component<SectionHeaderProps, any> {
    public static ButtonGroup = SectionHeaderButtonGroup;
    public static Button = SectionHeaderButton;
    public static DropdownButton = SectionHeaderDropdownButton;

    render() {
        return <SectionHeaderComp {...this.props} />;
    }
}
