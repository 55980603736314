import * as React from 'react';
import { useMemo, useState } from 'react';
import { SemanticICONS, Tab } from 'semantic-ui-react';
import { TabGroupMODE, TabKeyType, TabPaneRenderConf, TabStyleMODE } from './PageTabs';

interface CreateTabPaneInputType {
    activeTab: TabKeyType;
    key: TabKeyType;
    title: string | React.ReactNode;
    label?: string | number | React.ReactNode;
    icon?: SemanticICONS | React.ReactNode;
    render: () => React.ReactNode;
    paneContainer?;
}

export const createTabPane = (input: CreateTabPaneInputType): TabPaneRenderConf => {
    const { key, title, icon, render, paneContainer: PaneContainer = Tab.Pane, label } = input;
    return {
        menuItem: {
            key: key,
            content: title,
            icon: icon,
            label: label,
        },
        key: key,
        render: render,
    };
};

type Maybe<P> = P | null | undefined | false;

interface TabPaneConfStrict extends Omit<CreateTabPaneInputType, 'key' | 'activeTab'> {
    key?: TabKeyType;
}

export type TabPaneConf = Maybe<TabPaneConfStrict>;

export const useTabCtrl = (input: {
    onTabChange?: (tab?: string | number) => any;
    panes: TabPaneConf[];
    defaultTab?: string | number;
    activeTab?: string;
    groupMode?: TabGroupMODE;
    tabStyle?: TabStyleMODE;
}) => {
    const { panes, defaultTab, onTabChange, activeTab: activeTabExternal, ...rest } = input;
    const [activeTab, setActiveTab] = useState<string | number>(defaultTab);

    const _activeTab = activeTabExternal !== undefined ? activeTabExternal : activeTab;

    const tabPanes = useMemo(() => {
        const p = panes?.filter((p): p is TabPaneConfStrict => !!p);
        return p.map((p, index) =>
            createTabPane({
                key: 'tab-' + p.title + index,
                ...p,
                activeTab: _activeTab,
            })
        );
    }, [panes]);

    const setTab = (activeIndex) => {
        const tab = tabPanes[activeIndex];
        if (tab.key === _activeTab) {
            return;
        }
        setActiveTab(tab.key);
        onTabChange?.(tab.key);
    };

    const activeIndex = tabPanes.findIndex((p) => p.key === _activeTab);
    return {
        onTabChange: setTab,
        panes: tabPanes,
        activeIndex: activeIndex === -1 ? 0 : activeIndex,
        ...rest,
    };
};
