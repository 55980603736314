import * as React from 'react';
import { connect as connectFormik, getIn } from 'formik';
import { FormFieldLabel } from '../form-components';

export const FormikFormFieldLabel = connectFormik<{
    label?: string | React.ReactNode;
    name: string;
    required?: boolean;
    htmlFor?: string;
    disabled?: boolean;
    readOnly?: boolean;
}>((props) => {
    const { label, disabled, htmlFor, name, required, formik, readOnly } = props;
    if (!label) return null;
    const touched = getIn(formik.touched, name);
    const error = getIn(formik.errors, name);
    let hasError = touched && error;
    return (
        <FormFieldLabel
            readOnly={readOnly}
            disabled={disabled}
            required={required}
            label={label}
            errorLabel={hasError}
            htmlFor={htmlFor}
        />
    );
});
