import { ThemeInterface, ThemeInterfaceFoundation } from './theme.interface';

export const getDefaultTheme = (publicAssetPrefix): ThemeInterface => {
    const prefix = publicAssetPrefix || '';

    const foundation: ThemeInterfaceFoundation = {
        color: {
            background: '#fdfdfa',
            primary: '#363636',
        },
        text: {
            size: {
                base: '14px',
            },
            color: {
                primary: '#363636',
                secondary: undefined,
            },
        },
        borders: {
            radiusSm: '0.375rem',
            radius: '0.5rem',
            radiusMd: '0.75rem',
            radiusXl: '1.0rem',
            radius2xl: '1.5rem',
            radius3xl: '2rem',
            color: `hsl(240, 20%, 90%)`,
            colorStrong: `hsl(240, 20%, 85%)`,
        },
    };

    return {
        publicPrefix: prefix,
        themeName: 'default',
        logoPath: `${prefix}/logo.png`,
        iconPath: `${prefix}/icon.png`,

        ...foundation,
        sidebar: {
            backgroundColor: '#44475a',
            menuItem: {
                backgroundColor: '#44475a',
                backgroundColorHover: '#6272a4',
                backgroundColorActive: '#282a36',
                textColor: '#f8f8f2',
                ascentColorActive: '#f8f8f2',
            },
        },
        navbar: {
            backgroundColor: '#282a36',
            menuItem: {
                backgroundColor: '#282a36',
                backgroundColorHover: '#6272a4',
                backgroundColorActive: '#44475a',
                textColor: '#f8f8f2',
            },
            logo: {
                backgroundColor: '#1f1f27',
            },
            separatorColor: '#ffb86c',
        },
        main: {
            backgroundColor: '#f5f6ff',
        },
        panel: {
            header: {
                backgroundColor: `hsl(240, 20%, 96%)`,
                color: '#282a36',
            },
            body: {
                backgroundColor: `hsl(240, 20%, 99%)`,
            },
            item: {
                label: {
                    color: foundation.text.color.primary || '#282a36',
                },
            },
        },
    };
};
