import * as React from 'react';
import { useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import { Icon, Portal } from 'semantic-ui-react';
import { NoPrint } from '../print-components';
import { DebugJSONProps } from './DebugJSON';
import { useKeyPress, usePersistedString, usePersistedToggle } from '@ez/tools';
import { ScrollableLayout, SlideInDock } from '../layout-primitives';
import { PageTabs, TabPaneConf, useTabCtrl } from '../tab';
import { ViewJSON } from './ViewJSON';

const StyledDrawerToggleButton = styled.button(() => [
    tw`rounded-l-lg p-0 border border-gray-400 border-r-0 bg-gray-50 outline-none`,
    tw`fixed bottom-24 right-0 w-6 h-16 print:hidden z-10`,
]);

const DrawerToggleButton = ({ open, onClick, ...rest }) => {
    return (
        <StyledDrawerToggleButton aria-hidden="true" onClick={onClick} {...rest}>
            <Icon name={open ? 'chevron right' : 'chevron left'} />
        </StyledDrawerToggleButton>
    );
};

export interface DebugPanelTabProps {
    title: string;
    jsonData?: DebugJSONProps;
    render?: () => React.ReactNode;
}

export interface DebugPanelProps {
    showDimmer?: boolean;
    tabs: DebugPanelTabProps[];
}

export const DebugPanel: React.FC<DebugPanelProps> = ({ showDimmer = true, tabs }) => {
    const [isOpen, setOpen] = usePersistedToggle('debugPanel.isOpen', false);
    const [activeTab, setActiveTab] = usePersistedString('debugPanel.activeTab', undefined);

    // ctrl+d
    useKeyPress('d', true, () => {
        setOpen((s) => !s);
    });

    const toggleDrawer = () => {
        setOpen((isOpen) => !isOpen);
    };

    const panes: TabPaneConf[] = useMemo(() => {
        if (!tabs) return [];
        return tabs.map<TabPaneConf>((tab, i) => {
            if (tab.jsonData) {
                return {
                    title: tab.title,
                    key: 'tab_' + i,
                    render: () => <ViewJSON {...tab.jsonData} />,
                };
            } else if (tab.render) {
                return {
                    title: tab.title,
                    key: 'tab_' + i,
                    render: tab.render,
                };
            }
        });
    }, [tabs]);

    const tabCtrl = useTabCtrl({ panes, defaultTab: activeTab, onTabChange: (tab) => setActiveTab(tab) });

    return (
        <NoPrint>
            <Portal open={true}>
                <DrawerToggleButton open={isOpen} onClick={toggleDrawer} />
            </Portal>
            <SlideInDock preferredWidth={'620px'} open={isOpen} onClose={() => setOpen(false)}>
                <ScrollableLayout.BodyScroll>
                    <PageTabs {...tabCtrl} />
                </ScrollableLayout.BodyScroll>
            </SlideInDock>
        </NoPrint>
    );
};
