import * as React from 'react';
import { HTMLAttributes } from 'react';
import { Button, Dropdown, Icon, Menu, MenuMenu, SemanticCOLORS, SemanticICONS, DropdownItem } from 'semantic-ui-react';
import { LayoutControlProps } from './AppLayoutManager';
import * as _ from 'lodash';
import { styled } from 'twin.macro';
import { defaultMenuItemActiveBgColor, defaultSeparatorColor, defaultSidebarBgColor, defaultTextOnDarkColor } from './default-constants';

const StyledMenu = styled(Menu)`
    // background-color: ${(props) => _.get(props, 'theme.navbar.backgroundColor', '#44444')} !important;
    background-color: var(--ez-navbar-background-color, ${defaultSidebarBgColor}) !important;
    & > .menu {
        height: calc(var(--topBar, 46px) - 3px) !important;
    }
`;

const StyledMenuItemBase = styled(Menu.Item)`
    border-radius: unset !important;
    border: none;
    color: var(--ez-navbar-menu-item-text-color, ${defaultTextOnDarkColor}) !important;
    background-color: var(--ez-navbar-menu-item-background-color, ${defaultSidebarBgColor}) !important;
    //background-color: ${(props) => _.get(props, 'theme.navbar.menuItem.backgroundColor')} !important;
`;

const StyledMenuItem = styled(StyledMenuItemBase)`
    &:hover {
        // background-color: ${(props) => _.get(props, 'theme.navbar.menuItem.backgroundColorHover', '#44444')} !important;
        background-color: var(--ez-navbar-menu-item-hover-background-color, ${defaultMenuItemActiveBgColor}) !important;
    }
`;

interface NavbarMenuItemProps extends HTMLAttributes<any> {
    onClick?: any;
    icon?: SemanticICONS;
    color?: SemanticCOLORS;
    content?: any;
}

const StyledMenuItemLabel = styled.span`
    padding-left: 0.5em;
    @media screen and (max-width: 767px) {
        display: none;
    }
`;

const NavbarMenuItem: React.FC<NavbarMenuItemProps> = ({ onClick, icon, color, content, children }) => {
    return (
        <StyledMenuItem onClick={onClick} tabIndex={0} as={'button'}>
            <span>
                {icon && <Icon circular inverted color={color} name={icon} />}
                <StyledMenuItemLabel>
                    {content}
                    {children}
                </StyledMenuItemLabel>
            </span>
        </StyledMenuItem>
    );
};

const NavbarMenuItemHeader: React.FC<NavbarMenuItemProps> = ({ onClick, icon, color, content, children }) => {
    return (
        <StyledMenuItemBase>
            <span>
                {icon && <Icon circular inverted color={color} name={icon} />}
                <StyledMenuItemLabel>
                    {content}
                    {children}
                </StyledMenuItemLabel>
            </span>
        </StyledMenuItemBase>
    );
};

const LogoMenuItem = styled(Menu.Item)`
    padding: 1rem !important;
    background-color: ${(props) => _.get(props, 'theme.navbar.logo.backgroundColor')} !important;
`;

const StyledDropdown = styled(Dropdown)`
    // color: ${(props) => _.get(props, 'theme.navbar.menuItem.textColor')} !important;
    // background-color: ${(props) => _.get(props, 'theme.navbar.menuItem.backgroundColor')} !important;
    color: var(--ez-navbar-menu-item-text-color, ${defaultTextOnDarkColor}) !important;
    background-color: var(--ez-navbar-menu-item-background-color, ${defaultSidebarBgColor}) !important;

    &.active {
        // background-color: ${(props) => _.get(props, 'theme.navbar.menuItem.backgroundColorActive', '#44444')} !important;
        background-color: var(--ez-navbar-menu-item-active-background-color, ${defaultMenuItemActiveBgColor}) !important;
    }

    &:hover {
        // background-color: ${(props) => _.get(props, 'theme.navbar.menuItem.backgroundColorHover', '#44444')} !important;
        background-color: var(--ez-navbar-menu-item-hover-background-color, ${defaultMenuItemActiveBgColor}) !important;
    }
`;

const StyledHeader = styled.header`
    border: none;
    border-bottom: 3px solid var(--ez-navbar-separator-color, ${defaultSeparatorColor});
    border-radius: 0;
    > .ui.menu {
        border-radius: 0;
        border: none;

        .active.item,
        .dropdown.item,
        .item {
            &:hover {
                //background-color: $header-color - 5%;
            }

            &:before {
                background-color: inherit !important;
        }
    }
`;

const NavbarMenu: React.FC<LayoutControlProps> = ({ showSidebarToggle, onToggleMenu, children }) => {
    return (
        <StyledMenu>
            {showSidebarToggle && onToggleMenu && (
                <StyledMenuItem className={'hamburger'} style={{ minWidth: '36px' }} onClick={() => onToggleMenu()}>
                    <Icon style={{ fontSize: '18px', color: 'white' }} inverted name={'bars'} />
                </StyledMenuItem>
            )}
            {children}
        </StyledMenu>
    );
};

const NavbarDropdown = (props) => <StyledDropdown {...props} />;

const NavbarDropdownMenu: React.FC<{
    icon?: SemanticICONS;
    iconLoading?: boolean;
    color?: SemanticCOLORS;
    simple?: boolean;
    label?: any;
    onOpen?: () => any;
}> = ({ icon, color, label, children, simple, iconLoading, ...rest }) => {
    const iconName = iconLoading ? 'spinner' : icon;
    const DropdownTrigger = (
        <span>
            {icon && <Icon circular inverted color={color} name={iconName} loading={iconLoading} />}
            {label && <StyledMenuItemLabel>{label}</StyledMenuItemLabel>}
        </span>
    );

    return (
        <StyledDropdown tabIndex={0} simple={simple} item labeled trigger={DropdownTrigger} {...rest}>
            <Dropdown.Menu>{children}</Dropdown.Menu>
        </StyledDropdown>
    );
};

const NavbarDropdownMenuItem = DropdownItem;
const NavbarMenuSection = MenuMenu;

const MenuItemButton = (props) => {
    return (
        <Menu.Item>
            <Button {...props} />
        </Menu.Item>
    );
};

export const Navbar = {
    Header: StyledHeader,
    Menu: NavbarMenu,
    MenuSection: NavbarMenuSection,
    MenuItem: NavbarMenuItem,
    MenuItemHeader: NavbarMenuItemHeader,
    MenuItemButton: MenuItemButton,
    Dropdown: NavbarDropdown,
    DropdownMenu: NavbarDropdownMenu,
    DropdownMenuItem: NavbarDropdownMenuItem,
    LogoMenuItem: LogoMenuItem,
};
