import * as React from 'react';
import styled from 'styled-components';

export interface BarProgressProps {
    isLoading?: boolean;
    barColor?: string;
    height?: number;
}

export const BarProgress = styled.div<BarProgressProps>`
    height: ${(props) => (props.height ? props.height : 4)}px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: ${(props) => (props.isLoading ? '#ADD0F3' : 'transparent')};

    &:before {
        display: block;
        position: absolute;
        content: '';
        left: -200px;
        width: 200px;
        height: ${(props) => (props.height ? props.height : 4)}px;
        background-color: ${(props) => props.barColor || '#749bc2'};
        animation: ${(props) => (props.isLoading ? 'loading 2s linear infinite' : undefined)};
    }

    @keyframes loading {
        from {
            left: -200px;
            width: 30%;
        }
        50% {
            width: 40%;
        }
        70% {
            width: 70%;
        }
        80% {
            left: 50%;
        }
        95% {
            left: 120%;
        }
        to {
            left: 100%;
        }
    }
`;
