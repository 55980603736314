import { Button, Modal, ModalProps, StrictButtonProps } from 'semantic-ui-react';
import * as React from 'react';
import { useState } from 'react';
import * as ReactDOM from 'react-dom';
import { useModalCtrl } from './use-modal-ctrl';
import { useIsMounted } from '@ez/tools';

export interface ConfirmProps {
    negative?: boolean;
    confirmButton?: StrictButtonProps;
    cancelButton?: StrictButtonProps;
    confirmMessage?: {
        header?: string | React.ComponentType<any> | React.ReactNode;
        content?: string | React.ReactNode;
    };
    modalSize?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen';
    showLoaderOnConfirm?: boolean;
}

export interface ConfirmModalProps extends ModalProps {
    confirm: ConfirmProps;
    onClick: any;
    onCancel?: () => any;
}

export const ConfirmModal: React.FC<ConfirmModalProps> = (props) => {
    const modalCtrl = useModalCtrl();
    const [loading, setLoading] = useState(false);

    const isMounted = useIsMounted();

    const shouldShowLoaderOnClick =
        props?.confirm?.showLoaderOnConfirm == undefined ? true : props?.confirm?.showLoaderOnConfirm;

    const handleCancel = () => {
        modalCtrl.onClose();
        props.onCancel && props.onCancel();
    };

    const handleConfirm = async (e, data) => {
        e.preventDefault();
        e.stopPropagation();

        if (shouldShowLoaderOnClick) {
            setLoading(true);
            await props.onClick?.(e, data);
            if (isMounted()) {
                setLoading(false);
                modalCtrl.onClose();
            }
        } else {
            modalCtrl.onClose();
            if (props.onClick) {
                await props.onClick(e, data);
            }
        }
    };

    const { confirm, onClick, ...rest } = props;
    const { confirmMessage = {}, negative = false, confirmButton, cancelButton, modalSize } = confirm || {};

    const confirmProps = {
        header: confirmMessage.header || 'Are you sure?',
        content: confirmMessage.content,
        confirmButton: confirmButton || 'Yes',
    };

    const confirmButtonProps: StrictButtonProps = {
        content: 'Yes',
        positive: !negative,
        negative: negative,
        basic: true,
        disabled: loading,
        loading: loading,
        ...confirmButton,
        onClick: handleConfirm,
    };

    const cancelButtonProps: StrictButtonProps = {
        content: 'No',
        basic: true,
        disabled: loading,
        ...cancelButton,
        onClick: handleCancel,
    };

    return (
        <Modal
            size={modalSize || 'tiny'}
            {...modalCtrl}
            onClose={handleCancel}
            {...rest}
            onClick={undefined} // IMPORTANT!!! Don't pass onClick handler!!!
        >
            <Modal.Header>{confirmProps.header}</Modal.Header>
            {confirmProps.content && <Modal.Content>{confirmProps.content}</Modal.Content>}
            <Modal.Actions>
                <Button type={'button'} {...cancelButtonProps} />
                <Button type={'button'} {...confirmButtonProps} />
            </Modal.Actions>
        </Modal>
    );
};

const confirmRoot = document.createElement('div');
const body = document.querySelector('body');
body.appendChild(confirmRoot);

export const confirmImperative = (
    DialogContent: (props: { giveAnswer: (answer: boolean) => void }) => React.ReactElement
): Promise<boolean> =>
    new Promise((res) => {
        const giveAnswer = (answer: boolean) => {
            ReactDOM.unmountComponentAtNode(confirmRoot);
            res(answer);
        };

        ReactDOM.render(<DialogContent giveAnswer={giveAnswer} />, confirmRoot);
    });

export const confirmModalImperative = async (confirm: ConfirmProps): Promise<boolean> =>
    confirmImperative(({ giveAnswer }) => {
        return (
            <ConfirmModal
                closeOnDocumentClick={false}
                onCancel={() => giveAnswer(false)}
                onClick={() => giveAnswer(true)}
                confirm={confirm}
                open={true}
            />
        );
    });
