import * as React from 'react';
import { Dropdown, Icon, Menu, Popup, Segment, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import tw, { styled } from 'twin.macro';
import { useAppBreakpoints } from '../use-app-breakpoints';

export enum TabGroupMODE {
    AUTO_COLLAPSE = 'AUTO_COLLAPSE',
    EXPANDED = 'EXPANDED',
}

export enum TabStyleMODE {
    TABULAR,
    SECONDARY_POINTING,
}

export type TabKeyType = string | number;

export interface TabPaneRenderConf {
    menuItem: {
        icon?: SemanticICONS | React.ReactNode;
        content: any;
        key: TabKeyType;
        label?: string | React.ReactNode;
    };
    key: TabKeyType;
    render: () => React.ReactNode;
}

export interface TabProps {
    onTabChange?: (index: number) => any;
    panes: TabPaneRenderConf[];
    activeIndex: number;
    groupMode?: TabGroupMODE;
    tabStyle?: TabStyleMODE;
    paneStyle?: any;
    color?: SemanticCOLORS;
}

interface DropDownItemProps {
    onTabChange?: (index: number) => any;
    panes: TabPaneRenderConf[];
    activeIndex: number;
}

const DropdownMenuStyled = styled(Dropdown.Menu)`
    &&& {
        ${tw`border border-solid border-gray-400 shadow-xl! rounded!`}
    }
`;

const DropDownItem: React.FC<DropDownItemProps> = ({ onTabChange, panes, activeIndex }) => {
    return (
        <Dropdown labeled icon={<Icon name="bars" color="blue" />} item text="">
            <DropdownMenuStyled>
                {panes.map((p, index) => {
                    const { menuItem } = p;
                    const isActive = index === activeIndex;
                    const hasLabel = !!menuItem.label && menuItem.label !== '';
                    const MI = typeof menuItem.icon === 'string' ? <Icon name={menuItem.icon as any} /> : menuItem.icon;
                    return (
                        <Dropdown.Item key={p.key} active={isActive} onClick={() => onTabChange?.(index)}>
                            {MI}
                            {menuItem.content}
                            {hasLabel && <span tw={'ml-1 px-2 text-xs rounded bg-gray-200'}>{menuItem.label}</span>}
                        </Dropdown.Item>
                    );
                })}
            </DropdownMenuStyled>
        </Dropdown>
    );
};

const PaneLg = ({ style, ...props }) => (
    <Segment style={{ backgroundColor: 'none', fontSize: '1em', ...style }} attached={'bottom'} {...props} />
);

const PaneSm = ({ style, ...props }) => (
    <Segment style={{ fontSize: '1em', padding: '1em 0.5em', ...style }} attached={'bottom'} {...props} />
);

const PaneSecondary = ({ style, ...props }) => <div tw={'pt-8 md:px-2 px-0'} {...props} />;

enum TabRenderMode {
    FullTabs = 'FullTabs',
    ReducedTabs = 'ReducedTabs',
    Collapsed = 'Collapsed',
}

export const PageTabs: React.FC<TabProps> = ({
    onTabChange,
    panes,
    activeIndex,
    groupMode = TabGroupMODE.AUTO_COLLAPSE,
    tabStyle = TabStyleMODE.TABULAR,
    paneStyle,
    color,
}) => {
    const layout = useAppBreakpoints();

    let renderMode: TabRenderMode = TabRenderMode.FullTabs;

    // Use different thresholds for different number of tabs.
    const pcount = panes.length;
    if (
        (layout.gteTablet && pcount < 5) ||
        (layout.gteDesktopSM && pcount < 7) ||
        (layout.gteDesktopMD && pcount < 9) ||
        (layout.gteDesktopLG && pcount < 12) ||
        layout.gteDesktopXL
    ) {
        renderMode = TabRenderMode.FullTabs;
    } else {
        renderMode = TabRenderMode.ReducedTabs;
    }

    if (groupMode === TabGroupMODE.AUTO_COLLAPSE) {
        if (layout.gteDesktopAny) {
            //noop for any desktop sizes
        } else {
            if ((layout.gteMobile && pcount < 3) || (layout.gteTablet && pcount < 6)) {
                renderMode = TabRenderMode.FullTabs;
            } else {
                renderMode = TabRenderMode.Collapsed;
            }
        }
    }

    const activePane = panes[activeIndex];

    if (!panes || panes.length === 0) {
        return null;
    }

    const isExpanded = renderMode !== TabRenderMode.Collapsed;
    const collapseTitle = renderMode === TabRenderMode.ReducedTabs;
    const showDropdownRight = renderMode === TabRenderMode.ReducedTabs;
    const showDropdownInline = renderMode === TabRenderMode.Collapsed;

    let menuProps = undefined;

    let PaneComp = layout.gteDesktopAny ? PaneLg : PaneSm;
    switch (tabStyle) {
        default:
        case TabStyleMODE.TABULAR:
            menuProps = {
                attached: 'top',
                tabular: true,
                color,
            };
            break;
        case TabStyleMODE.SECONDARY_POINTING:
            menuProps = {
                attached: 'tops',
                tabular: true,
                secondary: true,
                pointing: true,
                color,
            };
            PaneComp = PaneSecondary;
            break;
    }

    return (
        <div>
            <Menu {...menuProps}>
                <Menu.Menu position={'left'}>
                    {isExpanded ? (
                        <>
                            {panes.map((p, index) => {
                                const { menuItem } = p;
                                const isActive = index === activeIndex;
                                const content = collapseTitle && !isActive ? undefined : menuItem.content;
                                const hasLabel = !!menuItem.label && menuItem.label !== '';
                                return (
                                    <Popup
                                        key={index}
                                        position={'top center'}
                                        inverted={true}
                                        size={'tiny'}
                                        content={menuItem.content}
                                        disabled={!collapseTitle}
                                        trigger={
                                            <Menu.Item
                                                key={p.key}
                                                active={isActive}
                                                icon={menuItem.icon}
                                                onClick={() => onTabChange?.(index)}
                                                content={
                                                    <>
                                                        <span>{content}</span>
                                                        {hasLabel && (
                                                            <span tw={'ml-1 px-2 text-xs rounded bg-gray-300'}>
                                                                {menuItem.label}
                                                            </span>
                                                        )}
                                                    </>
                                                }
                                            />
                                        }
                                    />
                                );
                            })}
                        </>
                    ) : (
                        <>
                            <Menu.Item
                                key={activePane?.key}
                                active={true}
                                icon={activePane?.menuItem?.icon}
                                content={activePane?.menuItem?.content}
                            />
                            {showDropdownInline && (
                                <DropDownItem panes={panes} activeIndex={activeIndex} onTabChange={onTabChange} />
                            )}
                        </>
                    )}
                </Menu.Menu>

                {showDropdownRight && (
                    <Menu.Menu position={'right'}>
                        <DropDownItem panes={panes} activeIndex={activeIndex} onTabChange={onTabChange} />
                    </Menu.Menu>
                )}
            </Menu>
            <PaneComp style={paneStyle}>{activePane?.render()}</PaneComp>
        </div>
    );
};
