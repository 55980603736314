import * as React from 'react';
import { Transition, SemanticTRANSITIONS } from 'semantic-ui-react';

const OPEN_TIME = 500;
const CLOSE_TIME = 1000;

export interface SemanticTransitionProps {
    toastId: number;
    onClose: (toastId: number) => any;
    openAnimation: SemanticTRANSITIONS;
    closeAnimation: SemanticTRANSITIONS;
    time?: number;
}

export interface SemanticTransitionState {
    visible: boolean;
    time: number;
    animation: SemanticTRANSITIONS;
}

const withTransitions = <P extends SemanticTransitionProps>(Component: React.ComponentType<P>) => {
    return class SemanticTransition extends React.Component<P, SemanticTransitionState> {
        public static defaultProps = {
            time: 2000,
        };

        constructor(props, context) {
            super(props, context);
            this.state = {
                visible: false,
                time: OPEN_TIME,
                animation: this.props.openAnimation,
            };
        }

        timerId: number | any;

        componentDidMount() {
            // schedule auto closing of toast
            if (this.props.time) {
                this.timerId = setTimeout(this.onClose, this.props.time);
            }

            // start animation as soon as toast is mounted in the dom
            this.setState({ visible: true });
        }

        onClose = (toastId: number) => {
            // trigger new animation when toast is dismissed
            this.setState(
                (prevState) => ({
                    visible: !prevState.visible,
                    animation: this.props.closeAnimation,
                    time: CLOSE_TIME,
                }),
                () => {
                    setTimeout(() => {
                        if (this.timerId) {
                            clearTimeout(this.timerId);
                        }

                        this.props.onClose(this.props.toastId);
                    }, CLOSE_TIME);
                }
            );
        };

        render() {
            const { time, visible, animation } = this.state;
            const styles = {
                marginBottom: '1em',
            };

            return (
                <Transition animation={animation} duration={time} visible={visible}>
                    <div style={styles} role="presentation">
                        <Component {...this.props} onClose={this.onClose} />
                    </div>
                </Transition>
            );
        }
    };
};

export default withTransitions;
