import { Button } from 'semantic-ui-react';
import * as React from 'react';
import styled from 'styled-components';

const ContainerDiv = styled.div.attrs({
    // @ts-ignore
    'data-testid': 'PageMenuBar',
})`
    @media print {
        display: none;
    }
    position: fixed;
    z-index: 1000;
    padding: 10px 20px;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0px 6px 8px -5px rgba(161, 161, 161, 1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

export const PrintPageMenuBar: React.FC<{ onCancel?: () => any }> = ({ onCancel, children }) => {
    const onPrint = () => {
        window.print();
    };

    return (
        <ContainerDiv>
            <Button secondary icon={'window close outline'} onClick={onCancel} content={'Cancel'} />
            <div>{children}</div>
            <Button primary icon={'print'} onClick={onPrint} content={'Print'} />
        </ContainerDiv>
    );
};
