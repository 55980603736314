import * as React from 'react';

export const HTMLCheckbox = ({ name, checked, label, onChange, ...rest }) => {
    const handleChange = (event) => {
        onChange && onChange(event, { ...event.target });
    };

    return (
        <div>
            <input type={'checkbox'} checked={checked} name={name} onChange={handleChange} />
            <label htmlFor={name}>{label}</label>
        </div>
    );
};

export const HTMLSelect = ({ name, options, value, label, onChange }) => {
    const handleChange = (event) => {
        onChange && onChange(event, { value: event.target.value });
    };

    return (
        <div>
            <label htmlFor={name}>{label}</label>
            <select name={name} onChange={handleChange} value={value}>
                {options.map((o, i) => {
                    return (
                        <option key={o.key || i} value={o.value}>
                            {o.text}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};
