import * as React from 'react';
import { DevOnly } from './DevOnly';
import { DebugJSON, DebugJSONProps } from './DebugJSON';
import { Button, ButtonProps, Modal } from 'semantic-ui-react';
import { useModalCtrl } from '../use-modal-ctrl';
import { Panel } from '../panel';

export interface DebugJsonButtonProps extends ButtonProps {
    debug?: DebugJSONProps;
    // `data` prop has priority over `debug.data` prop.
    data: DebugJSONProps['data'];
}

export const DebugJsonButton: React.FC<DebugJsonButtonProps> = ({ debug, data, ...props }) => {
    const modalCtrl = useModalCtrl();
    return (
        <DevOnly>
            <Button basic={true} color={'orange'} onClick={modalCtrl.onOpen} icon={'bug'} {...props} />
            <Modal {...modalCtrl}>
                <Panel>
                    <Panel.Header content={'Debug Info'} button={{ icon: 'cancel', onClick: modalCtrl.onClose }} />
                    <Panel.Body>
                        <DebugJSON {...debug} data={data} />
                    </Panel.Body>
                </Panel>
            </Modal>
        </DevOnly>
    );
};
