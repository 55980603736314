import * as React from 'react';
import { Dropdown, Icon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { MenuBarItem, MenuBarItemProps } from './MenuBarItem';
import { MenuBarItemWithConfirm, MenuBarItemWithConfirmProps } from './MenuBarItemWithConfirm';
import 'twin.macro';

export interface MenuBarDropdownProps {
    collapsed?: boolean;
    icon?: SemanticICONS | React.ReactElement;
    color?: SemanticCOLORS;
    title?: any;
    disabled?: boolean;
    responsive?: boolean;
    direction?: 'left' | 'right';
}
export const MenuBarDropdown: React.FC<MenuBarDropdownProps> = ({
    collapsed,
    icon,
    color,
    title,
    disabled,
    children,
    direction,
    responsive = true,
}) => {
    let IconComp: React.ReactElement;
    if (icon) {
        if (typeof icon === 'string') {
            IconComp = <Icon color={color} name={icon as SemanticICONS} />;
        } else {
            IconComp = icon;
        }
    }

    const trigger = (
        <span>
            {IconComp}
            {responsive ? <span tw={'hidden md:inline'}>{collapsed ? '' : title}</span> : collapsed ? '' : title}
        </span>
    );

    return (
        <Dropdown
            item
            labeled
            disabled={disabled}
            trigger={trigger}
            // direction={direction} // Not working with Fomantic. Causes issues
        >
            <Dropdown.Menu>{children}</Dropdown.Menu>
        </Dropdown>
    );
};

export const MenuBarDropdownItem: React.FC<MenuBarItemProps> = (props) => {
    return <MenuBarItem responsive={false} {...props} />;
};

export const MenuBarDropdownItemWithConfirm: React.FC<MenuBarItemWithConfirmProps> = (props) => {
    return <MenuBarItemWithConfirm responsive={false} {...props} />;
};

export const MenuBarDropdownDivider = Dropdown.Divider;
