import * as React from 'react';
import { SegmentedSelectButtons } from '../buttons';
import { FormikFormFieldLabel } from './FormikFormFieldLabel';
import { Form, SemanticCOLORS } from 'semantic-ui-react';
import { useField } from 'formik';
import { Optionable } from '../optionable';

export interface FormikSegmentedSelectButtonsProps<T = any> {
    name: string;
    options: Optionable<T>[];
    label?: string | React.ReactNode;
    onDidChange?: (newValue: T) => any;
    color?: SemanticCOLORS;
    readOnly?: boolean;
}

export const FormikSegmentedSelectButtons: React.FC<FormikSegmentedSelectButtonsProps> = ({
    name,
    label,
    options,
    onDidChange,
    color,
    readOnly,
}) => {
    const [field, meta, helpers] = useField<any>({ name: name });

    const handleChange = async (item) => {
        await onDidChange?.(item.value);
        helpers.setValue(item.value);
    };

    return (
        <Form.Field>
            {label && <FormikFormFieldLabel label={label} name={name} />}
            <SegmentedSelectButtons
                disabled={readOnly}
                color={color}
                widths={'equal'}
                value={field.value}
                options={options}
                onChange={handleChange}
            />
        </Form.Field>
    );
};
