import * as React from 'react';
import { Input, Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

const StyledEditButton = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    color: #c2c1c1;
    &:active {
        color: blue;
    }
`;

const StyleSpan = styled.span`
    border: 1px solid rgba(1, 1, 1, 0);
    border-radius: 4px;
    padding: 3px;
    margin-left: -3px;
    &:hover {
        border: 1px solid #bfdef7;
    }
`;

const ButtonsContainer = styled.div`
    position: absolute;
    right: -80px;
    top: 0;
    z-index: 1;
    background-color: white;
    border: 1px solid gray;
    padding: 2px 4px;
`;

const InputContainer = styled.div`
    position: relative;
`;

interface EditOnClickTextState {
    isEditing: boolean;
    initialValue: string;
    value: string;
    isDirty: boolean;
    isSubmitting: boolean;
}

export interface ContentEditableTextProps {
    value: string;
    editable?: boolean;
    onCommit?: (newValue: string) => any | Promise<any>;
    inputType?: string;
    style?: any;
}

export class ContentEditableText extends React.Component<ContentEditableTextProps, EditOnClickTextState> {
    private readonly textInputRef: React.RefObject<any>;
    private readonly textDomInputRef: React.RefObject<any>;

    private static defaultProps = {
        editable: true,
        inputType: 'text',
    };

    constructor(props, context) {
        super(props, context);

        this.textInputRef = React.createRef();
        this.textDomInputRef = React.createRef();
        const value = props.value || '';
        this.state = {
            isEditing: false,
            initialValue: value,
            value: value,
            isDirty: false,
            isSubmitting: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<ContentEditableTextProps>, nextContext: any): void {
        if (this.state.isEditing) return;

        if (nextProps.value !== this.state.initialValue) {
            this.setState({ initialValue: nextProps.value, value: nextProps.value });
        }
    }

    private onChange = (e, { value }) => {
        this.setState({
            value: value,
            isDirty: true,
        });
    };

    private onStartEdit = (e) => {
        e.preventDefault();
        const { value } = this.props;

        this.setState({
            isEditing: true,
            value: value,
        });
        this.focusTextInput();
    };

    private onCancelEdit = () => {
        const { initialValue } = this.state;
        this.setState({
            isEditing: false,
            value: initialValue,
        });
    };

    private onSave = async () => {
        const { value: newValue, initialValue, isDirty } = this.state;

        if (!this.props.onCommit || newValue === initialValue || !isDirty) {
            this.setState({ isEditing: false });
            return;
        }

        this.setState({ isSubmitting: true });
        await this.props.onCommit(newValue);
        this.setState({ isSubmitting: false, isEditing: false });
    };

    private focusTextInput = () => {
        setTimeout(() => {
            if (!this.textInputRef.current) return;
            this.textInputRef.current.focus();

            // there is some delay before textDomInputRef becomes available.
            // Wait for a few ticks.
            this.textDomInputRef.current.select();
        }, 100);
    };

    private keyDown = async (event) => {
        if (event.key === 'Enter') {
            // Enter
            await this.onSave();
        } else if (event.key === 'Escape') {
            // Escape
            this.onCancelEdit();
        }
    };

    private renderEditor = () => {
        const { isDirty, isSubmitting, value, isEditing } = this.state;

        // Keep Input mounted at all the time.
        // Otherwise textInputRef behaves funny.

        const style = !isEditing ? { position: 'absolute', left: '-15000px' } : {};
        return (
            <InputContainer>
                <Input
                    style={style}
                    size={'mini'}
                    fluid
                    action
                    value={value || ''}
                    hidden={!isEditing}
                    onChange={this.onChange}
                    ref={this.textInputRef}
                >
                    <input type={this.props.inputType} ref={this.textDomInputRef} onKeyDown={this.keyDown} />
                    <Button
                        size={'mini'}
                        basic
                        icon={'checkmark'}
                        type={'button'} // Trigger button on 'Enter/Return' key hit
                        disabled={!isDirty || isSubmitting}
                        color={isDirty ? 'green' : 'grey'}
                        loading={isSubmitting}
                        onClick={this.onSave}
                    />
                    <Button
                        size={'mini'}
                        basic
                        type={'button'} // Trigger button on 'Enter/Return' key hit
                        icon={'close'}
                        disabled={isSubmitting}
                        onClick={this.onCancelEdit}
                    />
                </Input>
            </InputContainer>
        );
    };

    render() {
        const { isEditing, isSubmitting } = this.state;
        const { value, editable, style } = this.props;

        if (!editable) {
            return <>{value}</>;
        }

        return (
            <div
                // onBlur={isEditing && this.onCancelEdit}
                style={{ minWidth: isEditing ? '180px' : undefined, width: '100%', display: 'inline-block', ...style }}
            >
                {this.renderEditor()}
                {!isEditing && (
                    <>
                        <StyleSpan onClick={this.onStartEdit}>{value}</StyleSpan>
                        <StyledEditButton onClick={this.onStartEdit}>
                            <Icon loading={isSubmitting} name={isSubmitting ? 'spinner' : 'edit'} />
                        </StyledEditButton>
                    </>
                )}
            </div>
        );
    }
}
