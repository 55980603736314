import * as React from 'react';
import { ConnectionTableProps } from './ConnectionTable';
import { DebugJSON, DevOnly } from '../dev-tools';

export interface ConnectionTableDebugProps extends ConnectionTableProps {
    hidden?: boolean;
}

export const ConnectionTableDebug: React.FC<ConnectionTableDebugProps> = ({
    connectionState,
    connectionData,
    hidden,
}) => {
    if (hidden) {
        return null;
    }
    return (
        <DevOnly>
            <DebugJSON data={{ connectionState, connectionData }} jsonExpandLevel={1} />
        </DevOnly>
    );
};
