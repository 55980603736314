import styled from 'styled-components';
import { default as React, useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { Portal } from 'semantic-ui-react';

const PopperBox = styled.div`
    max-width: 280px;
    font-size: 0.95em;
    line-height: 1.4em;
    background-color: #444444;
    color: white;
    border: 1px solid #7a7a7a;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    padding: 0.25em 1em;
    z-index: 10000;
    left: -120px;
`;

export const Hover = ({ trigger, content }) => {
    const [isOpen, setOpen] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'right',
        modifiers: [
            { name: 'arrow', options: { element: arrowElement } },
            { name: 'offset', options: { offset: [0, 5] } },
        ],
    });

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', onLeave);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', onLeave);
        };
    });

    const onEnter = () => {
        setOpen(true);
    };

    const onLeave = () => {
        setOpen(false);
    };

    return (
        <>
            <div
                onFocus={onEnter}
                onBlur={onLeave}
                onMouseEnter={onEnter}
                onMouseLeave={onLeave}
                ref={setReferenceElement}
                style={{ minWidth: '40px' }}
            >
                {trigger}
            </div>

            <Portal open={isOpen}>
                <PopperBox ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                    {content}
                </PopperBox>
            </Portal>
        </>
    );
};
