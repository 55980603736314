// Helper hook for controlling SemanticUI's Modal window component.
import { useSafeState } from '@ez/tools';

export function useModalCtrl<P = any>(initialOpen: boolean = false) {
    const [open, setOpen] = useSafeState(initialOpen);
    const [payload, setPayload] = useSafeState<P>(null);

    const onOpen = (payload?: P) => {
        setPayload(payload);
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
        setPayload(null);
    };

    const modalProps = {
        centered: false,
        open,
        onOpen: () => onOpen(),
        onClose,
    };

    return {
        // modalProps,
        centered: false,
        open,
        onOpen,
        onClose,
        payload,
    };
}
