import * as React from 'react';
import { PageSkeletonLoader } from '../spinners';
import { DebugJSON } from '../dev-tools';

export interface SuspenseSkeletonLoaderProps {
    loading?: boolean;
    error?: Error;
}

export const SuspenseSkeletonLoader: React.FC<SuspenseSkeletonLoaderProps> = ({ loading, error, children }) => {
    if (loading) {
        return <PageSkeletonLoader />;
    } else if (error) {
        return (
            <div>
                Error.
                <DebugJSON data={error} />
            </div>
        );
    }

    return <>{children}</>;
};
