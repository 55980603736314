import * as React from 'react';
import { Button, Icon, Popup, PopupProps, StrictButtonProps, StrictPopupProps } from 'semantic-ui-react';
import { ButtonWithPopup, ButtonWithPopupProps, MenuButton, MenuButtonItemConf } from '../buttons/';

export interface HeaderButtonProps extends StrictButtonProps {
    menu?: any;
    menuItems?: MenuButtonItemConf[];
    popup?: StrictPopupProps;
    autoHideContent?: boolean;
    // Default lock reason is "No Permission"
    // Provide string value to set custom lock reason.
    locked?: boolean | string;
    hidden?: boolean;
}

export type HeaderButtonType = HeaderButtonProps | React.ReactNode;

const isButtonProps = (button: HeaderButtonType): button is HeaderButtonProps => {
    return !React.isValidElement(button);
};

export const HeaderButtons: React.FC<{
    autoHideButtonsContent?: boolean;
    buttons?: HeaderButtonType | HeaderButtonType[];
}> = ({ buttons, autoHideButtonsContent }) => {
    const renderButtons = (button) => {
        if (!button || button.hidden) {
            return null;
        }

        if (button instanceof Array) {
            return button
                .filter((b) => !!b) // remove undefined
                .map((b, i) => ({
                    ...renderButtons(b),
                    key: i,
                }));
        }

        if (!isButtonProps(button)) {
            return button;
        }

        let { popup, content, locked, autoHideContent = true, icon, disabled, ...bp } = button;

        let _icon = icon;
        let _popup: PopupProps = popup;
        if (locked) {
            if (!_popup && !!locked) {
                if (typeof locked === 'string') {
                    _popup = { content: locked };
                } else {
                    _popup = { content: 'No Permission' };
                }
            }
            _icon = <Icon name={'lock'} />;
        }

        if (autoHideContent != false && autoHideButtonsContent && bp && icon) {
            // Don't show `content` on mobile screens, only icon if icon is set.
            content = undefined;
        }

        // Note: if content=='', then convert it to undefined.
        // It prevents from printing a whitespace in button.
        content = content ? content : undefined;

        const style = {
            // Fixes some SemanticUI issues where only icon is provided
            padding: '.75em 1.2em',
        };

        const buttonProps: any = {
            size: 'mini',
            basic: true,
            // floated: 'right',
            type: 'button',
            style: style,
            icon: _icon,
            disabled: disabled || !!locked,
            ...bp,
            content: content,
        };

        const renderButton = () => {
            // WARNING!!!
            // IMPORTANT: DON'T DELETE SPAN!!
            // Wrap in span so that Popup could appear when button is disabled.

            const { menu, menuItems } = button;
            return (
                <span>
                    {menu || menuItems ? (
                        <MenuButton {...buttonProps} menu={menu} menuItems={menuItems} />
                    ) : (
                        <Button {...buttonProps} />
                    )}
                </span>
            );
            // END OF WARNING!!
        };

        if (_popup) {
            return <Popup trigger={renderButton()} {..._popup} />;
        } else {
            return renderButton();
        }
    };

    return renderButtons(buttons);
};
