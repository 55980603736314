import * as React from 'react';
import { useState } from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import styled from 'styled-components';
import { Manager, Popper, Reference } from 'react-popper';
import { DateRangePicker, DateRange, DateRangePickerProps } from './DateRangePicker';
import { Display } from '../display';

const Arrow = styled.div`
    position: absolute;
    width: 1em;
    height: 3em;
    margin-left: -1em;
    &::before {
        content: '';
        margin: auto;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
    }
    &[data-placement*='bottom'] {
        top: 0;
        left: 0;
        margin-top: -2em;
        width: 1em;
        height: 1em;
        &::before {
            border-width: 1em;
            border-color: transparent transparent #7a7a7a transparent;
        }
    }
    &[data-placement*='top'] {
        bottom: 0;
        left: 0;
        margin-bottom: -0.9em;
        width: 3em;
        height: 1em;
        &::before {
            border-width: 1em 1.5em 0 1.5em;
            border-color: #7a7a7a transparent transparent transparent;
        }
    }
    &[data-placement*='right'] {
        left: 0;
        margin-left: -0.9em;
        height: 3em;
        width: 1em;
        &::before {
            border-width: 1em;
            border-color: transparent #7a7a7a transparent transparent;
        }
    }
    &[data-placement*='left'] {
        right: 0;
        margin-right: -0.9em;
        height: 3em;
        width: 1em;
        &::before {
            border-width: 1.5em 0 1.5em 1em;
            border-color: transparent transparent transparent #7a7a7a;
        }
    }
`;

const PopperBox = styled.div`
    width: 640px;
    background-color: #ffffff;
    border: 1px solid #7a7a7a;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    margin: 1em;
    padding: 0.5em;
    z-index: 10000;
`;

export interface DateRangePickerButton extends DateRangePickerProps {
    buttonProps?: ButtonProps;
}
export const DateRangePickerButton: React.FC<DateRangePickerButton> = ({
    onChange,
    initialDateRange,
    buttonProps,
    ...props
}) => {
    const [isOpen, setOpen] = useState(false);

    const handleDateRangeChange = (newDateRange: DateRange) => {
        setOpen(false);
        onChange && onChange(newDateRange);
    };

    const handleOnCancel = () => {
        setOpen(false);
    };

    return (
        <Manager>
            <Reference>
                {({ ref }) => (
                    <div ref={ref} className="react-datepicker__input-container">
                        <Button
                            basic
                            size={'tiny'}
                            fluid
                            type="button"
                            {...buttonProps}
                            onClick={() => setOpen(!isOpen)}
                        >
                            <Display.DateRange {...initialDateRange} />
                        </Button>
                    </div>
                )}
            </Reference>
            {isOpen && (
                <Popper placement="bottom">
                    {({ ref, style, placement, arrowProps }) => (
                        <PopperBox ref={ref} style={style as any} data-placement={placement}>
                            <DateRangePicker
                                initialDateRange={initialDateRange}
                                onChange={handleDateRangeChange}
                                onCancel={handleOnCancel}
                                {...props}
                            />
                            <Arrow ref={arrowProps.ref} data-placement={placement} style={arrowProps.style as any} />
                        </PopperBox>
                    )}
                </Popper>
            )}
        </Manager>
    );
};
