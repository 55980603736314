import { Icon, IconProps, SemanticICONS } from 'semantic-ui-react';
import * as React from 'react';

export type HeaderIconProp = SemanticICONS | React.ReactNode | IconProps;

export const isSemanticICONS = (icon?: HeaderIconProp): icon is SemanticICONS => {
    return typeof icon === 'string';
};
export const isSemanticIconProps = (icon?: HeaderIconProp): icon is IconProps => {
    return typeof icon === 'object';
};

export const iconPropToComp = (iconProp?: HeaderIconProp): React.ReactNode => {
    if (!iconProp) {
        return undefined;
    }
    if (isSemanticICONS(iconProp)) {
        return <Icon name={iconProp} />;
    } else if (isSemanticIconProps(iconProp)) {
        return <Icon {...iconProp} />;
    } else {
        return iconProp;
    }
};
