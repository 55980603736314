import * as React from 'react';
import tw, { styled, css } from 'twin.macro';

const scrollbarWidth = '.5em';
const scrollbarFgColor = '#7f91a1';
const scrollbarBgColor = '#a8bed0';

const SidebarAside = styled.aside`
    display: block;
    margin-top: -1.5rem;
    margin-left: -1.5rem;
    margin-bottom: -1rem;
    padding-top: 1rem;
    padding-left: 1rem;
    height: calc(100% + 2rem);
    box-shadow: 0px -8px 8px 1px rgb(93 93 93 / 38%);
`;

const SidebarMenuContainer = styled.div`
    height: 100%;
    overflow-y: auto;
    // For Google Chrome
    &::-webkit-scrollbar {
        width: ${scrollbarWidth};
        height: ${scrollbarWidth};
    }

    &::-webkit-scrollbar-thumb {
        background: ${scrollbarFgColor};
    }

    &::-webkit-scrollbar-track {
        background: ${scrollbarBgColor};
    }

    body {
        // Internet Explorer
        scrollbar-face-color: ${scrollbarFgColor};
        scrollbar-track-color: ${scrollbarBgColor};
    }
`;

const SidebarMenu = styled.div`
    display: flex;
    flex-direction: column;
    color: ${(props) => props.theme?.panel?.header?.color || '#222222'};
    background-color:  ${(props) => props?.theme?.sidebar?.backgroundColor || '#44444'}
    border: none;
    width: 100%;
    flex: 0 0 auto;
    overflow-x: hidden;
`;

const SidebarMenuSpacer = styled.div`
    height: 60px;
`;

const SidebarMenuBottom = styled(SidebarMenu)`
    flex: 0 0 auto;
    background-color: pink;
    justify-content: flex-end;
`;

const SidebarMenuGroup = styled.div`
    margin: 0;
    padding: 0.25rem 0.5rem 1rem 0.5rem;
    border-radius: 0;

    border-top: 1px solid rgba(255, 255, 255, 0.25);

    &:first-child {
        border-top: none !important;
    }
`;

const SidebarMenuHeader = styled.div(() => [
    css`
        color: ${(props) => props.theme?.panel?.header?.color || '#222222'};
    `,
    tw`text-xs font-thin uppercase tracking-widest whitespace-nowrap`,
    tw`m-0 py-1 pr-0 pl-3`,
]);

const SidebarMenuGroupContent = styled.div``;

const createSidebarMenuItem = (C) => styled(C)`
    margin: 0.125rem 0;
    font-size: 0.95rem;
    letter-spacing: 0.5px;
    padding: 0.25rem 0 0.25rem 0.75rem;
    display: block;
    cursor: pointer;
    border-bottom: none;
    border-radius: 4px;
    border-right-width: 3px;
    font-weight: 100;
    color: ${(props) => props.theme?.panel?.header?.color || '#222222'};

    > .secondary-icon {
        padding-left: 0.5em;
    }

    > .main-icon {
        padding-right: 1.5rem;
    }

    &.sub {
        font-size: 0.9em;
        padding-left: 2.4rem;
        & > .main-icon {
            padding-right: 0.5rem;
        }
    }

    &.active {
        background-color: ${(props) => props?.theme?.sidebar?.menuItem?.backgroundColorActive || '#44444'};
        color: ${(props) => props?.theme?.sidebar?.menuItem?.textColor || '#fdfdfa'};
        border-right-style: solid;
        border-right-width: 3px;
        border-color: ${(props) => props?.theme?.navbar?.separatorColor || '#FFFFFF'};
    }

    &:hover {
        background-color: ${(props) => props?.theme?.sidebar?.menuItem?.backgroundColorHover || '#44444'};
        color: ${(props) => props?.theme?.sidebar?.menuItem?.textColor || '#fdfdfa'};
    }

    // Labels are displayed in one line. Prevent from wrapping.
    white-space: nowrap;
`;

export const SidebarSecondary = {
    Aside: SidebarAside,
    MenuContainer: SidebarMenuContainer,
    Menu: SidebarMenu,
    MenuSpacer: SidebarMenuSpacer,
    MenuBottom: SidebarMenuBottom,
    MenuHeader: SidebarMenuHeader,
    MenuGroup: SidebarMenuGroup,
    MenuGroupContent: SidebarMenuGroupContent,
    createMenuItem: createSidebarMenuItem,
};
