import { Popup, PopupProps, Table, TableRowProps } from 'semantic-ui-react';
import * as React from 'react';
import styled from 'styled-components';

const styledSelectableTd = styled.tr`
    cursor: pointer;
    &&&:hover {
        background-color: #e8e8e9;
    }
`;

const styledNonselectableTd = styled.tr`
    cursor: default;
    background-color: #e7e7e7;
    &&&:hover {
        background-color: #e8e8e9;
    }
`;

export interface TableRowClickableProps extends TableRowProps {
    onClick?: () => any;
    popup?: PopupProps;
    isSelectable?: boolean;
}
export const TableRowClickable: React.FC<TableRowClickableProps> = ({
    onClick,
    popup,
    isSelectable = true,
    ...props
}) => {
    const as = isSelectable ? styledSelectableTd : styledNonselectableTd;
    const tr = <Table.Row as={as} onClick={isSelectable ? onClick : undefined} {...props} />;

    if (popup) {
        return <Popup {...popup} trigger={tr} />;
    } else {
        return tr;
    }
};
