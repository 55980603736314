import { Icon } from 'semantic-ui-react';
import * as React from 'react';
import styled from 'styled-components';

export interface PageHeaderProps {
    maxWidth?: number;
}

const TruncatedDiv = styled.div<{ maxWidth?: number }>`
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: ${(props) => props.maxWidth || 300}px;
    overflow: hidden;
    //padding: 3px 5px;
    //margin: -3px -5px;
    &.popup {
        &:hover {
            background-color: #f0f0f0;
            border-radius: 2px;
        }
    }
`;

export const PageHeader: React.FC<PageHeaderProps> = ({ children }) => {
    return <div tw={'flex flex-row min-w-0 w-full overflow-hidden text-lg font-bold'}>{children}</div>;
};

export const PageHeaderSub: React.FC<PageHeaderProps & { level?: 0 | 1 | 2 }> = ({
    children,
    level = 0,
    maxWidth = 1000,
}) => {
    const offsetLeft = `${level}rem`;
    return (
        <div tw={'font-normal flex flex-row text-sm'} style={{ paddingLeft: offsetLeft }}>
            <Icon name="caret right" />
            <TruncatedDiv maxWidth={maxWidth}>{children}</TruncatedDiv>
        </div>
    );
};
