import * as React from 'react';
import { Divider, Segment } from 'semantic-ui-react';
import * as PIs from './PanelItem';
import { PanelItems } from './PanelItems';

import PanelHeader from './PanelHeader';
import PanelBody from './PanelBody';
import PanelFooter from './PanelFooter';
import 'twin.macro';
import { SectionHeader } from './SectionHeader';

export interface PanelProps {}

export class Panel extends React.Component<PanelProps> {
    public static Header = PanelHeader;
    public static Body = PanelBody;
    public static Footer = PanelFooter;
    public static Divider = PIs.PanelItemDivider;
    public static SectionHeader = SectionHeader;
    public static Item = PIs.PanelItem;
    public static Items = PanelItems;
    public static ItemEmail = PIs.PanelItemEmail;
    public static ItemPhone = PIs.PanelItemPhone;
    public static ItemDate = PIs.PanelItemDate;
    public static ItemAddress = PIs.PanelItemAddress;
    public static ItemEntity = PIs.PanelItemEntity;
    public static ItemText = PIs.PanelItemText;
    public static ItemJSONView = PIs.PanelItemJSONView;

    render() {
        return (
            <div tw={'my-0 w-full'}>
                <Segment.Group>{this.props.children}</Segment.Group>
            </div>
        );
    }
}

export interface Panel extends React.Component<PanelProps> {
    Header: typeof PanelHeader;
    Body: typeof PanelBody;
    Footer: typeof PanelFooter;
    Segment: typeof Segment;
    Divider: typeof Divider;
    SectionHeader: typeof SectionHeader;
    Item: typeof PIs.PanelItem;
    Items: typeof PanelItems;
    ItemEmail: typeof PIs.PanelItemEmail;
    ItemPhone: typeof PIs.PanelItemPhone;
    ItemDate: typeof PIs.PanelItemDate;
    ItemAddress: typeof PIs.PanelItemAddress;
    ItemEntity: typeof PIs.PanelItemEntity;
    ItemText: typeof PIs.PanelItemText;
}
