import * as React from 'react';
import { Table, TableCellProps, TableProps } from 'semantic-ui-react';
import { ConnectionErrorMessage } from '../Errors';
import * as _ from 'lodash';
import { BarProgress, TableLineLoader } from '../spinners';
import { NoDataMessage } from './NoDataMessage';
import { isFunction } from '../formik-components/utils';
import { TableRowClickable } from './TableRowClickable';
import { QueryConnectionResult, QueryConnectionState, SortDirection } from './types';

type HeaderPropsFn = (connectionState: QueryConnectionState) => any;

type TableDefCellPropsFn<T> = (
    connectionDataItem: T,
    connectionState: QueryConnectionState,
    columnIndex: number
) => TableCellProps;

export interface TableDefColumn<T = any> {
    sortKey?: string;
    header: string | React.ReactNode | HeaderPropsFn;
    cellProps?: Object | TableDefCellPropsFn<T>;
    headerCellProps?: Object;
    cell: (connectionDataItem: T, connectionState: QueryConnectionState) => string | React.ReactNode;
    onCellClick?: (connectionDataItem: T, connectionState: QueryConnectionState) => any;
    [key: string]: any;
}

export type TableRowDef<T = any> = (
    connectionDataItem: T,
    connectionState: QueryConnectionState
) => {
    comp?: React.ComponentType<any>;
    [key: string]: any;
};

export type TableDefColumnOptional<T> = TableDefColumn<T> | false | undefined | null;
export type ConnectionTableDef<T> = TableDefColumnOptional<T>[];
export type TableDef<T> = ConnectionTableDef<T>; // alias

export interface ConnectionTableProps<T = any, B = any, C = any> extends QueryConnectionResult<T> {
    tableDef: ConnectionTableDef<T>;
    tableRowDef?: TableRowDef<T>;
    tableProps?: TableProps;
    tableCellProps?: Object | TableDefCellPropsFn<T>;
    tableHeaderCellProps?: TableCellProps;
    tableFooter?: React.ReactNode;
    noDataComponent?: React.ReactElement | null;
    onRowClick?: (connectionDataItem, connectionState) => any;
    showHeader?: boolean;
    showPagination?: boolean;
    isRowSelectable?: (item: T) => Boolean;
}

export const ConnectionTable: React.FC<ConnectionTableProps> = ({
    tableDef: origTableDef,
    tableRowDef,
    tableProps,
    tableCellProps,
    tableHeaderCellProps,
    tableFooter,
    showHeader = true,
    connectionData,
    connectionState,
    noDataComponent,
    onRowClick,
    isRowSelectable = () => true,
}) => {
    const error = connectionState?.error || null;
    const loading = connectionState?.loading || false;
    const isInitialLoading = connectionState?.isInitialLoading || false;

    // remove falsy columns
    const tableDef = origTableDef?.filter(Boolean) as TableDefColumn[];

    const numOfColumns = tableDef.length;

    const isSortable = tableDef.reduce((acc, td) => {
        if (td.sortKey !== undefined) return true;
        return acc;
    }, false);

    const renderEmpty = () => {
        if (noDataComponent === null) {
            return null;
        }

        const columnNum = tableDef.length;
        return (
            <Table.Row>
                <Table.Cell colSpan={columnNum} textAlign={'center'}>
                    {noDataComponent ? noDataComponent : <NoDataMessage />}
                </Table.Cell>
            </Table.Row>
        );
    };

    const renderBody = () => {
        if (!connectionData || connectionData.length === 0) {
            return renderEmpty();
        }

        return connectionData.map((connectionItem, index) => {
            let RowComponent: any = Table.Row;
            let rowProps = undefined;
            if (tableRowDef) {
                const { comp, isSelectable, ...rest } = tableRowDef(connectionItem, connectionState);
                if (comp) {
                    RowComponent = comp;
                }
                rowProps = rest;
            } else if (onRowClick) {
                RowComponent = (props) => (
                    <TableRowClickable
                        isSelectable={isRowSelectable?.(connectionItem)}
                        onClick={(e) => {
                            e?.preventDefault();
                            e?.stopPropagation();
                            onRowClick(connectionItem, connectionState);
                        }}
                        {...props}
                    />
                );
            }

            return (
                <RowComponent key={connectionItem.id || index} {...rowProps}>
                    {tableDef.map((td, index) => {
                        const { textAlign } = td;
                        const rootCellProps = isFunction(tableCellProps)
                            ? tableCellProps(connectionItem, connectionState, index)
                            : tableCellProps;
                        const cellProps = isFunction(td.cellProps)
                            ? td.cellProps(connectionItem, connectionState, index)
                            : td.cellProps;
                        const mergedCellProps = _.merge(rootCellProps, { textAlign }, cellProps);

                        let handleCellClick = undefined;
                        if (td.onCellClick) {
                            handleCellClick = (e) => {
                                e?.preventDefault();
                                e?.stopPropagation();
                                td.onCellClick(connectionItem, connectionState);
                            };
                        }

                        return (
                            <Table.Cell
                                onClick={handleCellClick}
                                key={index}
                                verticalAlign={'top'}
                                singleLine
                                {...mergedCellProps}
                            >
                                {td.cell(connectionItem, connectionState)}
                            </Table.Cell>
                        );
                    })}
                </RowComponent>
            );
        });
    };

    const renderHeaderRow = () => {
        return (
            <Table.Row>
                {tableDef.map((col, index) => {
                    const { header, cell, sortKey, cellProps, headerCellProps, onCellClick, ...rest } = col;
                    const isSortable = !!sortKey;
                    const isSorting = col.sortKey !== undefined && connectionState?.sortKey === col.sortKey;
                    let sortedProps = undefined;
                    if (isSorting) {
                        sortedProps = connectionState?.sortDirection === SortDirection.ASC ? 'ascending' : 'descending';
                    }

                    const onSortClick = () => {
                        if (connectionState?.sortKey !== sortKey) {
                            connectionState?.setSortKey(sortKey);
                            // connectionState.setSortDirection(SortDirection.ASC);
                        }

                        if (connectionState?.sortKey === sortKey) {
                            const newDir =
                                connectionState?.sortDirection === SortDirection.ASC
                                    ? SortDirection.DES
                                    : SortDirection.ASC;
                            connectionState?.setSortDirection(newDir);
                        }
                    };

                    const p = isFunction(headerCellProps) ? headerCellProps(col) : headerCellProps;

                    return (
                        <Table.HeaderCell
                            onClick={isSortable ? onSortClick : undefined}
                            sorted={sortedProps}
                            singleLine
                            disabled={!isSortable}
                            key={index}
                            {...tableHeaderCellProps}
                            {...p}
                            {...rest}
                        >
                            {isFunction(col.header) ? col.header(connectionState) : col.header}
                        </Table.HeaderCell>
                    );
                })}
            </Table.Row>
        );
    };

    const renderLoader = () => {
        // Show 5 lines on initial load.
        const size = connectionData.length || 5;
        return _.range(size).map((i, index) => {
            return (
                <Table.Row key={index}>
                    {tableDef.map((td, index) => (
                        <Table.Cell key={index} {...td.cellProps}>
                            <TableLineLoader />
                        </Table.Cell>
                    ))}
                </Table.Row>
            );
        });
    };

    const renderError = () => {
        return (
            <Table.Row>
                <Table.Cell colSpan={tableDef.length}>
                    <ConnectionErrorMessage error={error} />
                </Table.Cell>
            </Table.Row>
        );
    };

    return (
        <Table size={'small'} compact={'very'} unstackable={true} celled={true} sortable={isSortable} {...tableProps}>
            {showHeader && <Table.Header>{renderHeaderRow()}</Table.Header>}
            <Table.Body>
                <tr>
                    <td tw={'p-0! m-0!'} colSpan={numOfColumns}>
                        <BarProgress height={2} isLoading={Boolean(connectionState?.loading)} />
                    </td>
                </tr>
                {isInitialLoading && renderLoader()}
                {!loading && error && renderError()}
                {!isInitialLoading && !error && renderBody()}
            </Table.Body>
            {tableFooter && (
                <Table.Footer fullWidth>
                    <Table.Row>
                        {tableFooter}
                        {/*<Table.HeaderCell colSpan={numOfColumns}>{tableFooter}</Table.HeaderCell>*/}
                    </Table.Row>
                </Table.Footer>
            )}
        </Table>
    );
};
