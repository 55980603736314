import * as React from 'react';
import { useState } from 'react';
import { Visibility } from 'semantic-ui-react';

import styled from 'styled-components';

const MenuPlaceholder = styled.div<{ h: number }>`
    height: ${(props) => props.h}px;
    z-index: 99;
    @media print {
        display: none;
    }
`;

const StickyDiv = styled.div<{ topOffset: number; layoutSwitchWidth: number }>`
    &.menu-sticky {
        position: fixed;
        top: ${({ topOffset }) => `var(--topBar, ${topOffset}px)`};
        right: 0;
        left: 0;
        z-index: 100;
        box-shadow: 0 0 9px 3px #333333;
        display: flex;
        background-color: white;
        justify-content: center;
        padding-left: var(--sideBar, 180px);
        padding-right: 1rem;

        & .pw-menu {
            //@include contentSize($content-size-normal);
            border-radius: 0 !important;
            box-shadow: none !important;
            border: none !important;
        }
    }
    @media print {
        &&& {
            display: none;
        }
    }
`;

const VisibilityStyled = styled(Visibility)`
    &&& {
        padding: 0;
        margin: 0 !important;
    }
`;

export const StickyMenuContainer: React.FC<{ height?: number; topOffset?: number }> = ({
    topOffset = 46,
    height = 42,
    children,
}) => {
    const [topVisible, setTopVisible] = useState(true);

    const handleVisibilityUpdateImmediate = (e, { calculations }) => {
        setTopVisible(calculations.topVisible);
    };

    let sticky = topVisible === false;
    let className = sticky ? 'menu-sticky' : '';

    return (
        <>
            <MenuPlaceholder h={height} hidden={!sticky} />
            <StickyDiv topOffset={topOffset} layoutSwitchWidth={768} className={className}>
                {children}
            </StickyDiv>
            <VisibilityStyled
                test-dataid={'Visibility'}
                offset={[topOffset + height, 0]}
                fireOnMount
                onUpdate={handleVisibilityUpdateImmediate}
            />
        </>
    );
};
