import * as React from 'react';
import { SemanticICONS } from 'semantic-ui-react';

export interface Optionable<V> {
    text: string;
    value: V;
    icon?: React.ReactNode | SemanticICONS;
}

export interface OptionableComponent<V> {
    onChange: (option: Optionable<V> | null) => any;
    value: V | null;
    options: Optionable<V>[];
    valueComparator?: (a: V, b: V) => boolean;
}

export interface OptionableL2<V1 = any, V2 = any> {
    text: string;
    value: V1;
    icon?: React.ReactNode | SemanticICONS;
    options?: Optionable<V2>[];
}

export interface OptionableComponentL2<V1, V2> {
    value: V1 | V2 | null;
    onChange: (optionL1: OptionableL2<V1, V2> | null, optionL2: Optionable<V2> | null) => any;
    options: OptionableL2<V1, V2>[];
    valueComparator?: (value: V1 | V2, option: Optionable<V1 | V2>) => boolean;
}

export const defaultOptionValueComparatorFn = <V = any>(value: V, option: Optionable<V>) => {
    return value === option?.value;
};
