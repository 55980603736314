import { css } from 'twin.macro';

// These variables are used in Fomantic CSS, Tailwind css and EZ Components
export const getThemeCssVars = () => {
    return css`
      :root {
        --ez-font-size-base: ${(props) => props.theme?.text?.size?.base || '14px'};

        --ez-color-primary: ${(props) => props.theme?.color?.primary};
        --ez-color-secondary: ${(props) => props.theme?.color?.secondary};

        --ez-color-text-primary: ${(props) => props.theme?.text?.color?.primary};
        --ez-color-background: ${(props) => props.theme?.main?.backgroundColor || '#f5f5f5'};

        --ez-border-radius-sm: ${(props) => props.theme?.borders?.radiusSm};
        --ez-border-radius-default: ${(props) => props.theme?.borders?.radius};
        --ez-border-radius-md: ${(props) => props.theme?.borders?.radiusMd};
        --ez-border-radius-lg: ${(props) => props.theme?.borders?.radiusLg};
        --ez-border-radius-xl: ${(props) => props.theme?.borders?.radiusXl};
        --ez-border-radius-2xl: ${(props) => props.theme?.borders?.radius2xl};
        --ez-border-radius-3xl: ${(props) => props.theme?.borders?.radius3xl};
        --ez-border-color: ${(props) => props.theme?.borders?.color || '#f5f5f5'};
        --ez-border-color-strong: ${(props) => props.theme?.borders?.colorStrong};

        --ez-panel-border-color: ${(props) => props?.theme?.panel?.borderColor || props.theme?.borders?.color || '#f5f5f5'};

        --ez-navbar-background-color: ${(props) => props.theme?.navbar?.backgroundColor};
        --ez-navbar-logo-background-color: ${(props) => props.theme?.navbar?.logo?.backgroundColor};
        --ez-navbar-logo-text-color: ${(props) => props.theme?.navbar?.logo?.textColor};
        --ez-navbar-separator-color: ${(props) => props.theme?.navbar?.separatorColor};
        --ez-navbar-menu-item-background-color: ${(props) => props.theme?.navbar?.menuItem?.backgroundColor};
        --ez-navbar-menu-item-hover-background-color: ${(props) => props.theme?.navbar?.menuItem?.backgroundColorHover};
        --ez-navbar-menu-item-active-background-color: ${(props) => props.theme?.navbar?.menuItem?.backgroundColorActive};
        --ez-navbar-menu-item-text-color: ${(props) => props.theme?.navbar?.menuItem?.textColor};

        --ez-sidebar-background-color: ${(props) => props.theme?.sidebar?.backgroundColor};
        --ez-sidebar-shadow: ${(props) => props.theme?.sidebar?.shadow};
        --ez-sidebar-menu-group-separator-color: ${(props) => props.theme?.sidebar?.menuGroup?.separatorColor};
        --ez-sidebar-menu-group-padding: ${(props) => props.theme?.sidebar?.menuGroup?.padding};

        --ez-sidebar-menu-item-background-color: ${(props) => props.theme?.sidebar?.menuItem?.backgroundColor};
        --ez-sidebar-menu-item-padding: ${(props) => props.theme?.sidebar?.menuItem?.padding};
        --ez-sidebar-menu-item-border-radius: ${(props) => props.theme?.sidebar?.menuItem?.borderRadius};
        --ez-sidebar-menu-item-hover-background-color: ${(props) => props.theme?.sidebar?.menuItem?.backgroundColorHover};
        --ez-sidebar-menu-item-active-background-color: ${(props) => props.theme?.sidebar?.menuItem?.backgroundColorActive};
        --ez-sidebar-menu-item-active-ascent-color: ${(props) => props.theme?.sidebar?.menuItem?.ascentColorActive};
        --ez-sidebar-menu-item-text-color: ${(props) => props.theme?.sidebar?.menuItem?.textColor};

      }
    }`;
};
