import { ThemeInterface } from './theme.interface';
import { getDefaultTheme } from './default-scheme';

import { deepMerge, hsl } from './utils';

export const createThemeByHue = (input: {
    publicPrefix?: string;
    huePrim: number;
    hueSec?: number;
    customisation?: Partial<ThemeInterface>;
}): ThemeInterface => {
    const { hueSec, huePrim, publicPrefix, customisation = {} } = input || {};
    const sat = 40;
    const lum = 33;

    const hue2 = hueSec;
    const sat2 = 77;
    const lum2 = 80;

    const color_primary_1 = hsl(huePrim, sat * 0.8, lum * 2);
    const color_primary_2 = hsl(huePrim, sat * 0.9, lum * 1.25);
    const color_primary_0 = hsl(huePrim, sat, lum);
    const color_primary_3 = hsl(huePrim, sat, lum * 0.75);
    const color_primary_4 = hsl(huePrim, sat, lum * 0.5);

    const color_secondary_0 = hsl(hue2, sat2, lum2);

    const textColorPrim = hsl(huePrim, 10, lum);

    const theme: Partial<ThemeInterface> = {
        publicPrefix: publicPrefix,
        themeName: 'poolware',
        color: {
            background: '#edf2f5',
            primary: color_primary_0,
            primaryHue: huePrim,
            secondaryHue: hueSec,
        },
        text: {
            color: {
                primary: textColorPrim,
            },
        },
        sidebar: {
            backgroundColor: color_primary_0,
            menuItem: {
                backgroundColor: color_primary_0,
                backgroundColorHover: color_primary_3,
                backgroundColorActive: color_primary_4,
                textColor: '#FFFFFF',
            },
        },
        navbar: {
            backgroundColor: color_primary_3,
            menuItem: {
                backgroundColor: color_primary_3,
                backgroundColorHover: color_primary_1,
                backgroundColorActive: color_primary_2,
                textColor: '#FFFFFF',
            },
            logo: {
                backgroundColor: '#FFFFFF',
                textColor: textColorPrim,
            },
            separatorColor: color_secondary_0,
        },
        main: {
            backgroundColor: hsl(huePrim, 20, 91),
        },
        panel: {
            header: {
                backgroundColor: hsl(huePrim, 10, 95),
                color: hsl(huePrim, 20, 10),
            },
            body: {
                backgroundColor: hsl(huePrim, 10, 99),
            },
            item: {
                label: {
                    color: hsl(huePrim, 10, lum),
                },
            },
        },
        components: {
            activeFilterColor: hsl(285, 70, 50),
        },
        borders: {
            color: `hsl(${huePrim}, 20%, 88%)`,
            colorStrong: `hsl(${huePrim}, 20%, 80%)`,
        },
    };

    const defaultTheme = getDefaultTheme(publicPrefix);

    return deepMerge(deepMerge(defaultTheme, theme), customisation);
};
