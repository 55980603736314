import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Input } from 'semantic-ui-react';
import styled from 'styled-components';
import moment from 'moment';

const TimeItemLabel = styled.div`
    //width: 3em;
    display: inline-block;
`;

const TimeItemDiv = styled.div`
    margin-bottom: 0.5em;
    .ui.input.active input {
        border: #0077cc solid 3px;
    }
`;

export const TimeItemInput: React.FC<{
    label: string;
    value: Date;
    minDate: Date | null;
    maxDate: Date | null;
    onChange: (value: Date) => any;
    active: boolean;
    onSelect: () => any;
}> = ({ label, value, active, onChange, onSelect, minDate, maxDate }) => {
    const stringFormat = 'DD-MM-YYYY';
    const [_stringValue, setStringValue] = useState('--');
    const [isValid, setIsValid] = useState(true);

    const handleChange = (e, { value }) => {
        setStringValue(value);
        const parsedDate = moment(value, stringFormat, true);
        const isValid = parsedDate.isValid();
        if (!isValid) {
            // date string is invalid
            setIsValid(false);
            return;
        }

        // check if the data is within the specified range.
        if (minDate && moment(minDate).isAfter(parsedDate)) {
            setIsValid(false);
            return;
        }

        if (maxDate && moment(maxDate).isBefore(parsedDate)) {
            setIsValid(false);
            return;
        }

        setIsValid(true);
    };

    useEffect(() => {
        if (value) {
            setStringValue(moment(value).format(stringFormat));
        } else {
            setStringValue('---');
        }
    }, [value]);

    let handleOnBlur = () => {
        const parsedDate = moment(_stringValue, stringFormat, true);
        if (parsedDate.isValid()) {
            onChange(parsedDate.toDate());
        } else {
            setIsValid(false);
        }
    };

    return (
        <TimeItemDiv>
            <TimeItemLabel>
                {label} ({stringFormat})
            </TimeItemLabel>
            <Input
                className={active ? 'active' : ''}
                value={_stringValue}
                onChange={handleChange}
                onBlur={handleOnBlur}
                error={!isValid}
                onSelect={onSelect}
            />
        </TimeItemDiv>
    );
};
