import { styled } from 'twin.macro';
import * as React from 'react';
import { Display } from '../display';

export const PrintPageHeader = styled.h1`
    display: block;
    font-weight: normal;
    font-size: 1.6em;
`;

export const PrintPageSubHeader = styled.h2`
    font-size: 1.6em;
    display: block;
    margin: 0 0 1rem;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
`;

export const PrintPageNoteText = styled(Display.Text)`
    min-height: 1.5cm;
    max-height: 15cm;
    overflow: hidden;
    font-size: 0.95em;
    display: block;
    & * {
        line-height: 1em;
        margin: 0 0 0.5em 0;
    }

    font-family: serif;
    word-break: break-word;
`;

export const PrintPageBreak = styled.div`
    break-after: auto;
`;

export const PrintPreferPageBreak = styled.div`
    break-after: auto;
`;

export const PrintPreferNoPageBreak = styled.div`
    break-inside: avoid-page;
`;

export const NoPrint = styled.div`
    @media print {
        display: none;
    }
`;
