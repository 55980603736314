import * as React from 'react';
import * as _ from 'lodash';
import { CSSProperties } from 'react';

const errorMsgStyle: CSSProperties = {
    fontSize: '0.8em',
    color: 'red',
    marginLeft: '5px',
    whiteSpace: 'pre',
};

const labelStyleI = {
    // paddingRight: '0.25em',
    paddingLeft: '0.25em',
    color: 'red',
    verticalAlign: 'sub',
    lineHeight: 0,
};

function createMarkup(htmlString: string) {
    return { __html: htmlString };
}

export const formatLabel = (label, required, errorLabel, htmlFor?: string) => {
    let requiredSymbol = null;
    let errorMessage = null;

    if (required) {
        requiredSymbol = <span style={labelStyleI}>*</span>;
    }

    if (errorLabel && _.isString(errorLabel)) {
        // TODO: Remove `dangerouslySetInnerHTML` (Search for TOFIX1 tag in the code.)
        errorMessage = <span style={errorMsgStyle} dangerouslySetInnerHTML={createMarkup(errorLabel)} />;
    }

    if (!errorMessage && !label) {
        return null;
    }

    return (
        <label htmlFor={htmlFor}>
            {label}
            {requiredSymbol}
            {errorMessage}
        </label>
    );
};

export interface FormFieldLabelProps {
    label?: string | React.ReactNode;
    required?: boolean;
    errorLabel?: string;
    htmlFor?: string;
    disabled?: boolean;
    readOnly?: boolean;
}
export const FormFieldLabel: React.FC<FormFieldLabelProps> = (props) => {
    return formatLabel(props.label, props.required, props.errorLabel, props.htmlFor);
};
