import styled from 'styled-components';

const StyledTable = styled.table`
    border-collapse: collapse;
    width: 100%;
`;

const StyledTD = styled.td<{ width?: string; valign?: 'top' | 'middle' }>`
    width: ${(props) => props.width};
    vertical-align: ${(props) => props.valign || 'middle'};
    :not(:last-child) {
        //border-right: 1px dashed #999999;
    }
    padding: 5px;
`;

const StyledTR = styled.tr`
    :not(:last-child) {
        border-bottom: 1px dashed #c0c0c0;
    }
`;

const StyledTBODY = styled.tbody``;

const StyledTHEAD = styled.thead`
    border-bottom: 1px solid #ddd;
    font-weight: bold;
`;

export const PrintTable = {
    TABLE: StyledTable,
    THEAD: StyledTHEAD,
    TBODY: StyledTBODY,
    TR: StyledTR,
    TD: StyledTD,
    Table: StyledTable,
    Header: StyledTHEAD,
    Body: StyledTBODY,
    HeaderCell: StyledTD,
    Row: StyledTR,
    Cell: StyledTD,
};
