import * as React from 'react';
import { Popup, PopupProps } from 'semantic-ui-react';
import styled from 'styled-components';

export const TruncatedDiv = styled.div<{ maxWidth?: number }>`
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: ${(props) => props.maxWidth || 300}px;
    overflow: hidden;
    padding: 3px 5px;
    margin: -3px -5px;
    &.popup {
        &:hover {
            background-color: #f0f0f0;
            border-radius: 2px;
        }
    }
`;

export const TruncatedDivWithPopup: React.FC<PopupProps & { maxWidth?: number }> = ({
    maxWidth = 200,
    children,
    content,
    ...props
}) => {
    const trigger = (
        <TruncatedDiv className={'popup'} style={{ cursor: 'default' }} maxWidth={maxWidth}>
            {children}
        </TruncatedDiv>
    );

    return (
        <Popup mouseEnterDelay={300} size={'small'} position={'top center'} trigger={trigger} {...props}>
            {content || children}
        </Popup>
    );
};
