import * as React from 'react';
import DatePicker from '@ez/react-datepicker';
import { Button, Form, SemanticSIZES } from 'semantic-ui-react';

export interface FormInputCompProps {
    readOnly?: boolean;
    disabled?: boolean;
    hasError?: boolean;
    label?;
    onChange?;
    onClick?;
    value?;
    isClearable?: boolean;
    size?: SemanticSIZES;
}

class FormInputComp extends React.Component<FormInputCompProps> {
    /// THIS MUST BE A CLASS, NOT React.FC. DatePicker requires it.
    handleRemove = () => {
        const { onChange } = this.props;
        onChange?.({ target: { value: '' } });
    };

    render() {
        const { hasError, label, onClick, value, isClearable = true, readOnly, disabled, size } = this.props;
        const isDisabled = Boolean(disabled || readOnly);
        return (
            <Form.Input
                size={(size as any) || 'small'}
                error={hasError}
                value={value}
                type={'text'}
                label={label}
                readOnly={true}
                action
            >
                <input
                    readOnly={true}
                    style={{ opacity: isDisabled ? 0.5 : 1, backgroundColor: '#f8f8f8', cursor: 'pointer' }}
                    onClick={onClick}
                />
                {value && isClearable && (
                    <Button
                        size={size}
                        disabled={isDisabled}
                        onClick={this.handleRemove}
                        type={'button'}
                        basic
                        icon={'delete'}
                    />
                )}
                <Button
                    style={{ border: '1px solid rgba(34,36,38,.15)' }}
                    type={'button'}
                    icon={'calendar outline'}
                    disabled={isDisabled}
                    size={size}
                    onClick={onClick}
                />
            </Form.Input>
        );
    }
}

export interface FormInputDateProps {
    onChange: (val: null | Date, e: any) => any;
    value: Date | string;
    label?: string | React.ReactNode;
    dateFormat?: string;
    monthsShown?: number;
    isClearable?: boolean;
    todayButton?: string;
    disabled?: boolean;
    size?: SemanticSIZES;
}

export const FormInputDate: React.FC<FormInputDateProps> = ({
    onChange,
    value,
    label,
    monthsShown = 1,
    dateFormat = 'd MMMM yyyy',
    isClearable = true,
    size,
    ...rest
}) => {
    const arrowPosition: any = 'center';

    const valueS = typeof value === 'string' ? new Date(value) : value;

    let popperClassName = ['pw-datepicker-popper'];
    if (arrowPosition === 'center') {
        popperClassName.push('pw-react-datepicker__arrow--align-center');
    }
    if (arrowPosition === 'left') {
        popperClassName.push('pw-react-datepicker__arrow--align-left');
    }

    return (
        <DatePicker
            // Weeks starts from Monday in this locale
            dateFormat={dateFormat || 'd MMMM yyyy'}
            showMonthDropdown={true}
            showYearDropdown={true}
            monthsShown={monthsShown}
            wrapperClassName={'field'}
            dropdownMode="select"
            popperClassName={popperClassName.join(' ')}
            calendarClassName={'pw-datepicker'}
            allowSameDay={true}
            selected={valueS}
            customInput={<FormInputComp size={size} label={label} isClearable={isClearable} />}
            onChange={onChange}
            {...rest}
        />
    );
};
