import * as React from 'react';
import { HeaderButtons, HeaderButtonType } from '../panel';
import tw, { styled } from 'twin.macro';

const SectionHeaderContainer: React.FC = styled.div`
    border: none;
    padding: 0 0 0.21428571rem;
    line-height: 1.28571429em;
    text-transform: none;
    color: #727475;
    border-bottom: 2px solid #727475;
    font-size: 1.07142857em;
    font-weight: 700;
    display: flex;
    flex-direction: row;
    margin: 1.5rem 0 1rem;

    &:first-child {
        margin-top: 0;
    }
`;

const LeftSpan = styled.span`
    flex-grow: 0;
    align-self: center;
`;

const RightSpan = styled.span`
    flex-grow: 2;
    text-align: right;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    &&& > * {
        margin: 0;
    }
`;

export interface FormSectionHeaderProps {
    button?: HeaderButtonType | HeaderButtonType[];
}

export const FormSectionHeader: React.FC<FormSectionHeaderProps> = ({ children, button, ...props }) => {
    return (
        <SectionHeaderContainer>
            <LeftSpan>{children}</LeftSpan>
            <RightSpan>
                <HeaderButtons buttons={button} autoHideButtonsContent={false} />
            </RightSpan>
        </SectionHeaderContainer>
    );
};

export const FormSectionBody = styled.div(() => [tw`py-1 mb-6`]);
