import * as React from 'react';
import { Icon, Message } from 'semantic-ui-react';
import { DebugJSON, DevOnly } from '../dev-tools';

type ApolloError = any;

export const DisplayApolloError: React.FC<{ error?: ApolloError }> = ({ error }) => {
    if (!error) return null;
    const networkError = error?.networkError;
    const graphQLErrors = error?.graphQLErrors;
    const networkErrorMsg = !!networkError ? networkError.message || 'Network Error' : undefined;

    return (
        <DevOnly>
            <div>{networkErrorMsg}</div>
            {graphQLErrors?.length > 0 && <div>Graphql Error</div>}
            <DebugJSON data={error} />
        </DevOnly>
    );
};

export const ConnectionErrorMessage: React.FC<{ error: ApolloError }> = ({ error }) => {
    return (
        <Message>
            <Message.Header>
                We're sorry but something went wrong <Icon name={'frown'} />
            </Message.Header>
            <Message.Content>
                <DisplayApolloError error={error} />
            </Message.Content>
        </Message>
    );
};
