import { EmptyBoxImage } from '../EmptyBoxImage';
import * as React from 'react';
import 'twin.macro';

export const NoDataMessage: React.FC<{}> = () => {
    return (
        <div tw={'flex justify-center'}>
            <EmptyBoxImage tw={'m-2 h-8'} />
        </div>
    );
};
