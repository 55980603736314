import * as React from 'react';
import { useField } from 'formik';
import { Form, SemanticSIZES } from 'semantic-ui-react';
import { FormikFormFieldLabel } from './FormikFormFieldLabel';
import { FormInputDate } from '../form-components';
import { DatePickerButton, DatePickerButtonProps } from '../date-pickers';

export interface FormikDatePickerInputFieldProps {
    name: string;
    label: string;
    required?: boolean;
    dateFormat?: string;
    monthsShown?: number;
    minDate?: Date;
    maxDate?: Date;
    isClearable?: boolean;
    shouldCloseOnSelect?: boolean;
    todayButton?: string;
    disabled?: boolean;
    readOnly?: boolean;
    size?: SemanticSIZES;
}

export const FormikDatePickerInputField: React.FC<FormikDatePickerInputFieldProps> = ({
    name,
    label,
    required,
    disabled,
    readOnly,
    ...rest
}) => {
    const [field, meta, helpers] = useField({ name: name });
    const handleChange = (date, e) => {
        helpers.setValue(date);
    };
    const labelComp = (
        <FormikFormFieldLabel
            disabled={disabled}
            readOnly={readOnly}
            htmlFor={name}
            label={label}
            name={name}
            required={required}
        />
    );
    const value = typeof field.value === 'string' ? new Date(field.value) : field.value;
    return (
        <FormInputDate
            disabled={disabled || readOnly}
            value={value}
            label={labelComp}
            onChange={handleChange}
            {...rest}
        />
    );
};

export enum DatePickerMode {
    DATE,
    TIME,
}

export interface FormikDatePickerButtonFieldProps extends FormikDatePickerInputFieldProps, DatePickerButtonProps {
    mode?: DatePickerMode;
}

export const FormikDatePickerButtonField: React.FC<FormikDatePickerButtonFieldProps> = ({
    label,
    monthsShown = 3,
    name,
    mode = DatePickerMode.DATE,
    ...props
}) => {
    const [field, meta, helpers] = useField({ name: name });
    const innerWidth = window?.innerWidth;

    let _monthsShown;
    if (innerWidth >= 1024) {
        _monthsShown = monthsShown || 3;
    } else if (innerWidth >= 768) {
        _monthsShown = 2;
    } else {
        _monthsShown = 1;
    }

    let bProps = {};
    switch (mode) {
        case DatePickerMode.DATE:
            bProps = {
                dateFormat: 'dd MMMM yyyy',
                icon: 'calendar alternate outline',
                arrowPosition: 'left',
                dateFormatCalendar: 'MMMM yyyy',
                todayButton: 'Today',
                shouldCloseOnSelect: true,
                showMonthDropdown: true,
                showYearDropdown: true,
                dropdownMode: 'select',
                ...props,
            };

            break;
        case DatePickerMode.TIME:
            bProps = {
                showTimeSelect: true,
                showTimeSelectOnly: true,
                timeFormat: 'HH:mm',
                timeIntervals: 15,
                dateFormat: 'HH:mm',
                icon: 'time',
                timeCaption: 'Time',
                popperPlacement: 'bottom',
                ...props,
            };
            break;
    }

    const handleChange = (date, e) => {
        helpers.setValue(date);
    };

    // Date picker expects Date object
    const value = typeof field.value === 'string' ? new Date(field.value) : field.value;

    return (
        <Form.Field>
            <FormikFormFieldLabel disabled={props.disabled} readOnly={props.readOnly} label={label} name={name} />
            <DatePickerButton
                fluid={true}
                monthsShown={_monthsShown}
                selected={value}
                onChange={handleChange}
                dropdownMode="select"
                dateFormat={'dd MMMM yyyy'}
                timeFormat={'HH:mm'}
                {...bProps}
            />
        </Form.Field>
    );
};
