import * as React from 'react';
import tw, { css, styled } from 'twin.macro';
import { MenuBar, MenuBarProps } from '../menu';

export const ScrollableLayoutBodyScroll: React.FC = ({ children }) => {
    return (
        <div tw={'relative h-full flex-1'}>
            <div tw={'absolute inset-0 overflow-y-auto'}>{children}</div>
        </div>
    );
};

const ScrollableLayoutMenuBarStyled = styled(MenuBar)`
    &&& {
        ${tw`border-0 rounded-t m-0 rounded-b-none`}
    }
`;

export const ScrollableLayoutMenuBar: React.FC<MenuBarProps> = (props) => {
    return (
        <div tw={'bg-white z-10 shadow rounded'}>
            <ScrollableLayoutMenuBarStyled {...props} />
        </div>
    );
};

const ScrollableLayoutContainer = styled.div<{ maxHeight?: number; block?: boolean }>(({ maxHeight, block }) => [
    css`
        position: relative;
        height: ${maxHeight ? `${maxHeight}px` : '100%'};
        overflow-y: hidden;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    `,
    block && tw`shadow rounded bg-gray-100 border-0`,
]);

export interface ScrollableLayoutProps {
    maxHeight?: number;
    className?: string;
    style?;
    block?: boolean;
}

export class ScrollableLayout extends React.Component<ScrollableLayoutProps, any> {
    public static BodyScroll = ScrollableLayoutBodyScroll;
    public static MenuBar = ScrollableLayoutMenuBar;

    render() {
        const { style, className, maxHeight, children, block = true } = this.props;
        return (
            <ScrollableLayoutContainer maxHeight={maxHeight} className={className} style={style} block={block}>
                <div tw={'h-full absolute inset-0 flex flex-col'}>{children}</div>
            </ScrollableLayoutContainer>
        );
    }
}
