import { Breakpoint, isTouch, useBreakpoint } from '@ez/tools';
import { useMemo } from 'react';
import * as _ from 'lodash';

export enum AppLayoutModeType {
    Mobile = 'mobile',
    Tablet = 'tablet',
    DesktopSM = 'desktopSM',
    DesktopMD = 'desktopMD',
    DesktopLG = 'desktopLG',
    DesktopXL = 'desktopXL',
    DesktopXXL = 'desktopXXL',
}

export const DEFAULT_APP_BREAKPOINTS = {
    mobile: 0,
    tablet: 769,
    desktopSM: 1025,
    desktopMD: 1281,
    desktopLG: 1461,
    desktopXL: 1801,
    desktopXXL: 1921,
};

export interface AppBreakpoints {
    mode: AppLayoutModeType;
    bpValues: typeof DEFAULT_APP_BREAKPOINTS;
    isTouch: boolean;
    modeMinWidth: any;
    modeMaxWidth: any;
    isMobile: boolean;
    gteMobile: boolean;
    isTablet: boolean;
    gteTablet: boolean;
    isDesktopSM: boolean;
    gteDesktopSM: boolean;
    isDesktopMD: boolean;
    gteDesktopMD: boolean;
    isDesktopLG: boolean;
    gteDesktopLG: boolean;
    isDesktopXL: boolean;
    gteDesktopXL: boolean;
    isDesktopXXL: boolean;
    gteDesktopXXL: boolean;
    gteDesktopAny: boolean;
    isDesktopAny: boolean;
}

export const defaultAppBreakpointsValue: AppBreakpoints = {
    mode: AppLayoutModeType.DesktopMD,
    bpValues: DEFAULT_APP_BREAKPOINTS,
    isTouch: false,
    modeMinWidth: 0,
    modeMaxWidth: 10000,
    isMobile: false,
    gteMobile: true,
    isTablet: false,
    gteTablet: true,
    isDesktopAny: true,
    gteDesktopAny: true,
    isDesktopSM: true,
    gteDesktopSM: true,
    isDesktopMD: false,
    gteDesktopMD: false,
    isDesktopLG: false,
    gteDesktopLG: false,
    isDesktopXL: false,
    gteDesktopXL: false,
    isDesktopXXL: false,
    gteDesktopXXL: false,
};

type AppLayoutModeConfType = typeof DEFAULT_APP_BREAKPOINTS;

export const prepareLayoutConf = (bp: Breakpoint<AppLayoutModeConfType>, conf: AppLayoutModeConfType) => {
    const bpValues = conf;
    const sortedKeys = Object.keys(bpValues).sort((a, b) => (bpValues[a] > bpValues[b] ? 1 : -1));

    let res: Partial<AppBreakpoints> = {
        bpValues: bpValues,
        mode: bp.breakpoint as any,
        modeMinWidth: bp.minWidth,
        modeMaxWidth: bp.maxWidth,
        isTouch: isTouch,
    };

    sortedKeys.forEach((key) => {
        const bpValue = DEFAULT_APP_BREAKPOINTS[key];
        const isKeyName = 'is' + _.upperFirst(key);
        const gteKeyName = 'gte' + _.upperFirst(key);

        res[isKeyName] = bp.breakpoint === key;
        res[gteKeyName] = bp.minWidth >= bpValue;
    });

    res.gteDesktopAny = res.gteDesktopSM;
    res.isDesktopAny = res.gteDesktopSM;

    return res as AppBreakpoints;
};

export const useAppBreakpoints = (conf: AppLayoutModeConfType = DEFAULT_APP_BREAKPOINTS): AppBreakpoints => {
    const bp = useBreakpoint(conf, AppLayoutModeType.DesktopSM, false);

    return useMemo(() => {
        return prepareLayoutConf(bp, conf);
    }, [bp, conf]);
};
