import styled from 'styled-components';

export const Container = styled.div`
    display: block;
    max-width: 100% !important;
    width: 100%;
    @media only screen and (min-width: 1200px) {
        width: 1127px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
`;
