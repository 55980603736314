import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { SidebarSecondary } from './AppSidebarSecondaryComponents';
import { AppSidebarConfig, AppSidebarMenuItem } from './AppSidebar';
import { LinkButton } from '../buttons/LinkButton';

const ExternalLinkComp: React.FC<{ to; className }> = ({ to, children, className }) => (
    <a href={to} className={className} children={children} />
);

export const SidebarSecondaryMenuItemExternalLink = SidebarSecondary.createMenuItem(ExternalLinkComp);
export const SidebarSecondaryMenuItemButton = SidebarSecondary.createMenuItem(LinkButton);

const FullWidthItemContent: React.FC<AppSidebarMenuItem> = ({
    to,
    exact,
    as,
    onClick,
    icon,
    label,
    sub,
    secondaryIcon,
}) => {
    const SidebarMenuItem = as; // Sidebar.createMenuItem(as);
    return (
        <SidebarMenuItem
            key={to}
            activeClassName="active"
            to={to}
            exact={exact}
            onClick={onClick}
            className={sub ? 'sub' : undefined}
        >
            {icon && <Icon name={icon} className={'main-icon'} />}
            {label}
            {secondaryIcon && <Icon className={'secondary-icon'} name={secondaryIcon} />}
        </SidebarMenuItem>
    );
};

export interface AppSidebarSecondaryProps {
    config: AppSidebarConfig;
    onClick?: (item: AppSidebarMenuItem) => any;
}
export const AppSidebarSecondary: React.FC<AppSidebarSecondaryProps> = ({ config, onClick }) => {
    const menus = config?.menu?.filter(Boolean) || [];
    return (
        <SidebarSecondary.Aside id={'sidebar-secondary'}>
            <SidebarSecondary.MenuContainer>
                <SidebarSecondary.Menu>
                    {menus.map((group, index) => {
                        if (!group) return null;
                        const items = group.items?.filter(Boolean) || [];
                        if (items.length === 0) return null;
                        return (
                            <SidebarSecondary.MenuGroup key={index}>
                                {group.header && (
                                    <SidebarSecondary.MenuHeader>{group.header}</SidebarSecondary.MenuHeader>
                                )}
                                {items.map((item, index) => {
                                    if (!item) {
                                        return null;
                                    }
                                    return (
                                        <React.Fragment key={index}>
                                            <FullWidthItemContent onClick={() => onClick?.(item)} {...item} />
                                        </React.Fragment>
                                    );
                                })}
                            </SidebarSecondary.MenuGroup>
                        );
                    })}
                </SidebarSecondary.Menu>
                <SidebarSecondary.MenuSpacer />
            </SidebarSecondary.MenuContainer>
        </SidebarSecondary.Aside>
    );
};
