import * as React from 'react';
import styled from 'styled-components';
import { forwardRef } from 'react';

const ScrollXContainer = styled.div.attrs({
    // @ts-ignore
    'data-testid': 'ScrollX.ScrollContainer',
})<{ padBottom?: number }>`
    padding-bottom: ${({ padBottom = 1 }) => `${padBottom}em`};
    overflow-x: auto;
    //overflow-y: hidden;
`;

const scrollbarWidth = '.5em';
const scrollbarFgColor = '#7f91a1';
const scrollbarBgColor = 'rgba(0,0,0,0.1)';

export const ScrollX: React.FC<{ padBottom?: number } & React.HTMLAttributes<HTMLDivElement>> = ({
    padBottom = 0,
    children,
    ...rest
}) => {
    return (
        <ScrollXContainer {...rest} padBottom={0}>
            <div style={{ position: 'relative', margin: '0', padding: 0 }}>{children}</div>
        </ScrollXContainer>
    );
};

const ScrollYContainer = styled.div.attrs({
    // @ts-ignore
    'data-testid': 'ScrollY.ScrollContainer',
})<{ maxHeight?: number; minHeight?: number }>`
    overflow-y: auto;
    max-height: ${({ maxHeight }) => (maxHeight !== undefined ? `${maxHeight}px` : undefined)};
    min-height: ${({ minHeight }) => (minHeight !== undefined ? `${minHeight}px` : undefined)};
    //overflow-y: hidden;

    &::-webkit-scrollbar {
        width: ${scrollbarWidth};
        height: ${scrollbarWidth};
    }

    &::-webkit-scrollbar-thumb {
        background: ${scrollbarFgColor};
    }

    &::-webkit-scrollbar-track {
        background: ${scrollbarBgColor};
    }

    // For Internet Explorer
    body {
        scrollbar-face-color: ${scrollbarFgColor};
        scrollbar-track-color: ${scrollbarBgColor};
    }
`;

export interface ScrollYProps extends React.HTMLAttributes<HTMLDivElement> {
    maxHeight?: number;
    minHeight?: number;
}

export const ScrollY = forwardRef<any, ScrollYProps>((props, ref) => {
    const { children, ...rest } = props;
    return (
        <ScrollYContainer {...rest} ref={ref}>
            <div style={{ position: 'relative', margin: '1px', padding: 0 }}>{children}</div>
        </ScrollYContainer>
    );
});
