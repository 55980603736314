import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Checkbox, CheckboxProps, Icon } from 'semantic-ui-react';

export const AsyncCheckbox: React.FC<CheckboxProps & { loading?: boolean; onToggle: (checked: boolean) => any }> = ({
    onToggle,
    loading,
    ...rest
}) => {
    const [isChanging, setChanging] = useState(false);
    const ref = useRef<'ref'>();
    useEffect(() => {
        ref.current = 'ref';
        return () => {
            ref.current = null;
        };
    });

    const handleChange = async (event, data) => {
        event.preventDefault();
        event.stopPropagation();
        setChanging(true);
        await onToggle?.(data.checked);
        if (ref.current) {
            setChanging(false);
        }
    };

    if (isChanging || loading) {
        return <Icon loading={true} name={'spinner'} />;
    }

    return <Checkbox {...rest} onChange={handleChange} />;
};
