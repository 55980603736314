import * as React from 'react';
import DatePicker from '@ez/react-datepicker';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Button, Dropdown, Segment } from 'semantic-ui-react';
import { useMachine } from '@xstate/react';
import { rangePickerMachine } from './rangePickerMachine';
import { TimeItemInput } from './TimeInputField';
import moment from 'moment';

const DateRangePickerContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
`;

const DateRangePickerLeft = styled.div`
    flex: 0 0 440px;
`;

const DateRangePickerRight = styled.div`
    flex: 1 0 160px;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.25em 0;
`;

const StyledLabel = styled.label`
    font-weight: bold;
    font-size: 1.1em;
    margin-bottom: 0.5em;
    display: inline-block;
`;

const getDateRangeNWeeksBeforeToday = (numOfWeeks): DateRange => {
    const endDate = moment().endOf('day');
    const startDate = moment(endDate).subtract(numOfWeeks, 'weeks').add(1, 'day').startOf('day');

    return {
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
    };
};

export type DateRangeOptionItem = {
    key: string;
    text: string;
    value: string;
    dateRange?: DateRange;
};

const default_DateRangeSelectOptions: DateRangeOptionItem[] = [
    {
        key: 'custom',
        text: 'Custom',
        value: 'custom',
    },
    {
        key: '1wks',
        text: 'Last 1 week',
        value: '1wks',
        dateRange: getDateRangeNWeeksBeforeToday(1),
    },
    {
        key: '2wks',
        text: 'Last 2 weeks',
        value: '2wks',
        dateRange: getDateRangeNWeeksBeforeToday(2),
    },
    {
        key: '3wks',
        text: 'Last 3 weeks',
        value: '3wks',
        dateRange: getDateRangeNWeeksBeforeToday(3),
    },
    {
        key: '4wks',
        text: 'Last 4 weeks',
        value: '4wks',
        dateRange: getDateRangeNWeeksBeforeToday(4),
    },
    {
        key: '8wks',
        text: 'Last 8 weeks',
        value: '8wks',
        dateRange: getDateRangeNWeeksBeforeToday(8),
    },
];

export type DateRange = {
    startDate: Date;
    endDate: Date;
};

export interface DateRangePickerProps {
    initialDateRange: DateRange;
    onChange?: (newDateRange: DateRange) => any;
    maxDate?: Date;
    minDate?: Date;
    onCancel?: () => any;
    dateRangeSelectOptions?: DateRangeOptionItem[];
    maxRangeInWks?: number;
}
export const DateRangePicker: React.FC<DateRangePickerProps> = (props) => {
    const [currentSM, send] = useMachine(rangePickerMachine);

    const { onChange, onCancel, dateRangeSelectOptions = default_DateRangeSelectOptions } = props;

    const { startDate, endDate, maxDate, minDate, maxRangeInWks } = currentSM.context;

    const [rangeMode, setRangeMode] = useState('custom');

    const resetToDefaults = () => {
        const { initialDateRange, maxDate, minDate, maxRangeInWks } = props;
        send('INIT_DEFAULTS', {
            startDate: initialDateRange && initialDateRange.startDate ? initialDateRange.startDate : null,
            endDate: initialDateRange && initialDateRange.endDate ? initialDateRange.endDate : null,
            minDate: minDate,
            maxDate: maxDate,
            maxRangeInWks: maxRangeInWks,
        });
    };
    useEffect(() => {
        resetToDefaults();
        // eslint-disable-next-line
    }, []);

    const handleDateChange = (v) => {
        send('SELECT_DATE_CELL', { date: v });
    };

    const handleOnSelect = (type: 'start' | 'end') => () => {
        if (type === 'start') {
            send('CLICK_START_INPUT');
        } else {
            send('CLICK_END_INPUT');
        }
    };

    const handleDateInputChange = (type: 'start' | 'end') => (v) => {
        switch (type) {
            case 'start':
                send('SET_START_DATE', { date: v });
                break;
            case 'end':
                send('SET_END_DATE', { date: v });
                break;
        }
    };

    const handleSelectChange = (e, { value }) => {
        setRangeMode(value);
        const option = dateRangeSelectOptions.find((o) => o.value === value);
        if (option && option.dateRange) {
            send('SET_DATE_RANGE', { ...option.dateRange });
        }
    };

    const handleApply = () => {
        onChange &&
            onChange({
                startDate: startDate,
                endDate: endDate,
            });
    };

    const handleCancel = () => {
        onCancel && onCancel();
    };

    const dropdownOptions = dateRangeSelectOptions.map((o) => ({
        key: o.key,
        text: o.text,
        value: o.value,
    }));

    const canApply = Boolean(startDate && endDate);

    return (
        <DateRangePickerContainer>
            <DateRangePickerLeft>
                <DatePicker
                    calendarClassName={'pw-datepicker'}
                    inline
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={maxDate}
                    minDate={minDate}
                    onChange={handleDateChange}
                    monthsShown={2}
                    allowSameDay={true}
                />
                <br />
                {/*<pre>{JSON.stringify({ context: currentSM.context, value: currentSM.value }, null, 2)}</pre>*/}
            </DateRangePickerLeft>
            <DateRangePickerRight>
                <div>
                    <StyledLabel>Date Range</StyledLabel>
                    <Dropdown
                        fluid
                        selection
                        value={rangeMode}
                        onChange={handleSelectChange}
                        options={dropdownOptions}
                    />
                </div>
                <div>
                    <hr />
                    <TimeItemInput
                        maxDate={maxDate}
                        minDate={minDate}
                        active={currentSM.matches('selectStart')}
                        onSelect={handleOnSelect('start')}
                        label={'Start'}
                        value={currentSM.context.startDate}
                        onChange={handleDateInputChange('start')}
                    />
                    <TimeItemInput
                        maxDate={maxDate}
                        minDate={minDate}
                        active={currentSM.matches('selectEnd')}
                        onSelect={handleOnSelect('end')}
                        label={'End'}
                        value={currentSM.context.endDate}
                        onChange={handleDateInputChange('end')}
                    />
                </div>
                <Segment basic clearing size={'small'}>
                    <Button
                        disabled={!canApply}
                        floated={'left'}
                        size={'mini'}
                        color={'green'}
                        content={'Apply'}
                        onClick={handleApply}
                    />
                    <Button floated={'right'} size={'mini'} basic secondary content={'Cancel'} onClick={handleCancel} />
                </Segment>
                {maxRangeInWks > 0 && (
                    <span style={{ color: '#7a7a7a' }}>
                        Maximum range is <b>{maxRangeInWks}</b> weeks.
                    </span>
                )}
            </DateRangePickerRight>
        </DateRangePickerContainer>
    );
};
