import * as React from 'react';
import { Form, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';
import { Formik, FormikHelpers, FormikConfig, FormikValues } from 'formik';
import { toastError } from '../toast';
import { Panel } from '../panel';
import { isFunction } from './utils';
import { FormikFormDebug } from './FormikFormDebug';

export interface FormikPanelFormProps<Values extends FormikValues = FormikValues> extends FormikConfig<Values> {
    header?: string | React.ReactNode;
    icon?: SemanticICONS | React.ReactNode;
    color?: SemanticCOLORS;
    debug?: boolean;
    alwaysShowButtons?: boolean;
    onSubmit: (values: Values, formikHelpers: FormikHelpers<Values>) => void | Promise<Partial<Values> | void>;
    onCancel?: () => any;
    basic?: boolean;
}
export const FormikPanelForm: React.FC<FormikPanelFormProps> = (props) => {
    const { children, enableReinitialize = true, debug, alwaysShowButtons, onCancel, basic } = props;
    const onSubmit = async (values: any, formikHelpers: FormikHelpers<any>) => {
        formikHelpers.setSubmitting(true);
        try {
            const updateConf = await props.onSubmit(values, formikHelpers);
            if (updateConf) {
                formikHelpers.resetForm({ values: { ...values, ...updateConf } });
            }
        } catch (e) {
            console.error(e);
            formikHelpers.setStatus({ error: e });
            toastError({ title: 'Failed', description: e.message });
        }
        formikHelpers.setSubmitting(false);
    };

    return (
        <Formik enableReinitialize={enableReinitialize} initialValues={props.initialValues} onSubmit={onSubmit}>
            {(formikBag) => {
                const { handleSubmit, isSubmitting, resetForm, dirty } = formikBag;

                const handleOnCancel = () => {
                    resetForm();
                    onCancel && onCancel();
                };

                let buttons = [];
                if (dirty || alwaysShowButtons) {
                    buttons = [
                        {
                            content: 'Cancel',
                            disabled: isSubmitting,
                            onClick: handleOnCancel,
                        },
                        {
                            type: 'submit',
                            content: 'Save',
                            loading: isSubmitting,
                            disabled: isSubmitting,
                            onClick: handleSubmit,
                            // color: 'black',
                            primary: true,
                            basic: false,
                        },
                    ];
                }
                return (
                    <Form>
                        <Panel>
                            <Panel.Header
                                basic={basic}
                                content={props.header}
                                icon={props.icon}
                                color={props.color}
                                button={buttons}
                            />
                            <Panel.Body style={{ backgroundColor: 'hsl(210, 12%, 99%)' }}>
                                {isFunction(children) ? children(formikBag) : children}
                            </Panel.Body>
                        </Panel>
                        <FormikFormDebug hidden={!debug} />
                    </Form>
                );
            }}
        </Formik>
    );
};
