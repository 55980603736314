import * as React from 'react';
import * as _ from 'lodash';
import { Field, getIn, useField } from 'formik';
import { Form, FormCheckboxProps, FormSelectProps, FormTextAreaProps, Label } from 'semantic-ui-react';
import { FormikFormFieldLabel } from './FormikFormFieldLabel';
import { HTMLCheckbox, HTMLSelect } from './test-components';
import { cn, useDebouncedCallback } from '@ez/tools';
import { Optionable } from '../optionable';
import { useEffect, useState } from 'react';

export interface FormikFieldProps {
    name: string;
    label?: string | React.ReactNode;
    required?: boolean;
    readOnly?: boolean;
    disabled?: boolean;
    dataTestId?: string;
}

export type FormikFieldOptionType = {
    key: string | number;
    value: any;
    text: string;
};

export const FormikTextareaField: React.FC<FormTextAreaProps & FormikFieldProps> = ({
    label,
    name,
    required,
    dataTestId,
    ...rest
}) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const handleChange = (e, { value }) => form.setFieldValue(field.name, value);
                const value = _.get(field, 'value') || '';
                const touched = getIn(form.touched, name);
                const error = getIn(form.errors, name);
                let hasError = touched && !!error;
                const labelComp = <FormikFormFieldLabel label={label} name={name} required={required} />;
                return (
                    <Form.TextArea
                        id={name}
                        onBlur={field.onBlur}
                        error={hasError}
                        rows={5}
                        data-testid={dataTestId}
                        name={name}
                        {...rest}
                        label={labelComp}
                        value={value}
                        onChange={handleChange}
                    />
                );
            }}
        </Field>
    );
};

export interface FormikInputFieldProps extends Omit<FormTextAreaProps, 'label'>, FormikFieldProps {
    datalist?: FormikFieldOptionType[];
    options?: FormikFieldOptionType[]; // Same as datalist
    subLabel?: React.ReactNode | string;
    subLabelStyle?: any;
    debounceMs?: number;
    actionButton?: React.ReactNode;
    onDidChange?: (newValue) => any;
}

export const FormikInputField = React.forwardRef<any, FormikInputFieldProps>(
    (
        {
            dataTestId,
            datalist,
            options,
            label,
            name,
            required,
            className,
            subLabel,
            debounceMs = 0,
            subLabelStyle,
            actionButton,
            onDidChange,
            ...rest
        },
        ref
    ) => {
        const opts = datalist || options;
        const datalistname = opts?.length ? `datalist-${name}` : undefined;
        const cl = cn(className, { readonly: rest.readOnly, disabled: rest.disabled });

        const [field, meta, helpers] = useField({ name: name });

        let value = field?.value;
        if (value === null || value === undefined) {
            value = '';
        }
        const [presVal, setPresValue] = useState(value);

        useEffect(() => setPresValue(value), [value]);

        const setValue = (newValue) => {
            helpers.setValue(newValue);
            onDidChange?.(newValue);
        };

        const handleChange = (e, { value }) => {
            setPresValue(value);
            if (debounceMs > 1) {
                debouncedHandleChange(value);
            } else {
                setValue(value);
            }
        };
        const handleOnBlur = (e) => field.onBlur(e);

        const [debouncedHandleChange] = useDebouncedCallback((value) => {
            setValue(value);
        }, debounceMs);

        const touched = meta.touched;
        const error = meta.error;
        let hasError = touched && !!error;
        const subLabelProps = !subLabel
            ? undefined
            : {
                  labelPosition: 'right' as const,
              };

        const labelComp = <FormikFormFieldLabel htmlFor={name} label={label} name={name} required={required} />;

        return (
            <>
                <Form.Input
                    {...rest}
                    ref={ref}
                    className={cl}
                    list={datalistname}
                    onBlur={handleOnBlur}
                    error={hasError}
                    id={name}
                    name={name}
                    label={labelComp}
                    value={presVal}
                    onChange={handleChange}
                    {...subLabelProps}
                >
                    <input data-testid={dataTestId} />
                    {subLabel && <Label style={subLabelStyle}>{subLabel}</Label>}
                    {actionButton}
                </Form.Input>
                {opts?.length > 0 && (
                    <datalist id={datalistname}>
                        {opts.map((d, index) => (
                            <option key={d.key || index} value={d.value} />
                        ))}
                    </datalist>
                )}
            </>
        );
    }
);

export interface FormikSelectFieldProps extends FormikFieldProps, Omit<FormSelectProps, 'required'> {
    onDidChange?: ({ newValue, prevValue }) => any;
    options?: Optionable<any>[];
}

export const FormikSelectField: React.FC<FormikSelectFieldProps> = ({
    label,
    name,
    required,
    onDidChange,
    options,
    as,
    dataTestId,
    ...rest
}) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const value = field?.value || '';
                const touched = getIn(form.touched, name);
                const error = getIn(form.errors, name);
                let hasError = touched && !!error;
                const labelComp = <FormikFormFieldLabel label={label} name={name} required={required} />;

                const component = isUnitTest ? HTMLSelect : as;

                const handleChange = (e, { value: newValue }) => {
                    form.setFieldValue(field.name, newValue);
                    onDidChange?.({ newValue, prevValue: value });
                };
                return (
                    <Form.Select
                        {...rest}
                        options={options}
                        as={component}
                        error={hasError}
                        name={name}
                        label={labelComp}
                        value={value}
                        onChange={handleChange}
                        data-testid={dataTestId}
                    />
                );
            }}
        </Field>
    );
};

export const FormikHTMLSelectField: React.FC<FormikSelectFieldProps> = ({
    label,
    name,
    required,
    onDidChange,
    options,
    as,
    ...rest
}) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const value = getIn(field.value, '') || '';
                const touched = getIn(form.touched, name);
                const error = getIn(form.errors, name);
                let hasError = touched && !!error;
                const labelComp = <FormikFormFieldLabel label={label} name={name} required={required} />;

                const handleChange = (e) => {
                    const newValue = e?.target?.value;
                    form.setFieldValue(field.name, newValue);
                    onDidChange?.({ newValue, prevValue: value });
                };
                return (
                    <Form.Field
                        {...rest}
                        error={hasError}
                        value={value}
                        label={labelComp}
                        control="select"
                        onChange={handleChange}
                    >
                        {options.map((o, index) => {
                            return (
                                <option key={index} value={o.value}>
                                    {o.text}
                                </option>
                            );
                        })}
                    </Form.Field>
                );
            }}
        </Field>
    );
};

export interface FormikRadioField extends FormikFieldProps {
    options: Optionable<any>[];
    inline?: boolean;
}

export const FormikRadioField: React.FC<FormikRadioField> = ({
    label,
    name,
    required,
    options,
    inline,
    readOnly,
    disabled,
}) => {
    const GroupComp = inline ? Form.Group : React.Fragment;
    const groupCompProps = inline ? { inline: true } : undefined;
    return (
        <Field name={name}>
            {({ field, form }) => {
                const handleChange = (e, { value }) => {
                    const opt = options.find((o) => o.value === value);
                    form.setFieldValue(field.name, opt.value);
                };
                const value = _.get(field, 'value') || '';

                return (
                    <Form.Field>
                        <FormikFormFieldLabel label={label} name={name} required={required} />
                        <GroupComp {...groupCompProps}>
                            {options.map((o, index) => {
                                return (
                                    <Form.Checkbox
                                        key={index}
                                        radio
                                        name={name}
                                        label={o.text}
                                        value={o.value}
                                        readOnly={readOnly}
                                        checked={value == o.value}
                                        onChange={handleChange}
                                        disabled={disabled}
                                    />
                                );
                            })}
                        </GroupComp>
                    </Form.Field>
                );
            }}
        </Field>
    );
};

const isUnitTest = process.env.NODE_ENV === 'test';

export const FormikCheckboxField: React.FC<FormCheckboxProps & FormikFieldProps> = ({ label, name, ...rest }) => {
    return (
        <Field name={name}>
            {({ field, form }) => {
                const handleChange = (e, { checked }) => form.setFieldValue(field.name, checked);
                let value = Boolean(_.get(field, 'value') || false);
                const touched = getIn(form.touched, name);
                const error = getIn(form.errors, name);
                let hasError = touched && !!error;
                const labelComp = <FormikFormFieldLabel htmlFor={name} label={label} name={name} />;

                const component = isUnitTest ? HTMLCheckbox : undefined;

                return (
                    <Form.Checkbox
                        {...rest}
                        as={component}
                        error={hasError}
                        id={name}
                        name={name}
                        label={labelComp}
                        checked={value}
                        onChange={handleChange}
                    />
                );
            }}
        </Field>
    );
};

export const FormikFileInput: React.FC<{ name: string; label: string }> = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);

    return (
        <div className="form-group">
            <label htmlFor="file">File upload</label>
            <input
                id="file"
                name="file"
                type="file"
                multiple={false}
                // value={field.value?.name}
                onChange={(event) => {
                    helpers.setValue(event.currentTarget.files[0]);
                }}
                className="form-control"
            />
        </div>
    );
};
