import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDebouncedCallback, useIsMounted, useSafeState } from '@ez/tools';
import Select from 'react-select';
import * as _ from 'lodash';
import { FormLabel } from '../form-components';
import { ThemeInterface, useTheme } from '../app-theme';

export interface SuggestInputProps<T extends { id: string } = any> {
    label?: string | React.ReactNode;
    loading;
    activeItem?: T | null;
    value?: T | null; // value is an alias for `activeItem`
    items: T[];
    itemTextLabel: (item: T) => string;
    onItemSelect: (item: T) => void;
    placeholder?: string;
    initialValue?: string;
    onQueryChange?: (query: string) => any;
    getOptionValue?: (item: T) => any;
    isClearable?: boolean;
    isDisabled?: boolean;
    formatOptionLabel?: (o, ctx) => string | React.ReactNode;
    error?: boolean;
}

const defaultGetOptionValue = (option) => option && option['id'];

export const SuggestInput: React.FC<SuggestInputProps> = ({
    label,
    loading,
    items,
    activeItem,
    value,
    onItemSelect,
    onQueryChange,
    itemTextLabel,
    getOptionValue = defaultGetOptionValue,
    isClearable = true,
    isDisabled = false,
    placeholder,
    error = false,
    formatOptionLabel,
}) => {
    const isMounted = useIsMounted();
    const [options, setOptions] = useSafeState([]);
    const { ThemeState } = useTheme();

    const itemToOption = (i: any) => {
        const value = getOptionValue(i);
        if (!value) return null;
        return { label: itemTextLabel(i), value: value, key: value, item: i };
    };

    const prepareOptions = (items: { id: string }[]) => {
        let opts = items.map(itemToOption);
        if (!_.isEmpty(activeItem) && opts.findIndex((o) => o.value === getOptionValue(activeItem)) === -1) {
            opts = [itemToOption(activeItem), ...opts];
        }
        return opts.filter((o) => !!o);
    };

    useEffect(() => {
        setOptions(prepareOptions(items));
    }, [items]);

    const [debouncedOnSearchChange] = useDebouncedCallback((value) => {
        if (!isMounted()) return;
        onQueryChange?.(value);
    }, 500);

    const handleOnSearchChange = (value) => {
        // console.log('handleOnSearchChange', value);
        // setSearchQuery(value);
        debouncedOnSearchChange(value);
    };

    const handleOnChange = (o) => {
        onItemSelect(o ? o.item : null);
    };

    const foundValue = useMemo(() => {
        const activeValue = activeItem || value;
        const foundValue = options.filter((i) => i?.value === activeValue?.id)?.[0];
        if (!foundValue) {
            // if value is not found in the list of option, then present the activeValue.
            return itemToOption(activeValue);
        }
        return foundValue;
    }, [activeItem, value, options]);

    const styles = useMemo(() => getSuggestInputStyles(error, ThemeState.theme), [error, ThemeState.theme]);

    return (
        <div style={{ minWidth: '180px' }} className={'field'}>
            {label && <FormLabel>{label}</FormLabel>}
            <Select
                aria-label={label || placeholder}
                formatOptionLabel={formatOptionLabel}
                placeholder={placeholder}
                value={foundValue}
                cacheOptions
                isClearable={isClearable}
                isDisabled={isDisabled}
                options={options}
                isLoading={loading}
                onChange={handleOnChange}
                onInputChange={handleOnSearchChange}
                styles={styles}
                // menuIsOpen={true}
            />
        </div>
    );
};

export const getSuggestInputStyles = (error?: boolean, theme?: ThemeInterface) => ({
    control: (provided) => ({
        ...provided,
        minHeight: '2.5em',
        fontSize: '1em',
        // error colors are taken from semantic ui input fields
        borderRadius: 'var(--ez-border-radius-default, 4px)',
        backgroundColor: error ? '#fff6f6' : provided.backgroundColor,
        borderColor: error ? '#e0b4b4' : provided.borderColor,
    }),
    input: (provided) => ({
        ...provided,
        fontSize: '1em',
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: 'var(--ez-border-radius-default, 4px)',
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '1em',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        padding: '0.25em',
        maxHeight: '2.5em',
    }),
});
