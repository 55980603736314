import * as React from 'react';
import tw, { styled, css } from 'twin.macro';

export const PageWrapperDiv = tw.div`w-full flex flex-col m-0 flex-grow p-2 sm:p-2 xl:p-3 min-h-full`;

const PageContentDiv = styled.div<{ maxWidth: number }>(({ maxWidth }) => [
    tw`my-0 mx-auto flex-grow w-full p-0`,
    css`
        max-width: ${maxWidth};
        @media screen and (max-width: ${maxWidth}) {
            width: 100%;
        }
    `,
]);

export type PageLayoutContentWidth = 'wide' | 'normal' | 'narrow' | 'full';

export const PageContent: React.FC<{ className?: string; pageWidth?: PageLayoutContentWidth }> = ({
    pageWidth = 'full',
    className,
    children,
}) => {
    let maxWidth;
    switch (pageWidth) {
        case 'full':
            maxWidth = '100%';
            break;
        case 'wide':
            maxWidth = '140rem';
            break;
        default:
        case 'normal':
            maxWidth = '90rem';
            break;
        case 'narrow':
            maxWidth = '60rem';
            break;
    }

    return (
        <PageContentDiv data-testid={'PageContentDiv'} className={className} maxWidth={maxWidth}>
            {children}
        </PageContentDiv>
    );
};
