import { createGlobalStyle } from 'styled-components';
import tw from 'twin.macro';
import { ThemeInterface } from '../app-theme';
import { getThemeCssVars } from '../app-theme/theme-css-vars';

const rootTheme = getThemeCssVars();

export const AppGlobalStyle = createGlobalStyle<{ theme: ThemeInterface }>`
    ${rootTheme}
    body {
        ${tw`overflow-auto print:bg-white text-primary print:text-black text-base`}
        font-size: var(--ez-font-size-base, 14px);
        background-color: var(--ez-color-background, #f1f1f1);
        display: flex;
        flex-direction: column;
        margin: 0;
        width: 100%;
        height: 100%;

        & > #root {
            height: 100%;
            ${tw`flex flex-col flex-grow`}
        }
    }
`;
