import * as React from 'react';
import { BarProgress } from '../spinners';
import { QueryConnectionResult } from './types';

export interface ConnectionProgressProps<T extends any = any> extends QueryConnectionResult<T> {}

export const ConnectionBarProgress: React.FC<ConnectionProgressProps> = ({ connectionState }) => {
    if (!connectionState) return null;
    const { loading, isInitialLoading } = connectionState;

    // Note: using 0 and 1 as a workaround for console error.
    // see https://github.com/styled-components/styled-components/issues/1198
    return <BarProgress isLoading={Boolean(loading && !isInitialLoading)} />;
};
