import * as React from 'react';
import { ButtonWithPopup, ButtonWithPopupProps } from './ButtonWithPopup';
import { ConfirmModal, ConfirmProps } from '../ConfirmModal';

export interface ButtonWithConfirmProps extends ButtonWithPopupProps {
    confirm?: ConfirmProps;
    skipConfirm?: boolean;
}

// TODO: Deprecate the old ConfirmButton and rename this one.

export const ButtonWithConfirm: React.FC<ButtonWithConfirmProps> = (props) => {
    const { confirm, onClick, onCancel, skipConfirm, ...rest } = props;

    const handleClick = (e, data) => {
        e.stopPropagation();
        return onClick && onClick(e, data);
    };

    const defaultConfirm: ConfirmProps = {
        confirmMessage: {
            header: 'Are your sure?',
        },
        confirmButton: {
            content: 'Yes',
            basic: true,
            onClick: handleClick,
        },
    };

    if (skipConfirm) {
        return <ButtonWithPopup {...rest} onClick={onClick} />;
    } else {
        const trigger = <ButtonWithPopup {...rest} onClick={undefined} />;
        return (
            <ConfirmModal
                onCancel={onCancel}
                onClick={handleClick}
                confirm={confirm || defaultConfirm}
                trigger={trigger}
            />
        );
    }
};
