import * as React from 'react';
import tw, { styled, css } from 'twin.macro';
import {
    defaultMenuItemActiveBgColor,
    defaultSeparatorColor,
    defaultSidebarBgColor,
    defaultTextOnDarkColor,
    scrollbarBgColor,
    scrollbarFgColor,
    scrollbarWidth,
} from './default-constants';

const SidebarAside = styled.aside`
    display: block;
    background-color: var(--ez-sidebar-background-color, ${defaultSidebarBgColor});
    box-shadow: var(--ez-sidebar-shadow);
`;

const SidebarMenuContainer = styled.div`
    height: 100%;
    overflow-y: auto;

    // For Google Chrome
    &::-webkit-scrollbar {
        width: ${scrollbarWidth};
        height: ${scrollbarWidth};
    }

    &::-webkit-scrollbar-thumb {
        background: ${scrollbarFgColor};
    }

    &::-webkit-scrollbar-track {
        background: ${scrollbarBgColor};
    }

    // For Internet Explorer
    body {
        scrollbar-face-color: ${scrollbarFgColor};
        scrollbar-track-color: ${scrollbarBgColor};
    }
`;

const SidebarMenu = styled.div`
    display: flex;
    flex-direction: column;
    //background-color: var(--ez-sidebar-background-color, #44444);
    border: none;
    color: #fdfdfa;
    width: 100%;
    flex: 0 0 auto;
    overflow-x: hidden;
`;

const SidebarMenuSpacer = styled.div`
    //flex: 1 1 2rem;
    //background-color: brown;
    height: 60px;
`;

const SidebarMenuBottom = styled(SidebarMenu)`
    flex: 0 0 auto;
    background-color: pink;
    justify-content: flex-end;
`;

const SidebarLogoContainer = styled.div`
    padding: 0.5rem;
    height: var(--topBar, 46px);
    display: flex;
    flex-direction: column;
    background-color: var(--ez-navbar-logo-background-color, ${defaultSidebarBgColor});
    border-bottom: 2px solid var(--ez-navbar-separator-color, ${defaultSeparatorColor});
    color: var(--ez-navbar-logo-text-color, ${defaultTextOnDarkColor});

    & #nav-logo-icon {
        margin: 0 auto 0.5rem auto;
        max-width: 3rem;
        max-height: 3rem;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    #nav-logo-image {
        padding: 0 1rem;
        margin-top: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
        max-height: var(--topBar, 46px);
        max-width: 170px;
    }
`;

const SidebarMenuGroup = styled.div`
    margin: 0;
    padding: var(--ez-sidebar-menu-group-padding, 0.25rem 0.5rem 1rem 0.25rem);
    border-radius: 0;
    border-top: 1px solid var(--ez-sidebar-menu-group-separator-color, rgba(255, 255, 255, 0.25));
    &:first-child {
        border-top: none !important;
    }
`;

const SidebarMenuHeader = styled.div(() => [
    css`
        color: var(--ez-sidebar-menu-item-text-color, ${defaultTextOnDarkColor});
    `,
    // Labels are displayed in one line. Prevent from wrapping.
    tw`text-xs uppercase tracking-widest whitespace-nowrap`,
    tw`m-0 py-1 pr-0 pl-3`,
]);

const SidebarMenuGroupContent = styled.div``;

export const createSidebarMenuItem = (C) => styled(C)`
    ${tw`border-0 border-solid`}

    border-radius: var(--ez-sidebar-menu-item-border-radius, 0.25rem);

    cursor: pointer;
    display: block;
    //margin: 0 0 0 0.2em;
    padding: var(--ez-sidebar-menu-item-padding, 0.25em 0 0.25em 0.25em);
    font-size: 14px;
    font-weight: normal;

    color: var(--ez-sidebar-menu-item-text-color, ${defaultTextOnDarkColor});

    > .secondary-icon {
        padding-right: 0.5em;
    }

    > .main-icon {
        width: 2rem;
    }

    &.sub {
        font-size: 0.9em;
        padding-left: 2.4rem;
        & > .main-icon {
            padding-right: 0.5rem;
        }
    }

    &.active {
        ${tw`border-r-4`}
        background-color: var(--ez-sidebar-menu-item-active-background-color, ${defaultMenuItemActiveBgColor});
        border-color: var(--ez-sidebar-menu-item-active-ascent-color, ${defaultSeparatorColor});
        color: var(--ez-sidebar-menu-item-text-color, ${defaultTextOnDarkColor});
    }

    @media (pointer: fine) {
        &:hover {
            background-color: var(--ez-sidebar-menu-item-hover-background-color, ${defaultMenuItemActiveBgColor});
            color: var(--ez-sidebar-menu-item-text-color, ${defaultTextOnDarkColor});
        }
    }

    @media (pointer: coarse) {
        &:hover {
            background-color: var(--ez-sidebar-menu-item-hover-background-color, #44444);
            color: var(--ez-sidebar-menu-item-text-color, ${defaultTextOnDarkColor});
        }
    }

    // Labels are displayed in one line. Prevent from wrapping.
    white-space: nowrap;
`;

export const Sidebar = {
    Aside: SidebarAside,
    LogoContainer: SidebarLogoContainer,
    MenuContainer: SidebarMenuContainer,
    Menu: SidebarMenu,
    MenuSpacer: SidebarMenuSpacer,
    MenuBottom: SidebarMenuBottom,
    MenuHeader: SidebarMenuHeader,
    MenuGroup: SidebarMenuGroup,
    MenuGroupContent: SidebarMenuGroupContent,
    createMenuItem: createSidebarMenuItem,
};
