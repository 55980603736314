export type Maybe<T> = T | null;

export interface Node {
    /** The id of the object. */
    id: string;
}

/** Information about pagination in a connection. */
export interface PageInfo {
    /** When paginating forwards, the cursor to continue. */
    endCursor?: Maybe<string>;
    /** When paginating forwards, are there more items? */
    hasNextPage: boolean;
    /** When paginating backwards, are there more items? */
    hasPreviousPage: boolean;
    /** When paginating backwards, the cursor to continue. */
    startCursor?: Maybe<string>;
}

/** Information about pagination in a connection. */
export interface PageMeta {
    /** Total count of items in collection */
    totalCount?: Maybe<number>;
    /** Number of pages calculated by totalCount/pageSize */
    pageCount?: Maybe<number>;
}

export enum SortDirection {
    ASC = 'ASC',
    DES = 'DES',
}

export interface QueryConnectionConfigProps<TVariablesSearch = any> {
    after: string;
    setAfter: (cursor: string) => any;
    before: string;
    setBefore: (cursor: string) => any;
    pageIndex: number;
    setPageIndex: (n: number) => any;
    pageSize: number;
    defaultPageSize?: number;
    setPageSize: (n: number) => any;
    sortKey: string;
    defaultSortKey: string;
    setSortKey: (key: string) => any;
    sortDirection: SortDirection;
    defaultSortDirection: SortDirection;
    setSortDirection: (dir: SortDirection) => any;
    search: TVariablesSearch;
    defaultSearch: TVariablesSearch;
    setSearch: (props: TVariablesSearch) => any;
    variables?: { [key: string]: any };
    debugName?: string;
}

interface QueryResult {
    refetch: any;
    fetchMore: any;
}

export interface QueryConnectionFetchResultProps {
    pageInfo?: PageInfo;
    pageMeta?: PageMeta;
    pageCount?: number;
    totalCount?: number;
    error?;
    loading: boolean;
    isFetchingMore: boolean;
    isPolling: boolean;
    isInitialLoading: boolean;
    refetch: QueryResult['refetch'];
    fetchMore: QueryResult['fetchMore'];
}

export interface QueryConnectionState extends QueryConnectionConfigProps, QueryConnectionFetchResultProps {}

export interface QueryConnectionResult<T = any> {
    connectionData: T[];
    connectionState?: QueryConnectionState;
}

export interface GenericQueryConnection<T extends Node> {
    query?: any;
    connectionPath?: string;
    pollInterval?: number;
    skip?: boolean;
    errorPolicy?: any;
    fetchPolicy?: any;
    children?: (props: QueryConnectionResult<T>) => any;
}

export interface QueryConnectionExternalProps<T extends Node, TVariablesSearch = any>
    extends Partial<QueryConnectionConfigProps<TVariablesSearch>>,
        GenericQueryConnection<T> {}
