import { useEffect, useState } from 'react';
import { PrintSectionProps } from './PrintSection';
import { EZStorage } from '@ez/tools';

export const usePrintToggles = <T extends { [key: string]: boolean }>(
    name: string,
    initialState: T
): { [P in keyof T]: PrintSectionProps } => {
    const getInitialState = () => {
        try {
            const savedState = EZStorage.getItem(name);
            if (savedState) {
                return JSON.parse(savedState);
            } else {
                return initialState;
            }
        } catch (e) {
            return initialState;
        }
    };

    const [state, setState] = useState(getInitialState);

    useEffect(() => {
        try {
            // console.log('writing new state', state);
            EZStorage.setItem(name, JSON.stringify(state));
        } catch (e) {
            console.error(e);
        }
    }, [state, name]);

    const createToggleProps = (name: string): PrintSectionProps => {
        const onToggleClick = (e: any, { checked }) => {
            setState((state) => {
                state[name] = !checked;
                return { ...state };
            });
        };

        return {
            noPrint: state[name],
            onPrintChange: onToggleClick,
            showPrintToggle: true,
        };
    };

    let res: any = {};
    Object.keys(initialState).forEach((key) => {
        res[key] = createToggleProps(key);
    });

    return res;
};
