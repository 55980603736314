import { createGlobalStyle } from 'styled-components';
import * as React from 'react';
import { Button, Portal } from 'semantic-ui-react';
import { styled } from 'twin.macro';

const FullScreenContainer = styled.div<{ hideOnPrint?: boolean }>`
    --topBar: 0;
    --sideBar: 0;
    z-index: 999;
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    overscroll-behavior: contain;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f3f3f3;
    padding-bottom: 10px;
    @media print {
        display: ${({ hideOnPrint = false }) => (hideOnPrint ? 'none' : undefined)};
    }

    & .fullscreen-topbar {
        position: sticky;
        top: 0;
        //left: 0;
        //right: 0;
        background-color: #e7eaf1;
        width: 100%;
        z-index: 1000;
        padding: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    }

    & .fullscreen-header {
        border: none;
        padding: 0 0;
        font-weight: 700;
        line-height: 1.28571429em;
        font-size: 1.28571429em;
        text-transform: none;
        color: rgba(0, 0, 0, 0.87);
        text-align: center;
        flex-grow: 1;
    }

    & .fullscreen-content {
        padding: 1rem 0.5rem;
    }
`;

const BodyGlobalStyle = createGlobalStyle`
    &&& {
        body {
            overflow: hidden !important;
            user-select: none;
        }
    }
`;

export const FullScreen: React.FC<{ open?: boolean; onClose?; header?; hideOnPrint?: boolean }> = ({
    header,
    open = true,
    onClose,
    children,
    hideOnPrint = false,
}) => {
    return (
        <Portal open={open}>
            <FullScreenContainer hideOnPrint={hideOnPrint}>
                <BodyGlobalStyle />
                <div tw={'relative h-full inset-0 overflow-y-auto flex flex-col'}>
                    {(header || onClose) && (
                        <div className={'fullscreen-topbar'}>
                            <div className={'fullscreen-header'}>{header}</div>
                            <Button basic={true} color={'blue'} size={'tiny'} icon={'cancel'} onClick={onClose} />
                        </div>
                    )}
                    <div className={'fullscreen-content'}>{children}</div>
                </div>
            </FullScreenContainer>
        </Portal>
    );
};
