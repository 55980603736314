import * as React from 'react';
import { Portal } from 'semantic-ui-react';
import { createGlobalStyle } from 'styled-components';
import tw, { styled } from 'twin.macro';

const FullScreenContainer = styled.div<{ hideOnPrint?: boolean }>`
    --topBar: 0;
    --sideBar: 0;
    z-index: 900;
    position: fixed;
    overflow-x: hidden;
    overflow-y: hidden;
    overscroll-behavior: contain;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    padding-bottom: 10px;
    height: calc(100% - 10px);

    & .embedded-content {
        height: calc(100% - 10px);
    }
    @media print {
        display: ${({ hideOnPrint = false }) => (hideOnPrint ? 'none' : undefined)};
    }
`;

const BodyGlobalStyle = createGlobalStyle`
    &&& {
        body {
            overflow: hidden !important;
            user-select: none;
        }
    }
`;

const ContentContainer = styled.div(() => [
    tw`relative bg-gray-400 inset-4 overflow-y-auto flex flex-col max-w-screen-xl mx-auto rounded`,
]);

const EmbeddedPageMenu: React.FC = ({ children }) => {
    return <div tw={'sticky top-0 z-10 bg-gray-300 p-2 rounded-t shadow-xl'}>{children}</div>;
};

const EmbeddedPageBody: React.FC = ({ children }) => {
    return (
        <div tw={'lg:mx-auto lg:px-8 w-full  max-w-screen-xl p-4'}>
            <div tw={'bg-gray-50 rounded border border-gray-300 border-solid p-4'}>{children}</div>
        </div>
    );
};

export interface EmbeddedPageProps {
    fullScreen?: boolean;
    onClose?: () => any;
}

export class EmbeddedPage extends React.Component<EmbeddedPageProps> {
    public static Menu = EmbeddedPageMenu;
    public static Body = EmbeddedPageBody;

    render() {
        const { fullScreen, onClose, ...props } = this.props;
        if (!fullScreen) {
            return (
                <div tw={'bg-gray-300 shadow-inner min-h-24 rounded'}>
                    <div tw={'my-4'} {...props} />
                </div>
            );
        } else {
            return (
                <Portal open={true} closeOnEscape={true} onClose={this.props.onClose} closeOnDocumentClick={true}>
                    <FullScreenContainer
                        onClick={(e) => {
                            if (e.target == e.currentTarget) {
                                e.stopPropagation();
                                e.preventDefault();
                                this.props.onClose();
                            }
                        }}
                    >
                        <BodyGlobalStyle />
                        <ContentContainer className={'embedded-content'}>{props.children}</ContentContainer>
                    </FullScreenContainer>
                </Portal>
            );
        }
    }
}
//
// export interface EmbeddedPage extends React.Component<React.HTMLAttributes<HTMLDivElement>> {
//     Menu: typeof EmbeddedPageMenu;
//     Body: typeof EmbeddedPageBody;
// }
