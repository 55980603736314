import * as React from 'react';
import { Icon, Message } from 'semantic-ui-react';

const NotFoundMessage = () => {
    const isProduction = process.env.NODE_ENV === 'production';

    return (
        <Message warning>
            <Message.Header>
                Not Found <Icon name={'frown'} />
            </Message.Header>
        </Message>
    );
};

export default NotFoundMessage;
