import * as React from 'react';
import { ScrollY } from '../layout-primitives';
import { Panel } from './Panel';

const valueFormatter = (value: any) => {
    const isBasic = typeof value === 'string' || typeof value === 'number';

    if (isBasic) {
        return <>{value}</>;
    }

    const isBoolean = typeof value === 'boolean';

    if (isBoolean) {
        return <>{value ? 'YES' : 'NO'}</>;
    }

    return (
        <ScrollY maxHeight={600}>
            <pre style={{ margin: 1, fontSize: '0.8em' }}>{JSON.stringify(value, null, 2)}</pre>
        </ScrollY>
    );
};

export const PanelItems: React.FC<{ content: any; keyMapper? }> = ({ content, keyMapper }) => {
    if (!content) return null;

    const getLabel = (key) => {
        if (!keyMapper) return key;

        const label = keyMapper[key];
        if (!label) {
            return null;
        }

        return label;
    };

    const items = Object.keys(content).map((key) => {
        const itemData = content[key];
        const label = getLabel(key);
        if (!label) return null;
        return (
            <Panel.Item key={key} label={label}>
                {valueFormatter(itemData)}
            </Panel.Item>
        );
    });
    return <>{items}</>;
};
